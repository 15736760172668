import { AffiliatedTable } from "src/sections/app/affiliated";
import { AffiliatedTableRowType } from "src/sections/app/affiliated/AffiliatedTable";
import { alpha, useTheme } from "@mui/material/styles";
import { DefaultTableConfig } from "src/sections/app/_types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs";
import Iconify from "src/components/iconify";
import Loading from "src/components/Loading";
import Page from "src/components/Page";
import TopRanking from "src/sections/app/TopRanking";
import useAuth from "src/hooks/useAuth";
import useWebSocket from "src/hooks/useWebSocket";
import WidgetSummary from "src/sections/app/WidgetSummary";
import { useSnackbar } from "notistack";

type Affiliate = {
	id: string;
	document: string;
	email: string;
	firstName: string;
	lastName: string;
	cpa: number;
	revenueShare: number;
	peopleUsingCode: number;
	username: string;
	sub: boolean;
};

export default function AffiliatedListPage() {
	const theme = useTheme();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const { user } = useAuth();
	const { websocket } = useWebSocket();
	const [affiliates, setAffiliates] = useState<Affiliate[] | null>(null);
	const [requestingAffiliates, setRequestingAffiliates] = useState(false);
	const [tableConfig, setTableConfig] = useState<DefaultTableConfig<AffiliatedTableRowType>>({
		limit: 25,
		order: "desc",
		orderBy: "peopleUsingCode",
		page: 0,
	});

	useEffect(() => {
		if (!requestingAffiliates && !affiliates) {
			setRequestingAffiliates(true);
			websocket.emit("Affiliates.Get", {});
		}

		websocket
			.on("Affiliate.Create", (data) => {
				if (data.error) {
					enqueueSnackbar("Não foi possível criar o afiliado.", {
						variant: "error",
					});
				} else {
					enqueueSnackbar("Afiliado criado com sucesso.");
					setAffiliates((_affiliates) => [...(_affiliates || []), data.data]);
				}
			})
			.on("Affiliate.Delete", (data) => {
				setAffiliates((affiliates || []).filter((affiliate) => affiliate.id !== data.data.id));
			})
			.on("Affiliates.Get", (data) => {
				setAffiliates(data.data);
				setRequestingAffiliates(false);
			});
	}, [affiliates, enqueueSnackbar, requestingAffiliates, websocket]);

	const onChangeTableConfig = (
		data: {
			type: keyof DefaultTableConfig<AffiliatedTableRowType>;
			data: any;
		}[]
	) => {
		const newData = data.reduce((prev, curr) => ({ ...prev, [curr.type]: curr.data }), {});

		setTableConfig({
			...tableConfig,
			...newData,
		});
	};

	if (!affiliates || !user || (!affiliates.length && requestingAffiliates)) return <Loading loadingText="Carregando afiliados" />;

	return (
		<Page title="Gerenciamento: Afiliados">
			<Container maxWidth="xl">
				<CustomBreadcrumbs heading="Afiliados" links={[{ name: `Atualizado em ${new Date().toLocaleString("pt-BR")}` }]} />
				<Grid container spacing={3} sx={{ alignItems: "center", alignContent: "center", justifyContent: "center" }}>
					<Grid item xs={12} lg={5} md={6}>
						<TopRanking
							title={"Top 5 afiliados"}
							subheader={"Afiliados com melhor desempenho"}
							icon={"iconoir:shopping-code-check"}
							list={affiliates.map((affiliate) => ({
								id: affiliate.id,
								name: affiliate.email,
								count: affiliate.peopleUsingCode,
								sub: affiliate.sub,
								trophyAction: () => navigate(`/app/affiliated/${affiliate.id}/details`),
							}))}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<WidgetSummary
							title="Afiliados ativos"
							total={affiliates.length}
							icon={
								<Iconify
									icon={"iconoir:fingerprint-check-circle"}
									sx={{
										width: "100%",
										height: "100%",
										color: theme.palette.success.main,
									}}
								/>
							}
							bgColor={alpha(theme.palette.success.main, 0.08)}
							sx={{ mb: 3 }}
						/>
						<WidgetSummary
							title="Usuários captados"
							total={affiliates.reduce((prev, curr) => prev + curr.peopleUsingCode, 0).toLocaleString()}
							icon={
								<Iconify
									icon={"iconoir:shopping-code-check"}
									sx={{
										width: "100%",
										height: "100%",
										color: theme.palette.secondary.main,
									}}
								/>
							}
							bgColor={alpha(theme.palette.secondary.main, 0.08)}
							sx={{ mb: 3 }}
						/>
					</Grid>
					<Grid item xs={12} md={12}>
						<AffiliatedTable
							rows={affiliates.map((affiliated) => ({
								id: affiliated.id,
								email: affiliated.email,
								username: affiliated.username,
								firstName: affiliated.firstName,
								lastName: affiliated.lastName,
								document: affiliated.document,
								cpa: affiliated.cpa,
								revenueShare: affiliated.revenueShare,
								peopleUsingCode: affiliated.peopleUsingCode,
								sub: `${affiliated.sub}`,
							}))}
							tableConfig={tableConfig}
							itemCount={affiliates.length || 0}
							onChangeTableConfig={onChangeTableConfig}
							sx={{ p: 2 }}
						/>
					</Grid>
				</Grid>
			</Container>
		</Page>
	);
}
