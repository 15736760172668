import { List, Stack } from "@mui/material";
import { NavSectionProps } from "../types";
import { StyledSubheader } from "./styles";
import NavList from "./NavList";
import useAuth from "src/hooks/useAuth";

export default function NavSectionVertical({ data, sx, ...other }: NavSectionProps) {
	const { user } = useAuth();
	if (!user) return <></>;

	return (
		<Stack sx={sx} {...other}>
			{data.map((group) => {
				const key = group.subheader || group.items[0].title;

				if (
					!group.items.some((item) => user.roles.some((role) => item.permFilter?.includes(role))) ||
					!group.items.some((item) => (item.subDomainFilter ? item.subDomainFilter.some((subDomain) => window.location.hostname.includes(subDomain)) : true))
				) {
					return <></>;
				}

				return (
					<List key={key} disablePadding sx={{ px: 2 }}>
						{group.subheader && <StyledSubheader disableSticky>{group.subheader}</StyledSubheader>}

						{group.items.map((list) => {
							if (
								!user.roles.some((role) => list.permFilter?.includes(role)) ||
								(list.subDomainFilter && !list.subDomainFilter?.some((subDomain) => window.location.hostname.includes(subDomain)))
							) {
								return <></>;
							}

							return <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.children} />;
						})}
					</List>
				);
			})}
		</Stack>
	);
}
