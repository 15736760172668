import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Box, Link, BoxProps } from "@mui/material";
import { websiteConfig } from "src/config";

export interface LogoProps extends BoxProps {
	disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(({ disabledLink = false, sx, ...other }, ref) => {
	const logo = <Box component="img" src={websiteConfig.logo} sx={{ width: 40, height: 40, cursor: "pointer", ...sx }} />;

	if (disabledLink) {
		return logo;
	}

	return (
		<Link component={RouterLink} to="/" sx={{ display: "contents" }}>
			{logo}
		</Link>
	);
});

export default Logo;
