import { Box, BoxProps, Typography, useTheme } from "@mui/material";
import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

interface Props extends BoxProps {
	loadingText: string;
}

const rt = keyframes({
	"100%": {
		transform: "rotate(360deg)",
	},
});

export default function Loading({ loadingText, sx, ...props }: Props) {
	const theme = useTheme();

	const text = keyframes({
		"0%": {
			content: `'${loadingText}'`,
		},
		"25%": {
			content: `'${loadingText}.'`,
		},
		"50%": {
			content: `'${loadingText}..'`,
		},
		"100%": {
			content: `'${loadingText}...'`,
		},
	});

	const cw = keyframes({
		"0%": {
			width: 0,
			height: 0,
			background: theme.palette.background.default,
		},
		"100%": {
			width: "32px",
			height: "32px",
			background: theme.palette.primary.lighter,
		},
	});

	const Arc = styled.div({
		width: "100px",
		height: "100px",
		margin: "auto",
		borderRadius: "50%",
		borderTop: `2px solid ${theme.palette.primary.main}`,
		borderLeft: "1px solid transparent",
		borderRight: "1px solid transparent",
		animation: `${rt} 2s infinite linear`,
		"&:before": {
			position: "absolute",
			margin: "auto",
			top: "0",
			right: "0",
			bottom: "0",
			left: "0",
			width: "70px",
			height: "70px",
			borderRadius: "50%",
			borderTop: `5px solid ${theme.palette.primary.light}`,
			borderLeft: "1px solid transparent",
			borderRight: "1px solid transparent",
			animation: `${rt} 1s infinite linear reverse`,
			content: '""',
		},
		"&:after": {
			position: "absolute",
			margin: "auto",
			top: "0",
			right: "0",
			bottom: "0",
			left: "0",
			width: "0",
			height: "0",
			borderRadius: "50%",
			borderTop: "initial",
			borderLeft: "initial",
			borderRight: "initial",
			animation: `${cw} 1s infinite alternate`,
			content: '""',
		},
	});

	const Text = styled.div({
		height: "40px",
		margin: "auto",
		textTransform: "uppercase",
		textAlign: "center",
		letterSpacing: "0.1em",
		fontSize: "14px",
		fontWeight: "lighter",
		color: "white",
		"&:after": {
			animation: `${text} 5s infinite`,
			content: '""',
		},
	});

	return (
		<Box
			{...props}
			sx={{
				...sx,
				display: "flex",
				alignItems: "center",
				alignContent: "center",
				justifyContent: "center",
				textAlign: "center",
				height: "100%",
			}}>
			<Box>
				<Arc />
				<br />
				<Typography variant="subtitle2" color="text.secondary">
					<Text />
				</Typography>
			</Box>
		</Box>
	);
}
