import { alpha, useTheme } from "@mui/material/styles";
import { DashboardData } from "src/@types/websocket";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs";
import Iconify from "src/components/iconify";
import Loading from "src/components/Loading";
import Page from "src/components/Page";
import Stack from "@mui/material/Stack";
import TopRanking from "src/sections/app/TopRanking";
import Typography from "@mui/material/Typography";
import useWebSocket from "src/hooks/useWebSocket";
import WidgetSummary from "src/sections/app/WidgetSummary";
import { Divider } from "@mui/material";

export default function DashboardPage() {
	const navigate = useNavigate();
	const theme = useTheme();
	const { websocket, dashData: oldDashData, setWebsocketState } = useWebSocket();
	const [dashData, setDashData] = useState<DashboardData | null>(null);
	const [requesting, setRequesting] = useState(false);

	const getDashData = useCallback(() => {
		websocket.emit("Dashboard.Get", {});

		setWebsocketState((_state) => ({
			..._state,
			dashData: null,
		}));
	}, [setWebsocketState, websocket]);

	useEffect(() => {
		if (!requesting && !oldDashData && !dashData) {
			setRequesting(true);
			getDashData();
		} else if (!dashData && oldDashData) {
			setDashData(oldDashData);
		} else if (dashData && requesting) {
			setRequesting(false);
		}

		websocket.on("Dashboard.Get", (data) => {
			setDashData(data);
			setWebsocketState((_state) => ({
				..._state,
				dashData: data,
			}));
		});
	}, [dashData, getDashData, oldDashData, requesting, setWebsocketState, websocket]);

	if (!dashData) return <Loading loadingText="Carregando dados" />;

	return (
		<Page title="Página inicial">
			<Container maxWidth="xl">
				<CustomBreadcrumbs heading="Página inicial" links={[{ name: `Atualizado em ${new Date().toLocaleString("pt-BR")}` }]} />
				<Grid container spacing={3} sx={{ justifyContent: "space-around", alignItems: "center", alignContent: "center" }}>
					{(["balance", "bonus"] as (keyof typeof dashData.top10)[]).map((balance) => (
						<Grid item xs={12} md={6} xl={4}>
							<Stack spacing={3}>
								<WidgetSummary
									title={`Total em saldo ${balance === "balance" ? "real" : "bônus"}`}
									total={dashData.balances[balance === "balance" ? "real" : "bonus"].toLocaleString("pt-BR", {
										currency: "BRL",
										style: "currency",
									})}
									icon={
										<Iconify
											icon={"iconoir:wallet"}
											sx={{
												width: "100%",
												height: "100%",
												color: theme.palette.success.main,
											}}
										/>
									}
									bgColor={alpha(theme.palette.success.main, 0.08)}
								/>
								<TopRanking
									title={`5 maiores saldos ${balance === "balance" ? "reais" : "bônus"}`}
									subheader={""}
									icon={"tabler:moneybag"}
									formatAsMoney={true}
									list={dashData.top10[balance].map((punter: { userId: string; email: string; amount: number }) => ({
										id: punter.userId,
										name: punter.email,
										count: punter.amount,
										trophyAction: () => navigate(`/app/accounts/${punter.userId}/details`),
									}))}
									size={5}
								/>
							</Stack>
						</Grid>
					))}
					<Grid item xs={12} md={5} xl={4}>
						<Stack spacing={2.3}>
							<WidgetSummary
								title="Lucro total"
								total={(dashData.deposits.amount.confirmed - dashData.balances.real - dashData.withdraws.amount.approved).toLocaleString("pt-BR", {
									currency: "BRL",
									style: "currency",
								})}
								icon={
									<Iconify
										icon={"iconoir:card-wallet"}
										sx={{
											width: "100%",
											height: "100%",
											color: theme.palette.warning.main,
										}}
									/>
								}
								bgColor={alpha(theme.palette.warning.main, 0.08)}
							/>
							<WidgetSummary
								title="Total de cadastros"
								total={dashData.people.count.toLocaleString("pt-BR")}
								icon={
									<Iconify
										icon={"iconoir:user"}
										sx={{
											width: "100%",
											height: "100%",
											color: theme.palette.success.main,
										}}
									/>
								}
								bgColor={alpha(theme.palette.success.main, 0.08)}
							/>
							<WidgetSummary
								title="Total de depositantes"
								total={dashData.people.totalDeposited.toLocaleString("pt-BR")}
								icon={
									<Iconify
										icon={"iconoir:wallet"}
										sx={{
											width: "100%",
											height: "100%",
											color: theme.palette.secondary.main,
										}}
									/>
								}
								bgColor={alpha(theme.palette.secondary.main, 0.08)}
							/>
							<WidgetSummary
								title="Pessoas com dois depósitos"
								total={dashData.people.twoDeposits.toLocaleString("pt-BR")}
								icon={
									<Iconify
										icon={"iconoir:dice-two"}
										sx={{
											width: "100%",
											height: "100%",
											color: theme.palette.info.main,
										}}
									/>
								}
								bgColor={alpha(theme.palette.info.main, 0.08)}
							/>
							<WidgetSummary
								title="Pessoas com três ou mais depósitos"
								total={dashData.people.moreThanTwoDeposits.toLocaleString("pt-BR")}
								icon={
									<Iconify
										icon={"iconoir:dice-three"}
										sx={{
											width: "100%",
											height: "100%",
											color: theme.palette.info.main,
										}}
									/>
								}
								bgColor={alpha(theme.palette.info.main, 0.08)}
							/>
						</Stack>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12} xl={12}>
						<Typography variant="h4" sx={{ mb: 2 }}>
							Retenção nos últimos 30 dias
						</Typography>

						<Grid container spacing={3}>
							<Grid item xs={12} md={6} xl={3}>
								<WidgetSummary title="Após primeiro depósito" total={`--`} sx={{ mb: 3 }} icon={null} bgColor={""} />
							</Grid>
							<Grid item xs={12} md={6} xl={3}>
								<WidgetSummary
									title="Após primeiro depósito"
									total={`${(dashData.retention.afterDepositToWithdraw).toFixed(2)} dias para sacar`}
									sx={{ mb: 3 }}
									icon={null}
									bgColor={""}
								/>
							</Grid>
							<Grid item xs={12} md={6} xl={3}>
								<WidgetSummary title="Após registrar" total={`${(dashData.retention.afterRegisterToWithdraw).toFixed(2)} dias para sacar`} sx={{ mb: 3 }} icon={null} bgColor={""} />
							</Grid>
							<Grid item xs={12} md={6} xl={3}>
								<WidgetSummary
									title="Após registrar"
									total={`${(dashData.retention.afterRegisterToDeposit).toFixed(2)} horas para depositar`}
									sx={{ mb: 3 }}
									icon={null}
									bgColor={""}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12} xl={12}>
						<Typography variant="h4" sx={{ mb: 2 }}>
							Faixa etária
						</Typography>

						<Grid container spacing={3}>
							<Grid item xs={12} md={6} xl={3}>
								<WidgetSummary title="Usuários com 18 até 24 anos" total={dashData.ageGroup[18_24].toLocaleString("pt-BR")} sx={{ mb: 3 }} icon={null} bgColor={""} />
							</Grid>
							<Grid item xs={12} md={6} xl={3}>
								<WidgetSummary title="Usuários com 25 até 32 anos" total={dashData.ageGroup[25_32].toLocaleString("pt-BR")} sx={{ mb: 3 }} icon={null} bgColor={""} />
							</Grid>
							<Grid item xs={12} md={6} xl={3}>
								<WidgetSummary title="Usuários com 33 até 50 anos" total={dashData.ageGroup[33_50].toLocaleString("pt-BR")} sx={{ mb: 3 }} icon={null} bgColor={""} />
							</Grid>
							<Grid item xs={12} md={6} xl={3}>
								<WidgetSummary title="Usuários com mais de 51 anos" total={dashData.ageGroup[51_99].toLocaleString("pt-BR")} sx={{ mb: 3 }} icon={null} bgColor={""} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12} xl={5}>
						<Typography variant="h4" sx={{ mb: 2 }}>
							Depósitos
						</Typography>

						<Grid container spacing={3}>
							<Grid item xs={12} md={4} xl={6}>
								<WidgetSummary
									title="Quantia aprovada"
									total={dashData.deposits.amount.confirmed.toLocaleString("pt-BR", {
										currency: "BRL",
										style: "currency",
									})}
									icon={
										<Iconify
											icon={"iconoir:check"}
											sx={{
												width: "100%",
												height: "100%",
												color: theme.palette.success.main,
											}}
										/>
									}
									bgColor={alpha(theme.palette.success.main, 0.08)}
									sx={{ mb: 3 }}
								/>
							</Grid>
							<Grid item xs={12} md={4} xl={6}>
								<WidgetSummary
									title="Quantia pendente"
									total={dashData.deposits.amount.pending.toLocaleString("pt-BR", {
										currency: "BRL",
										style: "currency",
									})}
									icon={
										<Iconify
											icon={"iconoir:clock-outline"}
											sx={{
												width: "100%",
												height: "100%",
												color: theme.palette.warning.main,
											}}
										/>
									}
									bgColor={alpha(theme.palette.warning.main, 0.08)}
									sx={{ mb: 3 }}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} xl={7}>
						<Typography variant="h4" sx={{ mb: 2 }}>
							Saques
						</Typography>

						<Grid container spacing={3}>
							<Grid item xs={12} md={3} xl={4}>
								<WidgetSummary
									title="Quantia aprovada"
									total={dashData.withdraws.amount.approved.toLocaleString("pt-BR", {
										currency: "BRL",
										style: "currency",
									})}
									icon={
										<Iconify
											icon={"iconoir:check"}
											sx={{
												width: "100%",
												height: "100%",
												color: theme.palette.success.main,
											}}
										/>
									}
									bgColor={alpha(theme.palette.success.main, 0.08)}
									sx={{ mb: 3 }}
								/>
							</Grid>

							<Grid item xs={12} md={3} xl={4}>
								<WidgetSummary
									title="Quantia pendente"
									total={dashData.withdraws.amount.pending.toLocaleString("pt-BR", {
										currency: "BRL",
										style: "currency",
									})}
									icon={
										<Iconify
											icon={"iconoir:clock-outline"}
											sx={{
												width: "100%",
												height: "100%",
												color: theme.palette.warning.main,
											}}
										/>
									}
									bgColor={alpha(theme.palette.warning.main, 0.08)}
									sx={{ mb: 3 }}
								/>
							</Grid>

							<Grid item xs={12} md={3} xl={4}>
								<WidgetSummary
									title="Quantia rejeitada"
									total={dashData.withdraws.amount.rejected.toLocaleString("pt-BR", {
										currency: "BRL",
										style: "currency",
									})}
									icon={
										<Iconify
											icon={"iconoir:cancel"}
											sx={{
												width: "100%",
												height: "100%",
												color: theme.palette.error.main,
											}}
										/>
									}
									bgColor={alpha(theme.palette.error.main, 0.08)}
									sx={{ mb: 3 }}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</Page>
	);
}
