import { DefaultTableToolbarProps } from "../_types";
import { alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Iconify from "src/components/iconify";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

export default function LogsTableToolbar(props: DefaultTableToolbarProps) {
	const { numSelected } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						),
				}),
			}}>
			{numSelected > 0 ? (
				<Typography
					sx={{ flex: "1 1 100%" }}
					color="inherit"
					variant="subtitle1"
					component="div">
					{numSelected} selecionados
				</Typography>
			) : (
				<Typography
					sx={{ flex: "1 1 100%" }}
					variant="h6"
					id="tableTitle"
					component="div">
					Registros
				</Typography>
			)}
			{numSelected > 0 ? (
				<Tooltip title="Deletar">
					<IconButton>
						<Iconify icon={"iconoir:trash"} />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title="Filtrar Lista">
					<IconButton>
						<Iconify icon={"iconoir:filter"} />
					</IconButton>
				</Tooltip>
			)}
		</Toolbar>
	);
}
