import { dateLocale, dateOptions } from "../_constants";
import { DefaultTableRowProps } from "../_types";
import { LogsTableRowType } from "./LogsTable";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function LogsTableRow({ isItemSelected, row, labelId, handleSelect }: DefaultTableRowProps<LogsTableRowType>) {
	return (
		<TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} selected={isItemSelected}>
			<TableCell padding="checkbox">
				<Checkbox
					color="primary"
					checked={isItemSelected}
					onClick={(event) => handleSelect(event, row.id)}
					inputProps={{
						"aria-labelledby": labelId,
					}}
				/>
			</TableCell>
			<TableCell component="th" id={labelId} scope="row" padding="none">
				{row.action}
			</TableCell>
			<TableCell align="left">{row.message}</TableCell>
			<TableCell align="left">
				{row.ip} {row.agent}
			</TableCell>
			<TableCell align="left">{new Date(row.date).toLocaleString(dateLocale, dateOptions)}</TableCell>
			<TableCell align="left">{row.success}</TableCell>
		</TableRow>
	);
}
