import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

export type ColorSchema = "primary" | "secondary" | "info" | "success" | "warning" | "error";

declare module "@mui/material/styles/createPalette" {
	interface TypeBackground {
		neutral: string;
	}
	interface SimplePaletteColorOptions {
		lighter: string;
		darker: string;
	}
	interface PaletteColor {
		lighter: string;
		darker: string;
	}
}

// SETUP COLORS

const GREY = {
	0: "#FFFFFF",
	100: "#F9FAFB",
	200: "#F4F6F8",
	300: "#DFE3E8",
	400: "#C4CDD5",
	500: "#919EAB",
	600: "#637381",
	700: "#454F5B",
	800: "#212B36",
	900: "#161C24",
};

const PRIMARY = {
	lighter: "#FEF9D5",
	light: "#FEE982",
	main: "#FDD130",
	dark: "#B68D18",
	darker: "#795709",
	contrastText: "#fff",
};

const SECONDARY = {
	lighter: "#D6E4FF",
	light: "#84A9FF",
	main: "#3366FF",
	dark: "#1939B7",
	darker: "#091A7A",
	contrastText: "#fff",
};

const INFO = {
	lighter: "#CCFEF8",
	light: "#68F9FE",
	main: "#05CFFC",
	dark: "#0279B5",
	darker: "#003E78",
	contrastText: "#fff",
};

const SUCCESS = {
	lighter: "#D8FBDE",
	light: "#86E8AB",
	main: "#36B37E",
	dark: "#1B806A",
	darker: "#0A5554",
	contrastText: "#fff",
};

const WARNING = {
	lighter: "#FFFCD5",
	light: "#FFF681",
	main: "#FFED2D",
	dark: "#B7A616",
	darker: "#7A6C08",
	contrastText: GREY[800],
};

const ERROR = {
	lighter: "#FFE8DA",
	light: "#FFA890",
	main: "#FF5047",
	dark: "#B72335",
	darker: "#7A0D2C",
	contrastText: "#fff",
};

const COMMON = {
	common: { black: "#000", white: "#fff" },
	primary: PRIMARY,
	secondary: SECONDARY,
	info: INFO,
	success: SUCCESS,
	warning: WARNING,
	error: ERROR,
	grey: GREY,
	divider: alpha(GREY[500], 0.24),
	action: {
		hover: alpha(GREY[500], 0.08),
		selected: alpha(GREY[500], 0.16),
		disabled: alpha(GREY[500], 0.8),
		disabledBackground: alpha(GREY[500], 0.24),
		focus: alpha(GREY[500], 0.24),
		hoverOpacity: 0.08,
		disabledOpacity: 0.48,
	},
};

export default function palette(themeMode: "light" | "dark") {
	const light = {
		...COMMON,
		mode: "light",
		text: {
			primary: GREY[800],
			secondary: GREY[600],
			disabled: GREY[500],
		},
		background: { paper: "#fff", default: "#fff", neutral: GREY[200] },
		action: {
			...COMMON.action,
			active: GREY[600],
		},
	} as const;

	const dark = {
		...COMMON,
		mode: "dark",
		text: {
			primary: "#fff",
			secondary: GREY[500],
			disabled: GREY[600],
		},
		background: {
			paper: "#171A27",
			default: "#0F111A",
			neutral: alpha(GREY[500], 0.16),
		},
		action: {
			...COMMON.action,
			active: GREY[500],
		},
	} as const;

	return themeMode === "light" ? light : dark;
}
