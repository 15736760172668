import { affiliatedBaseRoute } from "../_constants";
import { AffiliatedTableRowType } from "./AffiliatedTable";
import { DefaultTableRowProps } from "../_types";
import { fCpf } from "src/utils/formatNumber";
import { TableMoreMenu } from "src/components/table";
import { useNavigate } from "react-router-dom";
import { useState, MouseEvent } from "react";
import Iconify from "src/components/iconify";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import useAuth from "src/hooks/useAuth";
import useWebSocket from "src/hooks/useWebSocket";

export default function AffiliatedTableRow({ isItemSelected, row, labelId, handleSelect }: DefaultTableRowProps<AffiliatedTableRowType>) {
	const [openMenu, setOpenMenu] = useState<null | HTMLElement>(null);
	const { user } = useAuth();
	const { websocket } = useWebSocket();
	const navigate = useNavigate();

	const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
		setOpenMenu(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setOpenMenu(null);
	};

	const onDeleteRow = () => {
		websocket.emit("Affiliate.Delete", { id: row.id });
	};

	const onDetailsRow = () => {
		navigate(`${affiliatedBaseRoute}/${row.id}/details`);
	};

	return (
		<TableRow hover>
			<TableCell id={labelId} scope="row">
				<Typography variant="subtitle2" color="secondary.main" component='span'>
					{row.sub === "true" ? "(Sub-Afiliado) " : ""}
				</Typography>
				{row.email}
			</TableCell>
			<TableCell align="left">{row.username}</TableCell>
			<TableCell align="left">
				{row.firstName} {row.lastName}
			</TableCell>
			<TableCell align="left">{row.peopleUsingCode.toLocaleString()}</TableCell>
			<TableCell align="left">
				{row.cpa.toLocaleString("pt-BR", {
					style: "currency",
					currency: "BRL",
				})}
			</TableCell>
			<TableCell align="left">{row.revenueShare.toLocaleString()}%</TableCell>
			<TableCell align="left">{fCpf(row.document)}</TableCell>
			<TableCell align="right">
				<TableMoreMenu
					open={openMenu}
					onOpen={handleOpenMenu}
					onClose={handleCloseMenu}
					actions={
						<>
							<MenuItem
								onClick={() => {
									onDeleteRow();
									handleCloseMenu();
								}}
								sx={{ color: "error.main" }}
								disabled={!user || !user.roles.includes("admin")}>
								<Iconify icon={"iconoir:trash"} />
								Apagar
							</MenuItem>
							<MenuItem
								onClick={() => {
									onDetailsRow();
									handleCloseMenu();
								}}>
								<Iconify icon={"iconoir:open-book"} />
								Ver Detalhes
							</MenuItem>
						</>
					}
				/>
			</TableCell>
		</TableRow>
	);
}
