import { Box, FormControl, InputLabel, MenuItem, Paper, Select, Stack, TextField } from "@mui/material";
import { DefaultTableConfig } from "src/sections/app/_types";
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { websiteConfig } from "src/config";
import Container from "@mui/material/Container";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs";
import Grid from "@mui/material/Grid";
import Loading from "src/components/Loading";
import Page from "src/components/Page";
import useWebSocket from "src/hooks/useWebSocket";
import WithdrawsTable, { WithdrawsTableRowType } from "src/sections/app/withdraws/list/WithdrawsTable";

export default function WithdrawListPage() {
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const { websocket } = useWebSocket();
	const [pendingWithdraws, setPendingWithdraws] = useState<{
		withdraws: {
			id: string;
			userId: string;
			email: string;
			document: string;
			kyc: boolean;
			amount: number;
			first: boolean;
			status: string;
			createdAt: number;
			depositTotal: number;
			withdrawTotal: number;
			walletId: string | null;
			currency: string;
			//
			firstName: string;
			lastName: string;
			address: string;
			postalCode: string;
			city: string;
			country: string;
			hometown: string;
			phoneDialCode: string;
			phoneNumber: string;
		}[];
		count: number;
	} | null>(null);

	const [tableConfig, setTableConfig] = useState<DefaultTableConfig<WithdrawsTableRowType>>({
		limit: 25,
		order: "asc",
		orderBy: "createdAt",
		page: 0,
		search: "",
		keyFilter: {
			status: "pending",
		},
	});
	const [requestingAccounts, setRequestingAccounts] = useState(false);

	const getWithdraws = useCallback(
		(config: DefaultTableConfig<WithdrawsTableRowType>) => {
			setTableConfig(config);

			setPendingWithdraws({ withdraws: [], count: 0 });
			websocket.emit("Management.Withdraws.Get", {
				...config,
				offset: config.page * config.limit,
			});
		},
		[websocket]
	);

	useEffect(() => {
		if (!requestingAccounts && !pendingWithdraws) {
			if (localStorage.getItem("withdraw-platform") === null) {
				localStorage.setItem("withdraw-platform", websiteConfig.withdrawPlatforms[0].value);
			}
			setRequestingAccounts(true);
			getWithdraws(tableConfig);
		}
	}, [getWithdraws, requestingAccounts, tableConfig, pendingWithdraws]);

	useEffect(() => {
		websocket
			.on("Management.Withdraws.Get", (data) => {
				setPendingWithdraws({ withdraws: data.data, count: data.count });
				setRequestingAccounts(false);
			})
			.on("Management.Withdraw.Action", (data) => {
				if (data.success) {
					enqueueSnackbar(data.message);
					setPendingWithdraws((_oldWithdraws) =>
						_oldWithdraws
							? {
									..._oldWithdraws,
									withdraws: [
										..._oldWithdraws.withdraws.filter((withdraw) => withdraw.id !== data.withdrawId),
										{ ..._oldWithdraws.withdraws.filter((withdraw) => withdraw.id === data.withdrawId)[0], status: data.approved ? "approved" : "rejected" },
									],
							  }
							: null
					);
				} else {
					enqueueSnackbar(data.message, {
						variant: "error",
					});
				}
			});
	}, [enqueueSnackbar, navigate, websocket]);

	const onChangeTableConfig = (data: { type: keyof DefaultTableConfig<WithdrawsTableRowType>; data: any }[]) => {
		const newData = data.reduce((prev, curr) => ({ ...prev, [curr.type]: curr.data }), {});

		setRequestingAccounts(true);
		getWithdraws({
			...tableConfig,
			...newData,
		});
	};

	if (!pendingWithdraws || (!pendingWithdraws.withdraws.length && requestingAccounts)) return <Loading loadingText="Carregando saques" />;

	return (
		<Page title="Gerenciamento: Saques">
			<Container maxWidth="xl">
				<CustomBreadcrumbs heading="Lista de saques" links={[{ name: `Atualizado em ${new Date().toLocaleString("pt-BR")}` }]} />

				<Grid container spacing={2} marginBottom={2} sx={{ alignItems: "center", alignContent: "center" }}>
					<Box sx={{ width: "100%" }}>
						<Paper sx={{ width: "100%", mb: 2 }}>
							<Stack
								spacing={2}
								direction="row"
								sx={{
									alignItems: "center",
									alignContent: "center",
									justifyContent: "center",
									pl: { xs: 1 },
									pr: { xs: 1 },
									py: 2,
								}}>
								<TextField
									placeholder="Blueocean key"
									defaultValue={localStorage.getItem("blueoceanKey")}
									onChange={(event) => localStorage.setItem("blueoceanKey", event.currentTarget.value)}
								/>
								<FormControl>
									<InputLabel id="select-platform-label">Plataforma de pagamento</InputLabel>
									<Select
										size="small"
										labelId="select-platform-label"
										id="select-platform"
										defaultValue={localStorage.getItem("withdraw-platform")}
										label="Status"
										onChange={({ target: { value } }) => localStorage.setItem("withdraw-platform", value!)}>
										{websiteConfig.withdrawPlatforms.map((platform) => (
											<MenuItem value={platform.value}>{platform.name}</MenuItem>
										))}
									</Select>
								</FormControl>
							</Stack>
						</Paper>
					</Box>
				</Grid>
				<Grid container spacing={2} sx={{ alignItems: "center", alignContent: "center" }}>
					<WithdrawsTable rows={pendingWithdraws.withdraws} tableConfig={tableConfig} itemCount={pendingWithdraws.count} onChangeTableConfig={onChangeTableConfig} />
				</Grid>
			</Container>
		</Page>
	);
}
