import { accountTransactionsHeadCells } from "../../_constants";
import { DefaultTableProps, TableOrder } from "../../_types";
import { emptyRows } from "../../../../utils/tableUtils";
import { TableEmptyRows, TableHeadCustom, TableNoData } from "src/components/table";
import { useState, MouseEvent, ChangeEvent, useEffect } from "react";
import AccountTransactionsTableRow from "./AccountTransactionsTableRow";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";

export interface AccountTransactionsTableRowType {
	id: string;
	amount: number;
	date: string;
	type: string;
	initialBalance: number;
	finalBalance: number;
	finalResult: string;
	typeb: string;
}

export default function AccountTransactionsTable({ rows, tableConfig, itemCount, dense: dense_ = false, onChangeTableConfig }: DefaultTableProps<AccountTransactionsTableRowType>) {
	const [dense, setDense] = useState(dense_);
	const [order, setOrder] = useState<TableOrder>(tableConfig.order);
	const [orderBy, setOrderBy] = useState<keyof AccountTransactionsTableRowType>(tableConfig.orderBy);
	const [page, setPage] = useState(tableConfig.page);
	const [rowCount, setRowCount] = useState(itemCount);
	const [rowsPerPage, setRowsPerPage] = useState(tableConfig.limit);
	const [selected, setSelected] = useState<"all" | readonly string[]>([]);
	const [tableData, setTableData] = useState<AccountTransactionsTableRowType[]>(rows);

	useEffect(() => {
		setOrder(tableConfig.order);
		setOrderBy(tableConfig.orderBy);
		setPage(tableConfig.page);
		setRowCount(itemCount);
		setRowsPerPage(tableConfig.limit);
		setTableData(rows);
	}, [rows, itemCount, tableConfig.limit, tableConfig.order, tableConfig.orderBy, tableConfig.page]);

	const handleRequestSort = (id: keyof AccountTransactionsTableRowType) => {
		const order_ = orderBy === id && order === "asc";
		onChangeTableConfig([
			{ type: "order", data: order_ ? "desc" : "asc" },
			{ type: "orderBy", data: id },
		]);
	};

	const handleSelectRow = (event: MouseEvent<unknown>, id: string) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: readonly string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		onChangeTableConfig([{ type: "page", data: newPage }]);
	};

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		onChangeTableConfig([
			{ type: "limit", data: Number(event.target.value) },
			{ type: "page", data: 0 },
		]);
	};

	const handleChangeDense = (event: ChangeEvent<HTMLInputElement>) => {
		setDense(event.target.checked);
	};

	const isSelected = (id: string) => (selected === "all" ? true : selected.indexOf(id) !== -1);

	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<TableContainer>
					<Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
						<TableHeadCustom<AccountTransactionsTableRowType>
							headLabel={accountTransactionsHeadCells}
							order={order}
							orderBy={orderBy}
							onSort={handleRequestSort}
							rowCount={itemCount}
							extra={true}
						/>
						<TableBody>
							{tableData.map((row, index) => (
								<AccountTransactionsTableRow
									isItemSelected={isSelected(row.id)}
									row={row}
									key={row.id}
									labelId={`accountTransactions-table-checkbox-${index}`}
									handleSelect={handleSelectRow}
								/>
							))}

							<TableEmptyRows height={dense ? 56 : 76} emptyRows={emptyRows(page, rowsPerPage, itemCount)} />
							<TableNoData isNotFound={!tableData.length} />
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
					component="div"
					count={rowCount}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage={"Transações por página"}
					getItemAriaLabel={function defaultGetAriaLabel(type) {
						return `Ir para a ${type === "next" ? "próxima página" : type === "first" ? "primeira página" : type === "last" ? "última página" : "pagina anterior"} `;
					}}
					showFirstButton={page !== 0 && rowCount > rowsPerPage * page}
					showLastButton={page !== Math.ceil(rowCount / rowsPerPage) - 1 && rowCount > rowsPerPage * page}
				/>
			</Paper>
			<FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Modo minimalista" />
		</Box>
	);
}
