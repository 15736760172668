import { affiliatedHeadCells, dateLocale, dateOptions } from "../_constants";
import { AffiliatedTableRow } from ".";
import { Card, Checkbox, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import { DefaultTableProps, TableOrder } from "../_types";
import { fMinMax } from "src/utils/formatNumber";
import { LoadingButton } from "@mui/lab";
import { stableSort, getComparator } from "../../../utils/tableUtils";
import { TableHeadCustom, TableNoData } from "src/components/table";
import { useState, MouseEvent, ChangeEvent, useEffect } from "react";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Iconify from "src/components/iconify";
import Modal from "src/components/Modal";
import Scrollbar from "src/components/scrollbar";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import useAuth from "src/hooks/useAuth";
import useWebSocket from "src/hooks/useWebSocket";
import useResponsive from "src/hooks/useResponsive";
import { Roles } from "src/@types/auth";

type NewAffiliateData = { cpaActive: boolean; email: string; cpaValue: number; revenueShare: number };

export interface AffiliatedTableRowType {
	id: string;
	document: string;
	email: string;
	firstName: string;
	lastName: string;
	peopleUsingCode: number;
	username: string;
	cpa: number;
	revenueShare: number;
	sub: string;
}

export default function AffiliatedTable({ rows, tableConfig, itemCount, onChangeTableConfig, ...other }: DefaultTableProps<AffiliatedTableRowType>) {
	const { user } = useAuth();
	const { websocket } = useWebSocket();
	const [dense, setDense] = useState(false);
	const [order, setOrder] = useState<TableOrder>(tableConfig.order);
	const [orderBy, setOrderBy] = useState<keyof AffiliatedTableRowType>(tableConfig.orderBy);
	const [page, setPage] = useState(tableConfig.page);
	const [rowCount, setRowCount] = useState(itemCount);
	const [rowsPerPage, setRowsPerPage] = useState(tableConfig.limit);
	const [searchFilter, setSearchFilter] = useState("");
	const [selected, setSelected] = useState<readonly string[]>([]);
	const [tableData, setTableData] = useState<AffiliatedTableRowType[]>(rows);
	const [newAffiliateData, setNewAffiliateData] = useState<NewAffiliateData>({
		cpaActive: false,
		email: "",
		cpaValue: 0,
		revenueShare: 30,
	});
	const [creatingAffiliate, setCreatingAffiliate] = useState(false);
	const isDesktop = useResponsive("up", "lg");

	useEffect(() => {
		setOrder(tableConfig.order);
		setOrderBy(tableConfig.orderBy);
		setPage(tableConfig.page);
		setRowCount(itemCount);
		setRowsPerPage(tableConfig.limit);
		setTableData(rows);
	}, [rows, tableConfig, itemCount, tableConfig.limit, tableConfig.order, tableConfig.orderBy, tableConfig.page]);

	const handleRequestSort = (property: keyof AffiliatedTableRowType) => {
		const order_ = orderBy === property && order === "asc";
		onChangeTableConfig([
			{ type: "order", data: order_ ? "desc" : "asc" },
			{ type: "orderBy", data: property },
		]);
	};

	const handleSelectRow = (event: MouseEvent<unknown>, id: string) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: readonly string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		onChangeTableConfig([{ type: "page", data: newPage }]);
	};

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		onChangeTableConfig([
			{ type: "limit", data: Number(event.target.value) },
			{ type: "page", data: 0 },
		]);
	};

	const handleChangeDense = (event: ChangeEvent<HTMLInputElement>) => {
		setDense(event.target.checked);
	};

	const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchFilter(event.target.value);
	};

	const isSelected = (id: string) => selected.indexOf(id) !== -1;

	/**
	 * New Affiliate
	 */
	const changeNewAffiliateData = (newData: Partial<NewAffiliateData>) => {
		setNewAffiliateData((_oldData) => ({ ..._oldData, ...newData }));
	};

	const createAffiliate = () => {
		setCreatingAffiliate(true);
		websocket.emit("Affiliate.Create", {
			email: newAffiliateData.email.toLowerCase(),
			cpaValue: newAffiliateData.cpaValue,
			revenueShare: newAffiliateData.revenueShare,
		});
		setCreatingAffiliate(false);
	};

	return (
		<Card {...other} sx={{ p: 0 }}>
			<Stack
				spacing={2}
				direction={"row"}
				sx={{
					alignItems: "center",
					alignContent: "center",
					justifyContent: "space-between",
					pl: { xs: 1 },
					pr: { xs: 1 },
					py: 2,
				}}>
				<TextField
					type="text"
					label="Procurar"
					placeholder="abc@email.xyz, 123.456.789-01..."
					size={dense || !isDesktop ? "small" : "medium"}
					value={searchFilter}
					onChange={handleChangeSearch}
				/>

				<Modal
					iconButton={!isDesktop}
					buttonText={isDesktop ? "Adicionar afiliado" : <Iconify icon="iconoir:add-user" />}
					buttonProps={{
						color: "success",
						size: "large",
						variant: "contained",
						disabled: !(["super-admin", "admin", "affiliates.manage"] as Roles[]).some((role) => user?.roles.includes(role)),
					}}
					Children={(closeModal: Function) => (
						<Box
							sx={{
								position: "absolute",
								top: "50%",
								left: "50%",
								transform: "translate(-50%, -50%)",
								width: 400,
								bgcolor: "background.paper",
								boxShadow: 24,
								p: 4,
							}}>
							<Box sx={{ display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center" }}>
								<Typography variant="h6" component="span">
									Adicionar Afiliado
								</Typography>
								<IconButton onClick={() => closeModal()} color="error">
									<Iconify icon="iconoir:cancel" />
								</IconButton>
							</Box>
							<Stack spacing={2} sx={{ my: 3 }}>
								<TextField
									fullWidth
									variant="outlined"
									id="email"
									name="email"
									label="Insira o e-mail do usuário"
									placeholder="abc@mail.xyz"
									size="medium"
									type="email"
									value={newAffiliateData.email}
									onChange={({ target: { name, value } }) => changeNewAffiliateData({ [name]: value })}
								/>
								<TextField
									id="revenueShare"
									inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
									InputProps={{ inputProps: { min: 0, max: 100 } }}
									label="% de revenue share"
									name="revenueShare"
									placeholder="30"
									size="medium"
									type="number"
									variant="outlined"
									value={Number(newAffiliateData.revenueShare)}
									onChange={({ target: { name, value } }) => changeNewAffiliateData({ [name]: fMinMax(value, 0, 100) })}
								/>
								<Box>
									{newAffiliateData.cpaActive && (
										<TextField
											fullWidth
											id="cpaValue"
											inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
											label="Valor do CPA	(R$)"
											name="cpaValue"
											placeholder="100"
											size="medium"
											type="number"
											variant="outlined"
											value={Number(newAffiliateData.cpaValue)}
											onChange={({ target: { name, value } }) => changeNewAffiliateData({ [name]: Number(value) })}
											disabled={!newAffiliateData.cpaActive}
										/>
									)}
									<FormControlLabel
										control={
											<Checkbox
												checked={newAffiliateData.cpaActive}
												id="cpaActive"
												name="cpaActive"
												onChange={({ target: { name, checked } }) => changeNewAffiliateData({ [name]: checked })}
											/>
										}
										label="Ativar CPA"
										sx={{
											float: "right",
										}}
									/>
								</Box>
							</Stack>
							<Box sx={{ textAlign: "end" }}>
								<LoadingButton
									variant="contained"
									color="success"
									loading={creatingAffiliate}
									disabled={newAffiliateData.email.length === 0}
									onClick={() => {
										createAffiliate();
										closeModal();
									}}>
									Adicionar
								</LoadingButton>
							</Box>
						</Box>
					)}
					open={false}
					children={<></>}
				/>
			</Stack>
			<Scrollbar>
				<TableContainer sx={{ minWidth: 800, position: "relative" }}>
					<Table size={dense ? "small" : "medium"}>
						<TableHeadCustom<AffiliatedTableRowType>
							headLabel={affiliatedHeadCells}
							order={order}
							orderBy={orderBy}
							onSort={handleRequestSort}
							rowCount={tableData.length}
							extra={true}
						/>
						<TableBody>
							{stableSort(
								tableData.filter((row) =>
									Object.values(row)
										.filter((value) => typeof value !== "boolean")
										.some((value: string | number) =>
											(typeof value === "string" ? value : new Date(value).getTime() ? new Date(value).toLocaleString(dateLocale, dateOptions) : `${value}`)
												.toLowerCase()
												.includes(searchFilter.toLowerCase())
										)
								),
								getComparator(order, orderBy)
							)
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => (
									<AffiliatedTableRow isItemSelected={isSelected(row.id)} row={row} key={row.id} labelId={`accounts-table-checkbox-${index}`} handleSelect={handleSelectRow} />
								))}
							<TableRow hover role="checkbox" tabIndex={-1}>
								<TableCell colSpan={8} />
							</TableRow>
							<TableNoData isNotFound={!tableData.length} />
						</TableBody>
					</Table>
				</TableContainer>
			</Scrollbar>
			<Box sx={{ position: "relative" }}>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
					component="div"
					count={rowCount}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage={"Contas por página"}
					getItemAriaLabel={function defaultGetAriaLabel(type) {
						return `Ir para a ${type === "next" ? "próxima página" : type === "first" ? "primeira página" : type === "last" ? "última página" : "pagina anterior"} `;
					}}
					showFirstButton={page !== 0 && rowCount > rowsPerPage * page}
					showLastButton={page !== Math.ceil(rowCount / rowsPerPage) - 1 && rowCount > rowsPerPage * page}
				/>
				<FormControlLabel
					sx={{ px: 3, py: 1.5, top: 0, position: { md: "absolute" } }}
					control={<Switch checked={dense} onChange={handleChangeDense} />}
					label="Modo minimalista"
				/>
			</Box>
		</Card>
	);
}
