import { TableRow, TableCell } from "@mui/material";
import EmptyContent from "../empty-content";

type Props = {
	isNotFound: boolean;
};

export default function TableNoData({ isNotFound }: Props) {
	return (
		<TableRow>
			{isNotFound ? (
				<TableCell colSpan={12}>
					<EmptyContent
						title="Nada encontrado"
						sx={{
							"& span.MuiBox-root": { height: 160 },
						}}
					/>
				</TableCell>
			) : (
				<TableCell colSpan={12} sx={{ p: 0 }} />
			)}
		</TableRow>
	);
}
