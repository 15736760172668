import { alpha } from "@mui/material";
import { Box, Container, Stack, TextField } from "@mui/material";
import { Button, Typography, useTheme } from "@mui/material";
import { Card } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { GameData } from "src/@types/websocket";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EmptyContent from "src/components/empty-content";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs";
import Iconify from "src/components/iconify";
import Loading from "src/components/Loading";
import Page from "src/components/Page";
import TopRanking from "src/sections/app/TopRanking";
import useWebSocket from "src/hooks/useWebSocket";
import WidgetSummary from "src/sections/app/WidgetSummary";

type Games = "double" | "doublepro" | "rocket" | "mines" | "plinko" | "dice";

export default function GamePage() {
	const location = useLocation();
	const navigate = useNavigate();
	const theme = useTheme();
	const websocketData = useWebSocket();
	const [game, setGame] = useState<GameData | null>(null);
	const [detailsConfig, setDetailsConfig] = useState({
		after: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0).getTime() - 1000 * 60 * 60 * 24 * 6,
		before: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 999).getTime(),
	});
	const gameName = location.pathname.split("/")[3] as Games;

	useEffect(() => {
		if (!websocketData[gameName]) {
			setGame(null);
			websocketData.websocket.emit(`Game.${gameName.charAt(0).toUpperCase() + gameName.slice(1)}.Get`, {
				after: detailsConfig.after,
				before: detailsConfig.before,
			});
		} else {
			setGame(websocketData[gameName]);
		}
	}, [detailsConfig.after, detailsConfig.before, game, gameName, location, websocketData]);

	const handleFilterData = () => {
		websocketData.setWebsocketState((_state) => ({ ..._state, [gameName]: null }));
		setGame(null);
	};

	if (!game) return <Loading loadingText="Carregando jogo" />;

	return (
		<Page title="Estatísticas">
			<Container maxWidth="xl">
				<CustomBreadcrumbs heading="Página inicial" links={[{ name: `Atualizado em ${new Date().toLocaleString("pt-BR")}` }]} />
				<Box sx={{ my: 3, textAlign: "center" }}>
					<Box>
						<Stack spacing={3} direction="row" sx={{ justifyContent: "center", alignItems: "center", alignContent: "center" }}>
							<DatePicker
								renderInput={(props) => <TextField size={"small"} {...props} />}
								label="A partir da data"
								value={detailsConfig.after}
								onChange={(newValue: Date | null) => {
									setDetailsConfig((_oldConfig) => ({ ..._oldConfig, after: newValue ? newValue.getTime() : Date.now() }));
								}}
							/>
							<Typography variant="subtitle2" color="text.secondary">
								{((detailsConfig.before - detailsConfig.after) / 24 / 60 / 60 / 1000).toFixed(0)} dia
								{Math.floor((detailsConfig.before - detailsConfig.after) / 24 / 60 / 60 / 1000) - 1 > 1 ? "s" : ""}
							</Typography>
							<DatePicker
								renderInput={(props) => <TextField size={"small"} {...props} />}
								label="Até a data"
								value={detailsConfig.before}
								onChange={(newValue: Date | null) => {
									newValue?.setHours(23, 59, 59, 999);
									setDetailsConfig((_oldConfig) => ({ ..._oldConfig, before: newValue ? newValue.getTime() : Date.now() }));
								}}
							/>
						</Stack>
						<Stack spacing={3} direction="row" sx={{ mt: 1, justifyContent: "center" }}>
							{[1, 7, 14, 31].map((dayOffset) => (
								<Button
									key={`date-change-${dayOffset}`}
									color="secondary"
									variant="soft"
									size="small"
									onClick={() =>
										setDetailsConfig((_oldConfig) => ({
											..._oldConfig,
											after: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0).getTime() - 1000 * 60 * 60 * 24 * (dayOffset - 1),
											before: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 999).getTime(),
										}))
									}>
									{dayOffset} dia{dayOffset > 1 ? "s" : ""}
								</Button>
							))}
						</Stack>
					</Box>
					<Box sx={{ mt: 2 }}>
						<Button variant="contained" color="success" onClick={handleFilterData}>
							Filtrar
						</Button>
					</Box>
				</Box>
				<Grid container spacing={3} sx={{ display: "flex", alignItems: "center", alignContent: "center", justifyContent: "space-around" }}>
					{game.count > 0 ? (
						<>
							<Grid item xs={12} md={5}>
								<Stack spacing={3}>
									<Card sx={{ p: 2, textAlign: "center" }}>
										<Typography variant="body2">Total em {game.count} jogos</Typography>
										<Stack direction="row" spacing={3} sx={{ justifyContent: "center", mt: 2 }}>
											<Box sx={{ width: 90, borderRadius: `${theme.shape.borderRadius}px`, backgroundColor: alpha(theme.palette.success.main, 0.08), p: 1 }}>
												<Iconify icon={"iconoir:emoji"} color={theme.palette.success.main} sx={{ fontSize: 56 }} />
												<Typography variant="subtitle2" color="success.main">
													{((game.players.reduce((prev, curr) => prev + curr.gain.count, 0) * 100) / game.players.reduce((prev, curr) => prev + curr.bets.count, 0)).toFixed(2)}%
												</Typography>
											</Box>
											<Box sx={{ width: 90, borderRadius: `${theme.shape.borderRadius}px`, backgroundColor: alpha(theme.palette.error.main, 0.08), p: 1 }}>
												<Iconify icon={"iconoir:emoji-sad"} color={theme.palette.error.main} sx={{ fontSize: 56 }} />
												<Typography variant="subtitle2" color="error.main">
													{((game.players.reduce((prev, curr) => prev + curr.loss.count, 0) * 100) / game.players.reduce((prev, curr) => prev + curr.bets.count, 0)).toFixed(2)}%
												</Typography>
											</Box>
										</Stack>
									</Card>
									<WidgetSummary
										title={`Quantia apostada em ${game.players.reduce((prev, curr) => prev + curr.bets.count, 0).toLocaleString()} apostas`}
										total={game.players
											.reduce((prev, curr) => prev + curr.bets.amount, 0)
											.toLocaleString("pt-BR", {
												currency: "BRL",
												style: "currency",
											})}
										icon={
											<Iconify
												icon={"iconoir:money-square"}
												sx={{
													width: "100%",
													height: "100%",
													color: theme.palette.secondary.main,
												}}
											/>
										}
										bgColor={alpha(theme.palette.secondary.main, 0.08)}
									/>
									<WidgetSummary
										title={`Quantia ganha em ${game.players.reduce((prev, curr) => prev + curr.gain.count, 0).toLocaleString()} apostas`}
										total={game.players
											.reduce((prev, curr) => prev + curr.gain.amount, 0)
											.toLocaleString("pt-BR", {
												currency: "BRL",
												style: "currency",
											})}
										icon={
											<Iconify
												icon={"iconoir:money-square"}
												sx={{
													width: "100%",
													height: "100%",
													color: theme.palette.success.main,
												}}
											/>
										}
										bgColor={alpha(theme.palette.success.main, 0.08)}
									/>
									<WidgetSummary
										title={`Quantia perdida em ${game.players.reduce((prev, curr) => prev + curr.loss.count, 0).toLocaleString()} apostas`}
										total={game.players
											.reduce((prev, curr) => prev + curr.loss.amount, 0)
											.toLocaleString("pt-BR", {
												currency: "BRL",
												style: "currency",
											})}
										icon={
											<Iconify
												icon={"iconoir:money-square"}
												sx={{
													width: "100%",
													height: "100%",
													color: theme.palette.error.main,
												}}
											/>
										}
										bgColor={alpha(theme.palette.error.main, 0.08)}
									/>
								</Stack>
							</Grid>
							<Grid item xs={12} md={5}>
								<TopRanking
									title={"Top 5 apostadores"}
									subheader={"Apostadores lucraram mais nesse jogo"}
									icon={"tabler:moneybag"}
									formatAsMoney={true}
									list={game.players.map((punter) => ({
										id: punter.userId,
										name: "",
										count: punter.gain.amount - punter.loss.amount,
										trophyAction: () => navigate(`/app/accounts/${punter.userId}/details`),
									}))}
									getUserEmailById={true}
								/>
							</Grid>
						</>
					) : (
						<EmptyContent
							title="Nenhum dado disponível"
							sx={{
								"& span.MuiBox-root": { height: 160 },
							}}
						/>
					)}
				</Grid>
			</Container>
		</Page>
	);
}
