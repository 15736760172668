import { useState, ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Roles } from "src/@types/auth";
import { rolesDefaultRoute } from "src/config";
import useAuth from "src/hooks/useAuth";

type AuthGuardProps = {
	children: ReactNode;
	roles: Roles[];
	subDomains?: string[];
};

export default function RouteGuard({ children, roles, subDomains }: AuthGuardProps) {
	const { user } = useAuth();

	const { pathname } = useLocation();

	const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

	// eslint-disable-next-line no-underscore-dangle
	if (user && !user.roles.some((role) => roles.includes(role))) {
		if (user.enableAffiliation && !pathname.includes(user.id)) {
			return <Navigate to={`/app/affiliated/${user.id}/details`} />;
		} else if (user.roles.length > 0 && !user.enableAffiliation) {
			return <Navigate to={rolesDefaultRoute[user.roles[0]]} />;
		}
	}

	if (requestedLocation && pathname !== requestedLocation) {
		setRequestedLocation(null);
		return <Navigate to={requestedLocation} />;
	}

	return <>{children}</>;
}
