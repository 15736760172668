import { dateLocale, dateOptions } from "../../_constants";
import { AffiliatedPaymentsTableRowType } from "./AffiliatedPaymentsTable";
import { DefaultTableRowProps } from "../../_types";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function AffiliatedPaymentsTableRow({ isItemSelected, row, labelId, handleSelect }: DefaultTableRowProps<AffiliatedPaymentsTableRowType>) {
	return (
		<TableRow hover>
			<TableCell align="left">{row.type === "cpa" ? "CPA" : "Revenue Share"}</TableCell>
			<TableCell align="left">
				{Math.abs(row.amount).toLocaleString("pt-BR", {
					currency: "BRL",
					style: "currency",
				})}
			</TableCell>
			<TableCell align="left">
				{row.type === "cpa"
					? "Valor do CPA " +
					  row.thatDayValue.toLocaleString("pt-BR", {
							currency: "BRL",
							style: "currency",
					  })
					: row.thatDayValue + "% de Revenue Share"}
			</TableCell>
			<TableCell align="left">{row.email}</TableCell>
			<TableCell align="left">{new Date(row.date).toLocaleString(dateLocale, dateOptions)}</TableCell>
		</TableRow>
	);
}
