import { AuthIllustration } from "src/assets";
import { LoginForm } from "src/sections/auth";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Logo from "src/components/logo";
import Page from "src/components/Page";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useResponsive from "src/hooks/useResponsive";

const RootStyle = styled("div")(({ theme }) => ({
	[theme.breakpoints.up("md")]: {
		display: "flex",
	},
}));

const HeaderStyle = styled("header")(({ theme }) => ({
	top: 0,
	zIndex: 9,
	lineHeight: 0,
	width: "100%",
	display: "flex",
	alignItems: "center",
	position: "absolute",
	padding: theme.spacing(3),
	justifyContent: "space-between",
	[theme.breakpoints.up("md")]: {
		alignItems: "flex-start",
		padding: theme.spacing(7, 5, 0, 7),
	},
}));

const SectionStyle = styled(Card)(({ theme }) => ({
	width: "100%",
	maxWidth: 464,
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
	maxWidth: 480,
	margin: "auto",
	minHeight: "100vh",
	display: "flex",
	justifyContent: "center",
	flexDirection: "column",
	padding: theme.spacing(12, 0),
}));

export default function LoginPage() {
	const mdUp = useResponsive("up", "md");

	return (
		<Page title="Login">
			<RootStyle>
				<HeaderStyle>
					<Logo />
				</HeaderStyle>

				{mdUp && (
					<SectionStyle>
						<Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
							Olá, boas vindas novamente
						</Typography>
						<AuthIllustration />
					</SectionStyle>
				)}

				<Container maxWidth="sm">
					<ContentStyle>
						<Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
							<Box sx={{ flexGrow: 1 }}>
								<Typography variant="h4" gutterBottom>
									Entrar no sistema
								</Typography>
								<Typography sx={{ color: "text.secondary" }}>Insira as suas informações abaixo.</Typography>
							</Box>
						</Stack>

						<LoginForm />
					</ContentStyle>
				</Container>
			</RootStyle>
		</Page>
	);
}
