/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import { BoxProps } from "@mui/material";
import Box from "@mui/material/Box";
import { forwardRef, ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { websiteConfig } from "src/config";

interface Props extends BoxProps {
	children: ReactNode;
	meta?: ReactNode;
	title: string;
}
const Page = forwardRef<HTMLDivElement, Props>(({ children, title = "", meta, ...other }, ref) => (
	<>
		<Helmet>
			<title>{`${title} | Back Office ${websiteConfig.formatedName}`}</title>
			{meta}
		</Helmet>

		<Box ref={ref} {...other}>
			{children}
		</Box>
	</>
));

export default Page;
