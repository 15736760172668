import { Theme } from "@mui/material/styles";
import {
	Box,
	SxProps,
	Checkbox,
	TableRow,
	TableCell,
	TableHead,
	TableSortLabel,
} from "@mui/material";

const visuallyHidden = {
	border: 0,
	margin: -1,
	padding: 0,
	width: "1px",
	height: "1px",
	overflow: "hidden",
	position: "absolute",
	whiteSpace: "nowrap",
	clip: "rect(0 0 0 0)",
} as const;

type Props<T> = {
	extra?: boolean;
	headLabel: any[];
	numSelected?: number;
	onSelectAllRows?: (checked: boolean) => void;
	onSort?: (id: keyof T) => void;
	order?: "asc" | "desc";
	orderBy?: string;
	rowCount?: number;
	sx?: SxProps<Theme>;
};

export default function TableHeadCustom<T>({
	order,
	orderBy,
	rowCount = 0,
	headLabel,
	numSelected = 0,
	sx,
	extra,
	onSort,
	onSelectAllRows,
}: Props<T>) {
	return (
		<TableHead sx={sx}>
			<TableRow>
				{onSelectAllRows && (
					<TableCell padding="checkbox">
						<Checkbox
							color="primary"
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={rowCount > 0 && numSelected === rowCount}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
								onSelectAllRows(event.target.checked)
							}
							inputProps={{
								"aria-label": "Selecionar tudo",
							}}
						/>
					</TableCell>
				)}

				{headLabel.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.align || "left"}
						sortDirection={orderBy === headCell.id ? order : false}
						sx={{ width: headCell.width, minWidth: headCell.minWidth }}>
						{onSort ? (
							<TableSortLabel
								hideSortIcon
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={() => onSort(headCell.id)}
								sx={{ textTransform: "capitalize" }}>
								{headCell.label}

								{orderBy === headCell.id ? (
									<Box sx={{ ...visuallyHidden }}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
						) : (
							headCell.label
						)}
					</TableCell>
				))}

				{extra && <TableCell sx={{ width: 10 }} />}
			</TableRow>
		</TableHead>
	);
}
