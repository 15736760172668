import { DefaultTableRowProps } from "../../_types";
import { AffiliatedCpaTableRowType } from "./AffiliatedCpaTable";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function AffiliatedCpaTableRow({ isItemSelected, row, labelId, handleSelect }: DefaultTableRowProps<AffiliatedCpaTableRowType>) {
	return (
		<TableRow hover>
			<TableCell align="left" id={labelId}>
				{new Array(row.userId.length - (row.userId.length - 16)).fill("*").map((x) => x)}
				{row.userId.slice(row.userId.length - 16)}
			</TableCell>
			<TableCell align="left">{row.amount.toLocaleString("pt-BR", { currency: "BRL", style: "currency", maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
			<TableCell align="left">{row.cpaCheck}</TableCell>
			<TableCell align="left">
				{row.cpaAmount.toLocaleString("pt-BR", {
					currency: "BRL",
					style: "currency",
					maximumFractionDigits: 2,
					minimumFractionDigits: 2,
				})}
			</TableCell>
		</TableRow>
	);
}
