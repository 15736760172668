import { useState, ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import LoginPage from "src/pages/auth/Login";
import useAuth from "src/hooks/useAuth";

type AuthGuardProps = {
	children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
	const { isAuthenticated } = useAuth();

	const { pathname } = useLocation();

	const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

	if (!isAuthenticated()) {
		if (pathname !== requestedLocation) {
			setRequestedLocation(pathname);
		}
		return <LoginPage />;
	}

	if (requestedLocation && pathname !== requestedLocation) {
		setRequestedLocation(null);
		return <Navigate to={requestedLocation} />;
	}

	return <>{children}</>;
}
