import { AffiliatedSubAffiliatesTableRowType } from "./AffiliatedSubAffiliatesTable";
import { Button } from "@mui/material";
import { dateLocale, dateOptions, subAffiliatedBaseRoute } from "../../_constants";
import { NoSelectTableRowProps } from "../../_types";
import { useNavigate } from "react-router-dom";
import Iconify from "src/components/iconify";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function AffiliatedSubAffiliatesTableRow({ row, labelId, searchFilter }: NoSelectTableRowProps<AffiliatedSubAffiliatesTableRowType>) {
	const navigate = useNavigate();

	if (
		!Object.values(row).some((value) =>
			(typeof value === "string" ? value : new Date(value).getTime() ? new Date(value).toLocaleString(dateLocale, dateOptions) : value.toString())
				.toLowerCase()
				.includes(searchFilter.toLowerCase())
		)
	) {
		return <></>;
	}

	const onDetailsRow = () => {
		navigate(`${subAffiliatedBaseRoute}/${row.id}`);
	};

	return (
		<TableRow>
			<TableCell id={labelId}>
				{new Array(row.id.length - (row.id.length - 16)).fill("*").map((x) => x)}
				{row.id.slice(row.id.length - 16)}
			</TableCell>
			<TableCell align="left">{row.email}</TableCell>
			<TableCell align="left">{row.cpa.toLocaleString("pt-BR", { currency: "BRL", style: "currency", maximumFractionDigits: 2, minimumFractionDigits: 2 })}</TableCell>
			<TableCell align="left">{row.revenueShare}%</TableCell>
			<TableCell align="left">{new Date(row.createdAt).toLocaleString(dateLocale, dateOptions)}</TableCell>
			<TableCell align="right">
				<Button variant="text" color="secondary" onClick={onDetailsRow} endIcon={<Iconify icon={"iconoir:nav-arrow-right"} />} name="sub-affiliate-open">
					Abrir
				</Button>
			</TableCell>
		</TableRow>
	);
}
