import { DefaultTableConfig } from "src/sections/app/_types";
import { Log } from "src/@types/server/database";
import { LogsTable } from "src/sections/app/logs";
import { LogsTableRowType } from "src/sections/app/logs/LogsTable";
import { useEffect, useState, useCallback } from "react";
import { websiteConfig } from "src/config";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs";
import Loading from "src/components/Loading";
import Page from "src/components/Page";
import useWebSocket from "src/hooks/useWebSocket";

export default function LogsPage() {
	const { websocket } = useWebSocket();
	const [logs, setLogs] = useState<Log[] | null>(null);
	const [requestingLogs, setRequestingLogs] = useState(false);
	const [tableConfig, setTableConfig] = useState<DefaultTableConfig<LogsTableRowType>>({
		limit: 25,
		order: "desc",
		orderBy: "date",
		page: 0,
	});

	const getLogs = useCallback(
		(config: DefaultTableConfig<LogsTableRowType>) => {
			setTableConfig(config);

			setLogs([]);

			websocket.emit("SystemLogs.Get", {
				...config,
				offset: config.page * config.limit,
			});
		},
		[websocket]
	);

	useEffect(() => {
		if (!requestingLogs && !logs) {
			setRequestingLogs(true);
			getLogs(tableConfig);
		}
	}, [getLogs, logs, requestingLogs, tableConfig]);

	useEffect(() => {
		websocket.on("SystemLogs.Get", (data) => {
			setLogs(data.data);
		});
	}, [websocket]);

	const onChangeTableConfig = (data: { type: keyof DefaultTableConfig<LogsTableRowType>; data: any }[]) => {
		const newData = data.reduce((prev, curr) => ({ ...prev, [curr.type]: curr.data }), {});

		setRequestingLogs(true);
		// getLogs({
		// 	...tableConfig,
		// 	...newData,
		// });
		setTableConfig({
			...tableConfig,
			...newData,
		});
	};

	if (!logs || (!logs.length && requestingLogs)) return <Loading loadingText="Carregando registros" />;

	return (
		<Page title="Gerenciamento: Registros">
			<Container maxWidth="xl">
				<CustomBreadcrumbs heading="Registros" links={[{ name: `Atualizado em ${new Date().toLocaleString("pt-BR")}` }]} />
				<Grid container spacing={2} sx={{ alignItems: "center", alignContent: "center" }}>
					<LogsTable
						rows={logs
							.filter((log) => log.database === websiteConfig.database)
							.map((log) => ({
								id: log.id,
								message: log.message,
								action: log.action,
								success: log.success.toString(),
								userId: log.userId,
								agent: log.info.agent,
								ip: log.info.ip,
								date: log.date,
							}))}
						tableConfig={tableConfig}
						itemCount={logs.length}
						onChangeTableConfig={onChangeTableConfig}
					/>
				</Grid>
			</Container>
		</Page>
	);
}
