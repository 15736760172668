const cloudinaryBaseUrl = "https://res.cloudinary.com/dbplyotri/image/upload";

export const serverUrl = "https://backoffice.eway.dev:1603";

export const avaibleWebsiteConfig = [
	{
		domainKey: "arbety",
		formatedName: "Arbety",
		database: "arbety",
		cassinoDomain: "https://arbety.com",
		logo: `${cloudinaryBaseUrl}/v1671932187/Cassino%20Dash/logo_arbety.png`,
		withdrawPlatforms: [
			{ name: "Paguepay", value: "/pp" },
			{ name: "P2P", value: "/" },
			{ name: "Hexopay", value: "/hexopay" },
		],
	},
	{
		domainKey: "eurowin",
		formatedName: "Euro Win",
		database: "eurolucky",
		cassinoDomain: "https://eurowin.bet",
		logo: `${cloudinaryBaseUrl}/v1671727885/Cassino%20Dash/logo_eurowin.png`,
		withdrawPlatforms: [{ name: "Starspay", value: "/starspay" }],
	},
];

export const rolesDefaultRoute: { [key: string]: string } = {
	"super-admin": "/app/accounts",
	"withdraws.see": "/app/withdraws",
	"deposits.verify": "/app/deposits",
	"affiliates.see": "/app/affiliated/list",
	"accounts.see": "/app/accounts/list",
	"affiliates.manage": "/app/affiliated/list",
	admin: "/app/accounts",
	user: "/app/accounts",
	dashboard: "/app/main",
	statistics: "/app/statistics",
};

export const dubDomainsDefaultRoute: { [key: string]: string } = {
	deposits: "/app/deposits",
	backoffice: "/app/accounts",
	localhost: "/app/accounts",
};

export const websiteConfig = avaibleWebsiteConfig.find((config) => window.location.href.includes(config.domainKey)) || avaibleWebsiteConfig[0];
