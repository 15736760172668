import { Buffer } from "buffer";
import axios from "./axios";

const isValidToken = (accessToken: string) => {
	if (!accessToken) {
		return false;
	}
	const decoded = JSON.parse(
		Buffer.from(accessToken.split(".")[1], "base64").toString("utf-8")
	);

	const currentTime = Date.now();

	return decoded.user.expiration > currentTime;
};

const handleTokenExpired = (exp: number, logout: VoidFunction) => {
	let expiredTimer;

	const currentTime = Date.now();

	const timeLeft = exp - currentTime;

	clearTimeout(expiredTimer);

	expiredTimer = setTimeout(() => {
		alert("Sua sessão expirou! Faça login novamente.");
		logout();

		sessionStorage.removeItem("accessToken");

		window.location.href = "/auth/login";
	}, timeLeft);
};

const setSession = (accessToken: string | null, logout: VoidFunction) => {
	if (accessToken) {
		sessionStorage.setItem("accessToken", accessToken);
		// axios.defaults.params = { token: `${accessToken}` };
		axios.defaults.headers.common.Authorization = `bearer ${accessToken}`;

		handleTokenExpired(
			JSON.parse(
				Buffer.from(accessToken.split(".")[1], "base64").toString("utf-8")
			).user.expiration,
			logout
		);
	} else {
		sessionStorage.removeItem("accessToken");
		delete axios.defaults.headers.common.accessToken;
	}
};

export { isValidToken, setSession };
