import { affiliatedCpaHeadCells, dateLocale, dateOptions } from "../../_constants";
import { StaticDefaultTableProps, TableOrder } from "../../_types";
import { emptyRows, getComparator, stableSort } from "../../../../utils/tableUtils";
import { TableEmptyRows, TableHeadCustom, TableNoData } from "src/components/table";
import { useState, MouseEvent, ChangeEvent } from "react";
import AffiliatedCpaTableRow from "./AffiliatedCpaTableRow";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";

export interface AffiliatedCpaTableRowType {
	id: string;
	userId: string;
	amount: number;
	cpaCheck: string;
	cpaAmount: number;
	date: number;
}

export default function AffiliatedCpaTable({ rows, tableConfig, dense: dense_ = false }: StaticDefaultTableProps<AffiliatedCpaTableRowType>) {
	const [dense, setDense] = useState(dense_);
	const [order, setOrder] = useState<TableOrder>(tableConfig.order);
	const [orderBy, setOrderBy] = useState<keyof AffiliatedCpaTableRowType>(tableConfig.orderBy);
	const [page, setPage] = useState(tableConfig.page);
	const [rowCount] = useState(rows.length);
	const [rowsPerPage, setRowsPerPage] = useState(tableConfig.limit);
	const [searchFilter, setSearchFilter] = useState("");
	const [selected, setSelected] = useState<"all" | readonly string[]>([]);
	const [tableData] = useState<AffiliatedCpaTableRowType[]>(rows);

	const handleRequestSort = (id: keyof AffiliatedCpaTableRowType) => {
		const order_ = orderBy === id && order === "asc";
		setOrder(order_ ? "desc" : "asc");
		setOrderBy(id);
	};

	const handleSelectRow = (event: MouseEvent<unknown>, id: string) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: readonly string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(Number(event.target.value));
		setPage(0);
	};

	const handleChangeDense = (event: ChangeEvent<HTMLInputElement>) => {
		setDense(event.target.checked);
	};

	const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchFilter(event.target.value);
	};

	const isSelected = (id: string) => (selected === "all" ? true : selected.indexOf(id) !== -1);

	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<Stack
					spacing={2}
					direction={"row"}
					sx={{
						alignItems: "center",
						alignContent: "center",
						pl: { xs: 1 },
						pr: { xs: 1 },
						py: 2,
					}}>
					<TextField
						type="text"
						label="Procurar"
						placeholder="Parte do ID"
						size={dense ? "small" : "medium"}
						value={searchFilter}
						onChange={handleChangeSearch}
						sx={{
							width: 300,
						}}
					/>
				</Stack>
				<TableContainer>
					<Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
						<TableHeadCustom<AffiliatedCpaTableRowType>
							headLabel={affiliatedCpaHeadCells}
							order={order}
							orderBy={orderBy}
							onSort={handleRequestSort}
							rowCount={tableData.length}
							extra={false}
						/>
						<TableBody>
							{stableSort(
								tableData.filter((row) =>
									Object.values(row).some((value) =>
										(typeof value === "string" ? value : new Date(value).getTime() ? new Date(value).toLocaleString(dateLocale, dateOptions) : `${value}`)
											.toLowerCase()
											.includes(searchFilter.toLowerCase())
									)
								),
								getComparator(order, orderBy)
							)
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => (
									<AffiliatedCpaTableRow
										isItemSelected={isSelected(row.id)}
										row={row}
										key={row.id}
										labelId={`affiliatedCpa-table-checkbox-${index}`}
										handleSelect={handleSelectRow}
									/>
								))}

							<TableEmptyRows height={dense ? 56 : 76} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />
							<TableNoData isNotFound={!tableData.length} />
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
					component="div"
					count={rowCount}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage={"CPAs por página"}
					getItemAriaLabel={function defaultGetAriaLabel(type) {
						return `Ir para a ${type === "next" ? "próxima página" : type === "first" ? "primeira página" : type === "last" ? "última página" : "pagina anterior"} `;
					}}
					showFirstButton={page !== 0 && rowCount > rowsPerPage * page}
					showLastButton={page !== Math.ceil(rowCount / rowsPerPage) - 1 && rowCount > rowsPerPage * page}
				/>
			</Paper>
			<FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Modo minimalista" />
		</Box>
	);
}
