import { Box, Button, Card, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { Roles } from "src/@types/auth";
import { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs";
import Grid from "@mui/material/Grid";
import Iconify from "src/components/iconify";
import Loading from "src/components/Loading";
import Page from "src/components/Page";
import useWebSocket from "src/hooks/useWebSocket";

type SystemUser = {
	email: string;
	id: string;
	createdAt: number;
	roles: Roles[];
	affiliate: boolean;
};

export default function AdminPermissionsPage() {
	const { websocket } = useWebSocket();
	const [systemRoles, setSystemRoles] = useState<null | Roles[]>(null);
	const [systemUsers, setSystemUsers] = useState<null | SystemUser[]>(null);

	useEffect(() => {
		if (!systemRoles) websocket.emit("System.Roles.Get", {});
		if (!systemUsers) websocket.emit("System.Accounts.Get", {});

		websocket
			.on("System.Roles.Get", (data) => {
				setSystemRoles(data.roles);
			})
			.on("System.Accounts.Get", (data) => {
				setSystemUsers(data.accounts);
			});
	}, [systemRoles, systemUsers, websocket]);

	if (!systemRoles || !systemUsers) return <Loading loadingText="Carregando dados" />;

	const handleUpdateUserRoles = (userId: string, roles: Roles[]) => {
		websocket.emit("System.Account.Roles.Update", {
			userId,
			roles,
		});
	};

	const handleChangeSystem = (accountId: string, data: Partial<SystemUser>) => {
		setSystemUsers((oldAccounts) =>
			oldAccounts
				? [
						...oldAccounts.filter((account) => account.id !== accountId),
						{
							...oldAccounts.filter((account) => account.id === accountId)[0],
							...data,
						},
				  ]
				: null
		);
	};

	const handleChange = (accountId: string, event: SelectChangeEvent<string[]>) => {
		const {
			target: { value },
		} = event;

		handleChangeSystem(accountId, {
			roles: (typeof value === "string" ? value.split(",") : value) as Roles[],
		});
	};

	return (
		<Page title="Página inicial">
			<Container maxWidth="xl">
				<CustomBreadcrumbs heading="Página inicial" links={[{ name: `Atualizado em ${new Date().toLocaleString("pt-BR")}` }]} />
				<Grid container spacing={3} sx={{ justifyContent: "space-around", alignItems: "center", alignContent: "center" }}>
					{systemUsers
						.sort((a, b) => a.email.localeCompare(b.email))
						.map((user) => (
							<Grid item xs={12} md={4} lg={3} key={user.id}>
								<Card sx={{ p: 3 }}>
									<Typography variant="subtitle2" color="text.secondary">
										ID: <Typography variant="caption">{user.id}</Typography>
									</Typography>
									<Typography variant="subtitle2" color="text.secondary">
										E-Mail:{" "}
										<Typography variant="caption">
											{user.email}{" "}
											<Typography color="primary" component="span" variant="caption">
												{user.affiliate ? `(Afiliado)` : ""}
											</Typography>
										</Typography>
									</Typography>

									<Stack direction="row" spacing={3} sx={{ alignContent: "center", alignItems: "Center", justifyContent: "space-between", mt: 3 }}>
										<FormControl fullWidth>
											<InputLabel>Cargos do usuário</InputLabel>
											<Select
												fullWidth
												multiple
												displayEmpty
												value={user.roles}
												onChange={(event) => handleChange(user.id, event)}
												input={
													<OutlinedInput
														fullWidth
														label="Cargos"
														sx={{
															overflow: "hidden",
															textOverflow: "ellipsis",
															display: "-webkit-box",
															WebkitLineClamp: 1,
															lineClamp: 1,
															WebkitBoxOrient: "vertical",
														}}
													/>
												}
												renderValue={(selected) => {
													return selected
														.map((option) =>
															option
																.split(".")
																.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
																.join(" ")
																.split("-")
																.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
																.join(" ")
														)
														.join(", ");
												}}
												MenuProps={{
													PaperProps: {
														style: {
															maxHeight: 120,
														},
													},
												}}
												inputProps={{ "aria-label": "Without label" }}>
												{systemRoles.map((option) => (
													<MenuItem key={`${user.id}-${option}`} value={option}>
														{option
															.split(".")
															.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
															.join(" ")
															.split("-")
															.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
															.join(" ")}
													</MenuItem>
												))}
											</Select>
										</FormControl>
										<Box>
											<Button
												size="small"
												variant="contained"
												color="success"
												startIcon={<Iconify icon="iconoir:check" />}
												onClick={() => handleUpdateUserRoles(user.id, user.roles)}>
												Salvar
											</Button>
										</Box>
									</Stack>
								</Card>
							</Grid>
						))}
				</Grid>
			</Container>
		</Page>
	);
}
