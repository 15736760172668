import { Card, Typography, Box, CardProps } from "@mui/material";
import { fShortenNumber } from "src/utils/formatNumber";
import { ReactElement } from "react";

interface Props extends CardProps {
	title: string;
	total: number | string;
	icon: ReactElement | null;
	bgColor: string;
	shortNumber?: boolean;
	shortDigits?: number;
}

export default function WidgetSummary({ title, total, icon, sx, bgColor, shortNumber = false, shortDigits = 0, ...other }: Props) {
	return (
		<Card
			sx={{
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
				p: 2,
				pl: 3,
				...sx,
			}}
			{...other}>
			<div>
				<Typography variant="h5">{shortNumber ? fShortenNumber(Number(total), shortDigits) : total}</Typography>

				<Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
					{title}
				</Typography>
			</div>
			{icon && (
				<Box
					sx={{
						width: 60,
						height: 60,
						lineHeight: 0,
						borderRadius: "50%",
						bgcolor: bgColor,
						p: 1,
					}}>
					{icon}
				</Box>
			)}
		</Card>
	);
}
