/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Link, Typography, alpha } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import useAuth from "src/hooks/useAuth";

const StyledRoot = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	padding: theme.spacing(2, 2.5),
	borderRadius: Number(theme.shape.borderRadius) * 1.5,
	backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

export default function NavAccount() {
	const { user } = useAuth();

	return (
		<Link component={RouterLink} to={user?.enableAffiliation ? `/app/affiliated/${user.id}/details` : "/app"} underline="none" color="inherit">
			<StyledRoot>
				<Box sx={{ ml: 2, minWidth: 0 }}>
					<Typography variant="subtitle2" component="span" noWrap>
						{user?.username}
					</Typography>
					{user?.enableAffiliation && (
						<Typography variant="caption" color="primary" component="span">
							{" "}
							(Afiliado)
						</Typography>
					)}

					<Typography variant="body2" noWrap sx={{ color: "text.secondary" }}>
						{user?.email}
					</Typography>
				</Box>
			</StyledRoot>
		</Link>
	);
}
