import { memo } from "react";
import { useTheme } from "@mui/material/styles";

function AuthIllustration() {
	const theme = useTheme();

	const PRIMARY_MAIN = theme.palette.primary.main;

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
			<g>
				<path
					fill={PRIMARY_MAIN}
					d="M258.06 135.91c-13.85 14.94-21 34.71-28.78 53.53s-17.38 38.07-34.14 49.66c-11.9 8.23-30.3 4.38-43.15 11-20.69 10.72-28.9 19.81-33.89 42.57-5.94 27.06 39.28 96.09 82.79 112.21 26.67 9.87 63.91 4.93 84.59-14.58s17.32-57.66 20.79-85.88c2.51-20.33 1.19-41.44 8.12-60.71 6.14-17.07 18.4-31.52 23.91-48.81 7.33-23 .84-53.63-18.14-69.68-18.64-15.73-47.05-5.56-62.1 10.69z"
				/>
				<path
					fill="#fff"
					stroke="#fff"
					strokeMiterlimit="10"
					d="M258.06 135.91c-13.85 14.94-21 34.71-28.78 53.53s-17.38 38.07-34.14 49.66c-11.9 8.23-30.3 4.38-43.15 11-20.69 10.72-28.9 19.81-33.89 42.57-5.94 27.06 39.28 96.09 82.79 112.21 26.67 9.87 63.91 4.93 84.59-14.58s17.32-57.66 20.79-85.88c2.51-20.33 1.19-41.44 8.12-60.71 6.14-17.07 18.4-31.52 23.91-48.81 7.33-23 .84-53.63-18.14-69.68-18.64-15.73-47.05-5.56-62.1 10.69z"
					opacity="0.9"
				/>
			</g>
			<g>
				<g>
					<ellipse cx="250" cy="431.27" fill="#f5f5f5" rx="193.89" ry="11.32" />
				</g>
			</g>
			<g>
				<ellipse
					cx="128.44"
					cy="225.06"
					fill={PRIMARY_MAIN}
					rx="24.14"
					ry="26.34"
					transform="rotate(-46.25 128.443 225.057)"
				/>
				<ellipse
					cx="128.44"
					cy="225.06"
					fill="#fff"
					opacity="0.8"
					rx="24.14"
					ry="26.34"
					transform="rotate(-46.25 128.443 225.057)"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M116.69 209.24a1 1 0 01-.6-1.8 19.21 19.21 0 0111.57-3.6 1 1 0 010 2 17.18 17.18 0 00-10.37 3.2 1 1 0 01-.6.2zM121.42 243.64a1 1 0 01-.47-.12 23.3 23.3 0 01-11.9-16.18 20.37 20.37 0 014-17 1 1 0 011.54 1.28A18.39 18.39 0 00111 227a21.37 21.37 0 0010.88 14.8 1 1 0 01-.47 1.88zM131.43 246.25a21.6 21.6 0 01-6.59-1.06 1 1 0 01.62-1.91c6.15 2 12.29.88 16.84-3a17.92 17.92 0 005.78-16.6 1 1 0 112-.32 19.83 19.83 0 01-6.45 18.43 18.44 18.44 0 01-12.2 4.46zM148.25 221.12a1 1 0 01-.95-.7 21.31 21.31 0 00-11.58-12.89 1 1 0 01.8-1.84 23.34 23.34 0 0112.68 14.12 1 1 0 01-.64 1.26 1.18 1.18 0 01-.31.05z"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M132.49 206.4a.85.85 0 01-.23 0 20.33 20.33 0 00-4.59-.53 1 1 0 01-1-1 1 1 0 011-1 22.05 22.05 0 015 .59 1 1 0 01-.23 2zM114.85 230.29a1 1 0 01-.94-.65 15.87 15.87 0 011.76-14.78 15 15 0 0112.52-6.46 1 1 0 010 2 13.09 13.09 0 00-10.89 5.6 13.93 13.93 0 00-1.51 13 1 1 0 01-.59 1.28 1.07 1.07 0 01-.35.01zM130.71 241.71a18.63 18.63 0 01-15-8.47 1 1 0 011.67-1.09 16.62 16.62 0 0013.33 7.56 1 1 0 010 2z"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M134.45 241.28a1 1 0 01-1-.76 1 1 0 01.73-1.21c8.11-2 10.21-9.11 9.54-14.57-.87-7.12-6.49-14.33-15.56-14.34a1 1 0 01-1-1 1 1 0 011-1c10.23 0 16.57 8.1 17.55 16.1 1 8.25-3.33 14.83-11.05 16.75a1 1 0 01-.21.03z"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M124.1 215.92a1 1 0 01-.9-.58 1 1 0 01.48-1.33 11.71 11.71 0 014.87-1 1 1 0 010 2 9.66 9.66 0 00-4 .87 1 1 0 01-.45.04zM130.53 237.05a13.25 13.25 0 01-11.91-7.9c-2.07-4.51-1.46-9.22 1.63-12.62a1 1 0 011.41-.06 1 1 0 01.06 1.41 9.28 9.28 0 00-1.28 10.43 11.25 11.25 0 0010.41 6.74 8.64 8.64 0 007.85-5.17 10.33 10.33 0 00-1.84-10.88 1 1 0 111.52-1.3 12.32 12.32 0 012.15 13 10.62 10.62 0 01-9.62 6.36z"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M134.74 216.8a.94.94 0 01-.53-.16 10.56 10.56 0 00-5.66-1.68 1 1 0 010-2 12.48 12.48 0 016.73 2 1 1 0 01-.54 1.84zM130 232.54a8 8 0 01-6.11-2.9 7.61 7.61 0 01-1.2-8.15 6.75 6.75 0 016.27-4 1 1 0 010 2 4.79 4.79 0 00-4.45 2.81 5.61 5.61 0 00.91 6 5.91 5.91 0 006 2 4.73 4.73 0 003.59-3.64 1 1 0 011.19-.76 1 1 0 01.77 1.19 6.71 6.71 0 01-5.06 5.15 8.12 8.12 0 01-1.91.3z"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M135.82 224.62a1 1 0 01-1-.7 6.32 6.32 0 00-5.87-4.4 1 1 0 110-2 8.37 8.37 0 017.78 5.8 1 1 0 01-.65 1.26 1 1 0 01-.26.04zM130.27 228.38a1 1 0 010-2 1 1 0 001-1.06 1.47 1.47 0 00-1.45-1.51 1.11 1.11 0 00-.78.35 1.2 1.2 0 00-.28.88 1 1 0 11-2 .12 3.09 3.09 0 013-3.35 3.46 3.46 0 013.45 3.51 3 3 0 01-3 3.06z"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M156 225.05c1.22 13.93-9.09 25.22-23 25.22h-1.08c13.26-.68 22.89-11.7 21.71-25.19a28.13 28.13 0 00-26.11-25.18h1.07c13.95-.06 26.23 11.23 27.41 25.15z"
				/>
				<path
					fill="#fff"
					d="M156 225.05c1.22 13.93-9.09 25.22-23 25.22h-1.08c13.26-.68 22.89-11.7 21.71-25.19a28.13 28.13 0 00-26.11-25.18h1.07c13.95-.06 26.23 11.23 27.41 25.15z"
					opacity="0.4"
				/>
			</g>
			<g>
				<path
					fill={PRIMARY_MAIN}
					d="M176.42 102.14a8.66 8.66 0 00.12.89 9.12 9.12 0 002.82 5 9 9 0 006.07 2.43h84.49a7.44 7.44 0 007.59-7.4 8.88 8.88 0 000-.89 9.25 9.25 0 00-9-8.28H184a7.47 7.47 0 00-7.58 8.25z"
				/>
				<path
					fill="#fff"
					d="M176.42 102.14a8.66 8.66 0 00.12.89 9.12 9.12 0 002.82 5 9 9 0 006.07 2.43h84.49a7.44 7.44 0 007.59-7.4 8.88 8.88 0 000-.89 9.25 9.25 0 00-9-8.28H184a7.47 7.47 0 00-7.58 8.25z"
					opacity="0.8"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M277.51 103a5.29 5.29 0 01-.06.8 9.25 9.25 0 00-9-8l-89.83.2a7.56 7.56 0 015.38-2.14h84.49a9.25 9.25 0 019 8.28 8.88 8.88 0 01.02.86z"
				/>
				<path
					d="M277.51 103a5.29 5.29 0 01-.06.8 9.25 9.25 0 00-9-8l-89.83.2a7.56 7.56 0 015.38-2.14h84.49a9.25 9.25 0 019 8.28 8.88 8.88 0 01.02.86z"
					style={{ isolation: "isolate" }}
					fill="#fff"
					opacity="0.5"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M263 97.86l.36 4 3.76-1.4.89 2-3.84 1.28 2.76 3.39-1.73 1.27-2.69-3.52-2 3.52-1.89-1.27 2.13-3.39-4-1.28.54-2 4.05 1.35-.36-4zM239.85 97.86l.36 4 3.76-1.4.9 2-3.87 1.31 2.76 3.39-1.72 1.27-2.69-3.52-2 3.52-1.9-1.27 2.14-3.39-4-1.28.54-2 4.05 1.35-.35-4zM216.75 97.86l.35 4 3.76-1.4.9 2-3.85 1.28 2.76 3.39-1.67 1.3-2.69-3.52-2 3.52-1.89-1.27 2.13-3.39-4-1.28.55-2 4 1.35-.35-4zM193.64 97.86l.35 4 3.77-1.4.89 2-3.85 1.28 2.77 3.39-1.73 1.27-2.69-3.52-2 3.52-1.89-1.27 2.13-3.39-4-1.28.54-2 4 1.35-.35-4z"
				/>
				<path fill={PRIMARY_MAIN} d="M245.71 89.94H249.65V90.94H245.71z" />
				<path fill={PRIMARY_MAIN} d="M253.73 89.94H266.58V90.94H253.73z" />
			</g>
			<g>
				<path
					fill={PRIMARY_MAIN}
					d="M397.79 127.24L393.7 174a6.17 6.17 0 01-6 5.52h-32.47a5 5 0 01-5-5.52l4.09-46.77a6.14 6.14 0 016-5.52h32.47a5 5 0 015 5.53z"
					opacity="0.8"
				/>
				<path
					fill="#fff"
					d="M397.79 127.24L393.7 174a6.17 6.17 0 01-6 5.52h-32.47a5 5 0 01-5-5.52l4.09-46.77a6.14 6.14 0 016-5.52h32.47a5 5 0 015 5.53z"
					opacity="0.8"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M357.28 134.78h-.09a1 1 0 01-.91-1.08l.38-4.28a1 1 0 011-.91h4.52a1 1 0 010 2h-3.6l-.3 3.36a1 1 0 01-1 .91zM393.64 134.78h-.09a1 1 0 01-.91-1.08l.28-3.19h-3.42a1 1 0 010-2h4.5a1 1 0 011 1.09l-.38 4.27a1 1 0 01-.98.91zM359 167.05h-4.52a1 1 0 01-.73-.32 1 1 0 01-.26-.76l.37-4.28a1 1 0 012 .17l-.28 3.19H359a1 1 0 010 2zM390.81 167.05h-4.51a1 1 0 010-2h3.6l.29-3.36a1 1 0 011.08-.91 1 1 0 01.91 1.08l-.37 4.28a1 1 0 01-1 .91z"
				/>
				<ellipse
					cx="374.61"
					cy="147.63"
					fill="#fff"
					rx="16.37"
					ry="13.16"
					transform="rotate(-75.54 374.624 147.626)"
				/>
				<path
					fill="#fff"
					d="M378.55 172.53L365.6 172.53 366.73 159.58 379.68 159.58 378.55 172.53z"
				/>
				<path
					fill="#fff"
					d="M393.7 174a6.17 6.17 0 01-6 5.52h-32.47a5 5 0 01-5-5.52s14.92-4.72 16.76-4.72h11.53z"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M390.17 149.62H408.6V150.62H390.17z"
					transform="rotate(-85.29 399.407 150.108)"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M399.24 133.84H402.86V134.84H399.24z"
					transform="rotate(-85.29 401.067 134.328)"
				/>
			</g>
			<g>
				<path fill={PRIMARY_MAIN} d="M361.94 244.07H365.88V245.07H361.94z" />
				<path fill={PRIMARY_MAIN} d="M369.96 244.07H382.81V245.07H369.96z" />
				<path
					fill={PRIMARY_MAIN}
					d="M406.33 227l-2.49 28.5a5.18 5.18 0 01-5.06 4.65 4.2 4.2 0 01-4.25-4.65L397 227a15.08 15.08 0 00-15.24-16.7H371.6a18.61 18.61 0 00-18.16 16.7l-1 11.64h-9.3l1-11.64c1.25-14.34 13.94-26 28.27-26h10.2a23.47 23.47 0 0123.72 26z"
				/>
				<path
					fill="#fff"
					d="M406.33 227l-2.49 28.5a5.18 5.18 0 01-5.06 4.65 4.2 4.2 0 01-4.25-4.65L397 227a15.08 15.08 0 00-15.24-16.7H371.6a18.61 18.61 0 00-18.16 16.7l-1 11.64h-9.3l1-11.64c1.25-14.34 13.94-26 28.27-26h10.2a23.47 23.47 0 0123.72 26z"
					opacity="0.5"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M407 254.46L402.15 310a7.07 7.07 0 01-6.92 6.35h-55.54a5.73 5.73 0 01-5.8-6.35l4.86-55.54a7.07 7.07 0 013.46-5.39 6.8 6.8 0 013.46-1h55.53a6 6 0 013.21.91 5.76 5.76 0 012.59 5.48z"
				/>
				<path
					fill="#fff"
					d="M349.25 278.72l14.43 22.21 47.59-47.59a.48.48 0 00-.63-.71l-45.35 35.55-8.57-13.81z"
				/>
			</g>
			<g>
				<path
					fill={PRIMARY_MAIN}
					d="M122 171.31H85.82a6.85 6.85 0 01-6.69-6.15L76 129a5.54 5.54 0 015.61-6.15h36.14a6.84 6.84 0 016.65 6.15l3.16 36.14a5.54 5.54 0 01-5.56 6.17z"
				/>
				<path
					fill="#fff"
					d="M122 171.31H85.82a6.85 6.85 0 01-6.69-6.15L76 129a5.54 5.54 0 015.61-6.15h36.14a6.84 6.84 0 016.65 6.15l3.16 36.14a5.54 5.54 0 01-5.56 6.17z"
					opacity="0.8"
				/>
				<path
					fill="#fff"
					d="M79.15 150.73S92 132.84 101.46 133.3s25.18 17 25.18 17-23.17 12.17-47.49.43z"
				/>
				<path
					fill="#263238"
					d="M79.56 151l-.81-.58c.52-.73 12.77-17.65 22.35-17.65h.39c9.56.46 24.86 16.48 25.51 17.16l-.72.69c-.16-.16-15.68-16.41-24.84-16.85h-.34c-9.1.02-21.42 17.08-21.54 17.23z"
				/>
				<path
					fill="#263238"
					d="M77.82 151.36c-1.94 0-4.46-.72-5.35-3.46a.5.5 0 01.32-.63.49.49 0 01.63.32c1.15 3.54 5.45 2.69 5.63 2.65a.51.51 0 01.59.39.5.5 0 01-.39.59 7.36 7.36 0 01-1.43.14zM85.45 143.68a.5.5 0 01-.31-.1c-.15-.12-3.7-2.86-4.6-5.65a.5.5 0 01.32-.63.51.51 0 01.63.32c.81 2.49 4.22 5.14 4.26 5.16a.5.5 0 01.09.7.5.5 0 01-.39.2zM92.79 136.87a.52.52 0 01-.49-.51 52.06 52.06 0 00-2.17-6.4c-.25-.66-.43-1.14-.47-1.26a.5.5 0 01.34-.63.5.5 0 01.62.34c0 .12.21.56.44 1.19 1.4 3.67 2.26 6.08 2.24 6.78a.49.49 0 01-.51.49zM101.46 133.8a.52.52 0 01-.5-.46l-.7-8a.5.5 0 011-.09l.7 8.06a.51.51 0 01-.45.54zM128.14 150.81a8.44 8.44 0 01-1.46-.12.5.5 0 01-.39-.59.48.48 0 01.58-.39s2.45.47 3.95-.59a2.8 2.8 0 001.14-2 .5.5 0 111 .15 3.79 3.79 0 01-1.56 2.65 5.7 5.7 0 01-3.26.89zM119.16 143.15a.48.48 0 01-.37-.17.5.5 0 010-.71s3-2.64 3.32-5.1a.51.51 0 01.57-.42.5.5 0 01.42.57c-.42 2.82-3.52 5.58-3.65 5.7a.46.46 0 01-.29.13zM110.71 137.34h-.09a.51.51 0 01-.41-.58c0-.06 1.1-6.49 1.3-7.81a.5.5 0 111 .15c-.2 1.33-1.29 7.77-1.3 7.83a.5.5 0 01-.5.41z"
				/>
				<ellipse
					cx="102.45"
					cy="144.63"
					fill={PRIMARY_MAIN}
					rx="10.84"
					ry="11.84"
					transform="rotate(-46.25 102.46 144.632)"
				/>
				<path
					d="M107.14 144.63a6.45 6.45 0 000-.76 5.36 5.36 0 00-5.24-4.81 4.34 4.34 0 00-4.39 4.81 3.28 3.28 0 00.14.76h-6.52a10.21 10.21 0 0110.33-11.33 12.63 12.63 0 0112.33 11.33z"
					opacity="0.3"
				/>
				<path
					d="M107.13 143.87a6.45 6.45 0 010 .76 4.33 4.33 0 01-4.41 4.05 5.35 5.35 0 01-5.09-4.05 3.28 3.28 0 01-.14-.76 4.34 4.34 0 014.39-4.81 5.36 5.36 0 015.25 4.81z"
					opacity="0.7"
				/>
				<path
					fill="#fff"
					d="M109.74 139.15a3.54 3.54 0 01-3.58 3.92 4.37 4.37 0 01-4.26-3.92 3.53 3.53 0 013.58-3.92 4.36 4.36 0 014.26 3.92z"
					opacity="0.7"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M90.32 118.38H94.25999999999999V119.38H90.32z"
				/>
				<path fill={PRIMARY_MAIN} d="M98.34 118.38H111.19V119.38H98.34z" />
			</g>
			<g>
				<path
					fill="#ffb573"
					d="M276 191.65l.34.41.46.53c.33.35.65.72 1 1.06.69.72 1.44 1.42 2.2 2.13a58.1 58.1 0 004.77 3.95c1.69 1.26 3.47 2.41 5.25 3.55 1 .54 1.82 1.11 2.76 1.63l2.17 1.2a1.44 1.44 0 001 .06c.7-.21 4.72-4.19 6-5.67 2.84-3.25 7.14-7.53 9.46-11.17l2.59 3.57a58.69 58.69 0 01-6.47 12.64 28 28 0 01-5.1 5.71 14.34 14.34 0 01-3.92 2.35 9.51 9.51 0 01-5.68.43 8.9 8.9 0 01-1.43-.48l-.9-.39-1.62-.74c-1.09-.48-2.14-1.06-3.2-1.59a66.35 66.35 0 01-6.16-3.73 63.54 63.54 0 01-5.75-4.46c-.95-.83-1.8-1.67-2.67-2.58-.43-.45-.87-.92-1.29-1.41l-.62-.76c-.23-.28-.4-.49-.71-.92z"
				/>
				<path
					fill="#ffb573"
					d="M311 189.88l4.64-9.92 4.43 7.94s-2.91 5-6.07 5z"
				/>
				<path
					fill="#ffb573"
					d="M320.9 175.84L325.58 181.86 320.11 187.89 315.68 179.96 320.9 175.84z"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M319.27 190.63h7.37a.84.84 0 00.83-.76L329 172.2a.83.83 0 00-.83-.91h-7.36a.84.84 0 00-.83.77l-1.55 17.66a.84.84 0 00.84.91z"
				/>
				<path
					fill="#fff"
					d="M319.35 188.86L326.73 188.86 328.11 173.07 320.73 173.07 319.35 188.86z"
					opacity="0.3"
				/>
				<path
					fill="#ffb573"
					d="M318.87 186.62l1.31-.67a8 8 0 001.83-1.28l.24-.87a1.26 1.26 0 00-1.39-.57l-1.09.3-5.41.41-.13 2.28 1.58 2.44h.54a2.47 2.47 0 002.52-2.04z"
				/>
				<path
					fill="#263238"
					d="M266.56 182c3.75-.68 30.2 22.25 30.2 22.25l-7.84 11.5s-28.92-20.14-28-25c.89-5.11.18-7.75 5.64-8.75z"
				/>
				<path
					d="M269.9 189.33l-.29 12c3 2.33 13.84 10.64 13.84 10.64z"
					style={{ isolation: "isolate" }}
					opacity="0.2"
				/>
				<path
					fill="#ffb573"
					d="M248.93 420.31L257.8 421.18 260.62 400.61 251.74 399.74 248.93 420.31z"
				/>
				<path
					fill="#ffb573"
					d="M161.6 299.61L159.33 308.25 178.52 316.52 182.5 307.75 161.6 299.61z"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M264.15 423a3.38 3.38 0 002.19-.48 1.18 1.18 0 00.4-1 .65.65 0 00-.34-.57c-1.07-.64-4.69 1.08-5.1 1.27a.21.21 0 00-.12.21.21.21 0 00.17.18 15.5 15.5 0 002.8.39zm1.61-1.79a.7.7 0 01.4.09.26.26 0 01.14.23.8.8 0 01-.27.67c-.55.47-2 .51-4 .13a11.52 11.52 0 013.76-1.13z"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M261.39 422.57h.08c1.08-.45 3.25-2.31 3.11-3.33a.79.79 0 00-.78-.62 1.47 1.47 0 00-1.19.3c-1.15.89-1.42 3.3-1.44 3.39a.23.23 0 00.05.21.22.22 0 00.17.05zm2.21-3.57h.12c.39.06.41.21.41.26.09.61-1.39 2.09-2.5 2.68a4.78 4.78 0 011.22-2.72 1.06 1.06 0 01.77-.19z"
				/>
				<path
					fill="#263238"
					d="M257.36 417.6l-8 .34a.7.7 0 00-.69.63l-1.92 11.17a1.44 1.44 0 001.37 1.52h.16c3.49-.18 5.17-.44 9.59-.59 2.71-.09 11 .89 14.7.05s3-4.51 1.41-4.48c-5.14.1-11.07-3.25-14-7.17-.51-.33-2.06-1.5-2.62-1.47zM163.41 309.38l4.08-6.93a.69.69 0 00-.17-.92l-8.32-7.67a1.45 1.45 0 00-2 .32l-.09.14c-1.74 3-2.43 4.58-4.7 8.37-1.4 2.33-6.74 8.8-8 12.33s2.16 5 3 3.62c2.71-4.38 8.74-7.54 13.64-7.92.47-.18 2.28-.85 2.56-1.34z"
				/>
				<path
					d="M260.62 400.61L259.17 411.22 250.29 410.35 251.73 399.75 260.62 400.61z"
					opacity="0.2"
				/>
				<path
					d="M188.4 309.89L184.3 319.14 171.94 313.68 175.09 304.87 188.4 309.89z"
					opacity="0.2"
				/>
				<path
					fill="#263238"
					d="M272.23 192.55c5.07 8.62-1.13 15.89-6.31 41.65l-33.42.09c.54-13.88.48-25.1-5.44-52.41a4.58 4.58 0 014.49-5.6c2.76 0 6.23.09 9.76.45a121.54 121.54 0 0116.55 2.51 68.75 68.75 0 018.7 2.72c1.72.58 3.79 5.26 5.67 10.59z"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M267.16 235.94s18.77 54.27 18.32 80.37c-.26 14.95-1.39 42.81-5.9 62.4-3.68 16-18.68 26.2-18.68 26.2l-12-.71s-6.5-24.4-5.37-34.11c2.25-19.33 8.92-33.85 8.11-51.6-1.28-29.06-9.88-83-9.88-83z"
				/>
				<path
					fill="#263238"
					d="M246.49 407.33L263.44 408.33 264.98 403.24 246.04 401.49 246.49 407.33z"
				/>
				<path
					d="M248.63 345.58s0-.1.1-.09c10.65 1 18-14.33 16.25-35.32l-5.65-53.5z"
					style={{ isolation: "isolate" }}
					opacity="0.2"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M258.74 235.49s14.92 95.43-16.1 106.17c-16.37 6.2-30.14-.52-46.45-7.74-9.43-4.17-21.21-15.46-21.21-15.46l4.53-12.33a59 59 0 0125-1.26c15.9 2.46 16.57 7 22.22 3.57 11.88-29.36-8.56-52.57 5.33-73z"
				/>
				<path
					fill="#263238"
					d="M180.4 303.79L174.34 319.78 178.8 323.19 186 304.16 180.4 303.79z"
				/>
				<path
					fill="#ffb573"
					d="M177.9 217.56c-1.51 2.23-9.17.72-9.17.72l-1.63-9.49 9.41 3.77z"
				/>
				<path
					fill="#ffb573"
					d="M169 208.3l1.46 9.86-5.65.84a1.24 1.24 0 01-1.33-1l-2.17-7a.65.65 0 01.46-.77z"
				/>
				<path
					fill="#ffb573"
					d="M166.47 212.77l3.22.57 3.05-2.29-1.18-.84a4 4 0 01-1.58-2.37 17.34 17.34 0 01-.41-3.05v-.34a1.39 1.39 0 00-1.18-1.2l-.8.59c-.5 1.09-.14 2.36-.2 3.21l-.13 1.65-1.29.56zM228.67 180l-18.92 26-34.75 6.86v5.7s32.66 2.42 40-1.85 19.65-30.17 19.65-30.17z"
				/>
				<path
					fill="#263238"
					d="M228.45 130c-7.94-2.66-17.15 2.27-20.78 9.81s-2.36 16.79 1.73 24.09c1.23 2.21 2.7 4.29 3.71 6.6a10.44 10.44 0 01.69 7.37c-.82 2.39-1.66 3.92-4.62 3.54 0 0 0-1.64 1.13-1.44s-2.4 1.33-3.34.46c1.09 1.68 4 1.07 4.93-.71 1.39-2.65-1.17-6.24-4.17-6.21s-5.44 3.07-5.34 6.06a8.22 8.22 0 005 6.89 3.72 3.72 0 01-4-2.15c1.36 3.56 5.8 5.13 9.55 4.43s6.92-3.1 9.86-5.53c4-3.28 8.09-7.43 8-12.56-.09-3.83-2.55-7.12-4.62-10.35s-3.88-7.17-2.56-10.76c1.11-3.05 4.13-4.89 6.79-6.75 1.92-1.33 5.82-4.3 5.36-7.11-.47-2.68-5.07-4.93-7.32-5.68z"
				/>
				<path
					fill="#263238"
					d="M210.56 219l-5.24-13.87 1.2.1a5.07 5.07 0 004.85-2.58c4-7.12 14.33-25.19 17.24-26.09 4.15-1.23 8.42 2.09 10 7 1.19 3.61-11 19.09-16.62 27.89-4.99 7.79-11.43 7.55-11.43 7.55z"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M159.45 313.94v.11a1.22 1.22 0 00.9.76.74.74 0 00.71-.15c.84-.77.3-4 .25-4.32a.2.2 0 00-.16-.17.24.24 0 00-.21.08c-.68.96-1.76 2.67-1.49 3.69zm1.54-3c.18 1.28.26 3-.21 3.42a.33.33 0 01-.32 0 .8.8 0 01-.6-.5c-.18-.54.2-1.64 1.13-2.94z"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M161 310.76c.26 1.08 1.28 2.92 2.18 3.14a.74.74 0 00.82-.42 1.11 1.11 0 00.06-.94c-.41-1.24-2.77-2.26-2.87-2.31a.22.22 0 00-.21 0 .22.22 0 00-.08.2 2.19 2.19 0 00.1.33zm2.72 2a.68.68 0 01-.09.52c-.13.24-.26.24-.36.22-.61-.12-1.54-1.63-1.84-2.68.77.39 2 1.12 2.26 1.88a.09.09 0 000 .06zM232.38 232.6l-.93 3.25c-.12.25.17.51.59.5h34.5c.33 0 .6-.17.62-.37l.32-3.25c0-.22-.26-.42-.62-.41h-33.9a.64.64 0 00-.58.28z"
				/>
				<path
					d="M232.38 232.6l-.93 3.25c-.12.25.17.51.59.5h34.5c.33 0 .6-.17.62-.37l.32-3.25c0-.22-.26-.42-.62-.41h-33.9a.64.64 0 00-.58.28z"
					style={{ isolation: "isolate" }}
					fill="#fff"
					opacity="0.5"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M239 236.65h-1c-.19 0-.35-.1-.33-.22l.45-4.23c0-.12.19-.22.39-.22h1c.19 0 .34.1.33.23l-.45 4.22c-.06.12-.24.23-.39.22zM263.87 236.65h-1c-.18 0-.34-.1-.32-.22l.45-4.22c0-.13.18-.23.37-.23h1c.19 0 .35.1.33.22l-.45 4.22c-.02.13-.18.23-.38.23zM252.46 236.63h-1c-.19 0-.35-.1-.33-.22l.45-4.22c0-.13.19-.23.38-.23h1c.19 0 .34.1.33.22l-.46 4.22c0 .13-.17.23-.37.23z"
				/>
				<path
					fill="#ffb573"
					d="M242.87 158.53c1.06 5.45 2.1 15.29-1.6 18.25 0 0 1.48 5.59 11.37 7.08 10.92 1.64 5.19-4.58 5.19-4.58-5.95-2.35-5.8-6.65-4.78-10.61z"
				/>
				<path
					fill="#ffb573"
					d="M241.31 176.73a106.07 106.07 0 0115.69 2.18l7.48 2.23c1.33 2.68-.85 8.19-7.11 9.24 0 0-15.77 2.17-20.42-14.07z"
				/>
				<path
					d="M247 162.68l6 6a15.21 15.21 0 00-.49 2.84c-2.28-.67-5.94-3.18-6.19-5.58a8.25 8.25 0 01.68-3.26z"
					style={{ isolation: "isolate" }}
					opacity="0.2"
				/>
				<path
					fill="#ffb573"
					d="M236.19 151.65c2.68 8.46 3.62 12.1 9.11 15.43 8.18 5 18-.12 17.9-9.2-.08-8.18-4.43-20.64-13.72-22a12.1 12.1 0 00-13.29 15.74z"
				/>
				<path
					fill="#ed893e"
					d="M256.38 150.33a19 19 0 003.41 4 3.12 3.12 0 01-2.43 1z"
				/>
				<path
					fill="#263238"
					d="M255.43 159.85a5.82 5.82 0 001-.2.2.2 0 00.15-.24.21.21 0 00-.25-.15 5.67 5.67 0 01-5-1 .22.22 0 00-.31 0 .21.21 0 000 .3 6 6 0 004.41 1.29z"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M233.28 134.76a8.2 8.2 0 00-3.74 6.55 3.06 3.06 0 00.23 1.47 1.42 1.42 0 001.14.85c.76 0 1.32-.68 1.74-1.32l1.94-3a14.91 14.91 0 002-3.19c.65-1.97-2.12-2.12-3.31-1.36z"
				/>
				<path
					fill="#263238"
					d="M234.23 136.75c-5.31 6.14-7.69 23.13 7.87 27.87 2.6-2.06-1.6-5.81-3.38-10.55-1.17-3.1 4.76-8.89 7.07-11.27s4.44-5.81 7.7-5.26a4.4 4.4 0 002.56.07c2.59-1.17 1.33-2.72 0-3.8a13.31 13.31 0 00-7-2.58 17.33 17.33 0 00-14.82 5.52z"
				/>
				<path
					fill="#263238"
					d="M251.5 134.69c6.25 5.21-2 5.34-6.37 8.64-4.19 3.17-1.25 8.36-8.34 10.77-5.79 1.69-7.13.67-8.73-.45a9.12 9.12 0 007.53-8.19c.49-3.8 8.77-16.81 15.91-10.77z"
				/>
				<path
					fill="#ffb573"
					d="M235.25 158.76a6.55 6.55 0 004.16 2.7c2.24.39 3.1-1.66 2.33-3.66-.73-1.8-2.76-4.17-4.93-3.66a2.91 2.91 0 00-2.09 3.54 3 3 0 00.53 1.08z"
				/>
				<path
					fill="#263238"
					d="M250.43 135.22c1.21 3.08 4.7 2.67 7.28 5.89 1.59 2 4.71-4.17.46-6.6s-9.64-4.11-7.74.71zM246.75 148.22a.42.42 0 00.43-.25c.82-1.82 3.28-2.28 3.3-2.28a.44.44 0 00.34-.5.42.42 0 00-.49-.33c-.13 0-2.93.59-3.93 2.79a.43.43 0 00.21.56h.14zM259.75 144.54a.4.4 0 00.37-.19.41.41 0 00-.11-.58 4.2 4.2 0 00-3.84-.49.41.41 0 00-.22.56.42.42 0 00.55.22 3.31 3.31 0 013 .42.6.6 0 00.25.06z"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M300.86 199.28l-5.86 3.33-6.09 13.1s5.88 3.74 10.52.71 1.43-17.14 1.43-17.14z"
				/>
				<path
					d="M300.86 199.28l-5.86 3.33-6.09 13.1s5.88 3.74 10.52.71 1.43-17.14 1.43-17.14z"
					style={{ isolation: "isolate" }}
					fill="#fff"
					opacity="0.5"
				/>
				<path
					fill={PRIMARY_MAIN}
					d="M198.39 203.33l6.93 2 6.57 13.59s-5.28 4.89-11 .22c-4.28-3.54-2.5-15.81-2.5-15.81z"
				/>
				<path
					fill="#fff"
					d="M198.39 203.33l6.93 2 6.57 13.59s-5.28 4.89-11 .22c-4.28-3.54-2.5-15.81-2.5-15.81z"
					opacity="0.5"
				/>
				<path
					fill="#263238"
					d="M259.35 147.93c.48-.09 1 .45 1.11 1.19s-.16 1.42-.64 1.5-1-.45-1.11-1.19.16-1.43.64-1.5z"
				/>
				<path
					fill="#263238"
					d="M260.83 147.2l-1.69.83c1.02.72 1.69-.83 1.69-.83zM251 149.65c.48-.09 1 .45 1.11 1.19s-.16 1.42-.64 1.5-1-.45-1.11-1.19.14-1.41.64-1.5z"
				/>
				<path
					fill="#263238"
					d="M252.46 148.92l-1.69.83c1.02.72 1.69-.83 1.69-.83zM208.66 187.91a7.31 7.31 0 01-2.75-.52c-3.1-1.27-4.54-4.49-4-8.85a.5.5 0 01.56-.43.49.49 0 01.43.56c-.52 3.87.7 6.71 3.35 7.79a7.7 7.7 0 008.65-2.66c2.51-3.34 1.69-8.08-2.22-13-10.24-12.85-9.56-19.68-8.11-25.08 2.1-7.84 8.25-16.82 25.65-15.09a.5.5 0 11-.1 1c-16.69-1.66-22.59 6.9-24.59 14.36-1.44 5.4-2 11.71 7.93 24.2 6 7.53 3.57 12.46 2.24 14.22a9 9 0 01-7.04 3.5z"
				/>
			</g>
		</svg>
	);
}

export default memo(AuthIllustration);
