import { alpha, styled } from "@mui/material/styles";
import { CardProps } from "@mui/material";
import { fShortenNumber } from "src/utils/formatNumber";
import { IconifyIcon } from "@iconify/react";
import { useState } from "react";
import axiosInstance from "src/utils/axios";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Iconify from "src/components/iconify";

const IconWrapperStyle = styled("div")(({ theme }) => ({
	height: 40,
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	color: theme.palette.primary.main,
	backgroundColor: alpha(theme.palette.primary.main, 0.08),
}));

type ItemProps = {
	id: string;
	name: string;
	count: number;
	sub?: boolean;
	trophyAction?: () => void;
};

interface Props extends CardProps {
	formatAsMoney?: boolean;
	icon: IconifyIcon | string;
	list: ItemProps[];
	subheader?: string;
	title?: string;
	getUserEmailById?: boolean;
	size?: number;
}

export default function TopRanking({ title, subheader, list, icon, formatAsMoney = false, getUserEmailById = false, size = 5, ...other }: Props) {
	return (
		<Card {...other}>
			<CardHeader title={title} subheader={subheader} />

			<Stack spacing={3} sx={{ p: 3 }}>
				{list
					.sort((a, b) => b.count - a.count)
					.slice(0, size)
					.map((placed, index) => (
						<PlacedItem key={placed.id} placed={placed} index={index} icon={icon} formatAsMoney={formatAsMoney} getUserEmailById={getUserEmailById} />
					))}
			</Stack>
		</Card>
	);
}

type PlacedItemProps = {
	formatAsMoney: boolean;
	icon: IconifyIcon | string;
	index: number;
	placed: ItemProps;
	getUserEmailById: boolean;
};

function PlacedItem({ placed, index, icon, formatAsMoney, getUserEmailById }: PlacedItemProps) {
	const [userEmail, setUserEmail] = useState(null);

	if (!userEmail) {
		axiosInstance
			.get(`/api/v1/users/email/${placed.id}`)
			.then((data) => {
				setUserEmail(data.data.email);
			})
			.catch(console.error);
	}

	return (
		<Stack direction="row" alignItems="center" spacing={2}>
			<Box sx={{ flexGrow: 1 }}>
				<Typography variant="subtitle2">{`${placed.sub ? "(Sub-Afiliado) " : ""}${getUserEmailById ? userEmail : placed.name}`}</Typography>

				<Typography
					variant="caption"
					sx={{
						mt: 0.5,
						display: "flex",
						alignItems: "center",
						color: "text.secondary",
					}}>
					<Iconify icon={icon} sx={{ width: 16, height: 16, mr: 0.5 }} />
					{formatAsMoney
						? placed.count.toLocaleString("pt-BR", {
								currency: "BRL",
								style: "currency",
						  })
						: fShortenNumber(placed.count, 2)}
				</Typography>
			</Box>

			<IconWrapperStyle
				sx={{
					...(index !== 0 && {
						color: "text.secondary",
						bgcolor: (theme) => alpha(theme.palette.background.neutral, 0.08),
					}),
					...(index === 1 && {
						color: "info.main",
						bgcolor: (theme) => alpha(theme.palette.info.main, 0.08),
					}),
					...(index === 2 && {
						color: "error.main",
						bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
					}),
					width: placed.trophyAction ? 80 : 40,
					borderRadius: placed.trophyAction ? 12 : "50%",
					cursor: placed.trophyAction ? "pointer" : "default",
				}}
				role={placed.trophyAction ? "button" : "none"}
				onClick={() => {
					if (typeof placed.trophyAction === "function") placed.trophyAction();
				}}>
				<Iconify icon={"ant-design:trophy-filled"} width={20} height={20} /> {placed.trophyAction && "Abrir"}
			</IconWrapperStyle>
		</Stack>
	);
}
