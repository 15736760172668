import { affiliatedPaymentsHeadCells } from "../../_constants";
import { Card, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import { DefaultTableProps, TableOrder } from "../../_types";
import { stableSort, getComparator } from "../../../../utils/tableUtils";
import { TableHeadCustom, TableNoData, TableSelectedActions } from "src/components/table";
import { useState, MouseEvent, ChangeEvent, useEffect } from "react";
import AffiliatedPaymentsTableRow from "./AffiliatedPaymentsTableRow";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";

export interface AffiliatedPaymentsTableRowType {
	id: string;
	date: number;
	amount: number;
	thatDayValue: number;
	type: string;
	email: string;
}

export default function AffiliatedPaymentsTable({ rows, tableConfig, itemCount, onChangeTableConfig, ...other }: DefaultTableProps<AffiliatedPaymentsTableRowType>) {
	const [dense, setDense] = useState(false);
	const [order, setOrder] = useState<TableOrder>(tableConfig.order);
	const [orderBy, setOrderBy] = useState<keyof AffiliatedPaymentsTableRowType>(tableConfig.orderBy);
	const [page, setPage] = useState(tableConfig.page);
	const [rowCount, setRowCount] = useState(itemCount);
	const [rowsPerPage, setRowsPerPage] = useState(tableConfig.limit);
	const [selected, setSelected] = useState<readonly string[]>([]);
	const [tableData, setTableData] = useState<AffiliatedPaymentsTableRowType[]>(rows);

	useEffect(() => {
		setOrder(tableConfig.order);
		setOrderBy(tableConfig.orderBy);
		setPage(tableConfig.page);
		setRowCount(itemCount);
		setRowsPerPage(tableConfig.limit);
		setTableData(rows);
	}, [rows, tableConfig, itemCount, tableConfig.limit, tableConfig.order, tableConfig.orderBy, tableConfig.page]);

	const handleRequestSort = (property: keyof AffiliatedPaymentsTableRowType) => {
		const order_ = orderBy === property && order === "asc";
		onChangeTableConfig([
			{ type: "order", data: order_ ? "desc" : "asc" },
			{ type: "orderBy", data: property },
		]);
	};

	const handleSelectAllClick = (checked: boolean) => {
		if (checked) {
			const newSelected = tableData.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleSelectRow = (event: MouseEvent<unknown>, id: string) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: readonly string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		onChangeTableConfig([{ type: "page", data: newPage }]);
	};

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		onChangeTableConfig([
			{ type: "limit", data: Number(event.target.value) },
			{ type: "page", data: 0 },
		]);
	};

	const handleChangeDense = (event: ChangeEvent<HTMLInputElement>) => {
		setDense(event.target.checked);
	};

	const isSelected = (id: string) => selected.indexOf(id) !== -1;

	return (
		<Card {...other} sx={{ p: 0 }}>
			<Scrollbar>
				<TableContainer sx={{ minWidth: 800, position: "relative" }}>
					{selected.length > 0 && (
						<TableSelectedActions
							dense={dense}
							numSelected={selected.length}
							rowCount={tableData.length}
							onSelectAllRows={(checked) => handleSelectAllClick(checked)}
							actions={
								<Stack spacing={1} direction="row">
									<Tooltip title="Delete">
										<IconButton color="primary" onClick={() => {}}>
											<Iconify icon={"eva:trash-2-outline"} />
										</IconButton>
									</Tooltip>
								</Stack>
							}
						/>
					)}
					<Table size={dense ? "small" : "medium"}>
						<TableHeadCustom<AffiliatedPaymentsTableRowType>
							headLabel={affiliatedPaymentsHeadCells}
							order={order}
							orderBy={orderBy}
							onSort={handleRequestSort}
							rowCount={tableData.length}
							extra={false}
						/>
						<TableBody>
							{stableSort(tableData, getComparator(order, orderBy))
								// .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => (
									<AffiliatedPaymentsTableRow
										isItemSelected={isSelected(row.id)}
										row={row}
										key={row.id}
										labelId={`accounts-table-checkbox-${index}`}
										handleSelect={handleSelectRow}
									/>
								))}
							<TableRow hover role="checkbox" tabIndex={-1}>
								<TableCell colSpan={8} />
							</TableRow>
							<TableNoData isNotFound={!tableData.length} />
						</TableBody>
					</Table>
				</TableContainer>
			</Scrollbar>
			<Box sx={{ position: "relative" }}>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
					component="div"
					count={rowCount}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage={"Pagamentos por página"}
					getItemAriaLabel={function defaultGetAriaLabel(type) {
						return `Ir para a ${type === "next" ? "próxima página" : type === "first" ? "primeira página" : type === "last" ? "última página" : "pagina anterior"} `;
					}}
					showFirstButton={page !== 0 && rowCount > rowsPerPage * page}
					showLastButton={page !== Math.ceil(rowCount / rowsPerPage) - 1 && rowCount > rowsPerPage * page}
				/>
				<FormControlLabel
					sx={{ px: 3, py: 1.5, top: 0, position: { md: "absolute" } }}
					control={<Switch checked={dense} onChange={handleChangeDense} />}
					label="Modo minimalista"
				/>
			</Box>
		</Card>
	);
}
