import { ApexOptions } from "apexcharts";
import Box from "@mui/material/Box";
import Card, { CardProps } from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Chart, { useChart } from "src/components/chart";

interface Props extends CardProps {
	title?: string;
	subheader?: string;
	chart: {
		labels: string[];
		colors?: string[];
		series: {
			name: string;
			type: string;
			fill?: string;
			data: number[];
		}[];
		options?: ApexOptions;
	};
}

export default function FirstInteractionsGraph({ title, subheader, chart, ...other }: Props) {
	const { labels, colors, series, options } = chart;

	console.log(labels);
	const chartOptions = useChart({
		colors,
		plotOptions: { bar: { columnWidth: "20%" } },
		fill: {
			type: series.map((i) => i.fill) as string[],
		},
		labels,
		xaxis: {
			type: "datetime",
			labels: {
				formatter: (value) => {
					return new Date(value).toLocaleDateString("pt-BR");
				},
			},
		},
		tooltip: {
			shared: true,
			y: {
				formatter: (y: number) => {
					if (typeof y !== "undefined") {
						return `${y.toLocaleString("pt-BR")}`;
					}
					return y;
				},
			},
			x: {
				formatter: (x: number) => {
					return new Date(x).toLocaleDateString("pt-BR");
				},
			},
		},
	});

	return (
		<Card {...other}>
			<CardHeader title={title} subheader={subheader} />

			<Box sx={{ p: 3, pb: 1 }} dir="ltr">
				<Chart type="line" series={series} options={chartOptions} height={364} />
			</Box>
		</Card>
	);
}
