import { AccountsTable } from "src/sections/app/accounts";
import { AccountsTableRowType } from "src/sections/app/accounts/list/AccountsTable";
import { DefaultTableConfig } from "src/sections/app/_types";
import { useEffect, useState, useCallback } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs";
import Page from "src/components/Page";
import useWebSocket from "src/hooks/useWebSocket";
import Loading from "src/components/Loading";

export default function AccountListPage() {
	const { websocket } = useWebSocket();
	const [userAccounts, setUserAccounts] = useState<{
		accounts: {
			id: string;
			username: string;
			email: string;
			firstName: string;
			lastName: string;
			balance: number;
			bonusBalance: number;
			createdAt: number;
			birthDate: number;
		}[];
		count: number;
	} | null>(null);

	const [tableConfig, setTableConfig] = useState<DefaultTableConfig<AccountsTableRowType>>({
		limit: 25,
		order: "desc",
		orderBy: "createdAt",
		page: 0,
		search: "",
	});
	const [requestingAccounts, setRequestingAccounts] = useState(false);

	const getAccounts = useCallback(
		(config: DefaultTableConfig<AccountsTableRowType>) => {
			setTableConfig(config);

			setUserAccounts({ accounts: [], count: 0 });
			websocket.emit("Accounts.Get", {
				...config,
				offset: config.page * config.limit,
			});
		},
		[websocket]
	);

	useEffect(() => {
		if (!requestingAccounts && !userAccounts) {
			setRequestingAccounts(true);
			getAccounts(tableConfig);
		}
	}, [getAccounts, requestingAccounts, tableConfig, userAccounts]);

	useEffect(() => {
		websocket.on("Accounts.Get", (data) => {
			setUserAccounts({ accounts: data.data, count: data.count });
			setRequestingAccounts(false);
		});
	}, [websocket]);

	const onChangeTableConfig = (data: { type: keyof DefaultTableConfig<AccountsTableRowType>; data: any }[]) => {
		const newData = data.reduce((prev, curr) => ({ ...prev, [curr.type]: curr.data }), {});

		setRequestingAccounts(true);
		getAccounts({
			...tableConfig,
			...newData,
		});
	};

	if (!userAccounts || (!userAccounts.accounts.length && requestingAccounts)) return <Loading loadingText="Carregando contas" />;

	return (
		<Page title="Gerenciamento: Lista de usuários">
			<Container maxWidth="xl">
				<CustomBreadcrumbs heading="Lista de usuários" links={[{ name: `Atualizado em ${new Date().toLocaleString("pt-BR")}` }]} />
				<Grid container spacing={2} sx={{ alignItems: "center", alignContent: "center" }}>
					<AccountsTable rows={userAccounts.accounts} tableConfig={tableConfig} itemCount={userAccounts.count} onChangeTableConfig={onChangeTableConfig} />
				</Grid>
			</Container>
		</Page>
	);
}
