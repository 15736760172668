import { SettingsValueProps } from "./types";

export const defaultSettings: SettingsValueProps = {
	themeMode: "dark",
	themeDirection: "ltr",
	themeContrast: "default",
	themeLayout: "vertical",
	themeColorPresets: "default",
	themeStretch: false,
};
