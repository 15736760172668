import "simplebar/src/simplebar.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "react-lazy-load-image-component/src/effects/blur.css";

import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter } from "react-router-dom";
import { StyledChart } from "./components/chart";
import { CollapseDrawerProvider } from "./contexts/CollapseDrawerContext";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { MotionLazyContainer } from "./components/animate";
import { WebSocketProvider } from "./contexts/WebSocketContext";
import { ThemeSettings, SettingsProvider } from "./components/settings";

import AdapterDateFns from "@date-io/date-fns";
import { ptBR } from "date-fns/locale";

import Router from "./routes";
import ThemeProvider from "./theme";
import SnackbarProvider from "./components/snackbar/SnackbarProvider";

const container = document.getElementById("root")!;
const root = createRoot(container);
root.render(
	<HelmetProvider>
		<LocalizationProvider adapterLocale={ptBR} dateAdapter={AdapterDateFns}>
			<SettingsProvider>
				<BrowserRouter>
					<MotionLazyContainer>
						<ThemeProvider>
							<ThemeSettings>
								<CollapseDrawerProvider>
									<SnackbarProvider>
										<AuthProvider>
											<WebSocketProvider>
												<StyledChart />
												<Router />
											</WebSocketProvider>
										</AuthProvider>
									</SnackbarProvider>
								</CollapseDrawerProvider>
							</ThemeSettings>
						</ThemeProvider>
					</MotionLazyContainer>
				</BrowserRouter>
			</SettingsProvider>
		</LocalizationProvider>
	</HelmetProvider>
);
