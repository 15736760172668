import { alpha } from "@mui/material/styles";
import { Box, Divider, Typography, Stack, MenuItem } from "@mui/material";
import { CustomAvatar } from "../../../components/custom-avatar";
import { IconButtonAnimate } from "../../../components/animate";
import { serverUrl } from "src/config";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../../components/snackbar";
import { useState } from "react";
import MenuPopover from "../../../components/menu-popover";
import useAuth from "src/hooks/useAuth";

const OPTIONS = [
	{
		label: "Página principal",
		linkTo: "/",
	},
];

export default function AccountPopover() {
	const navigate = useNavigate();

	const { user, logout } = useAuth();

	const { enqueueSnackbar } = useSnackbar();

	const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

	const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
		setOpenPopover(event.currentTarget);
	};

	const handleClosePopover = () => {
		setOpenPopover(null);
	};

	const handleLogout = async () => {
		try {
			logout();
			handleClosePopover();
		} catch (error) {
			console.error(error);
			enqueueSnackbar("Não foi possível fazer logout!", { variant: "error" });
		}
	};

	const handleClickItem = (path: string) => {
		handleClosePopover();
		navigate(path);
	};

	return (
		<>
			<IconButtonAnimate
				onClick={handleOpenPopover}
				sx={{
					p: 0,
					...(openPopover && {
						"&:before": {
							zIndex: 1,
							content: "''",
							width: "100%",
							height: "100%",
							borderRadius: "50%",
							position: "absolute",
							bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
						},
					}),
				}}>
				<CustomAvatar src={`${serverUrl}/api/v1/user/${user?.id}/avatar`} alt={user?.username} name={user?.username} />
			</IconButtonAnimate>

			<MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
				<Box sx={{ my: 1.5, px: 2.5 }}>
					<Typography variant="subtitle2" noWrap>
						{user?.username}
					</Typography>

					<Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
						{user?.email}
					</Typography>
				</Box>

				<Divider sx={{ borderStyle: "solid" }} />

				<Stack sx={{ p: 1 }}>
					{OPTIONS.map((option) => (
						<MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
							{option.label}
						</MenuItem>
					))}
				</Stack>

				<Divider sx={{ borderStyle: "solid" }} />

				<MenuItem onClick={handleLogout} sx={{ m: 1 }}>
					Desconectar
				</MenuItem>
			</MenuPopover>
		</>
	);
}
