import { Navigate, Outlet, useRoutes } from "react-router-dom";
import { WithdrawsDetailsPage } from "src/pages/app/withdraws/Details";
import AccountDetailsPage from "src/pages/app/accounts/Details";
import AccountListPage from "src/pages/app/accounts/List";
import AdminPermissionsPage from "src/pages/app/admin/Permissions";
import AffiliatedDetailsPage from "src/pages/app/affiliated/Details";
import RouteGuard from "src/guards/RouteGuard";
import AffiliatedListPage from "src/pages/app/affiliated/List";
import AuthGuard from "src/guards/AuthGuard";
import DashboardLayout from "src/layout/dashboard";
import DashboardPage from "src/pages/app";
import GamePage from "src/pages/app/games";
import GuestGuard from "src/guards/GuestGuard";
import LoginPage from "src/pages/auth/Login";
import LogsPage from "src/pages/app/logs";
import StatisticsPage from "src/pages/app/statistics";
import WithdrawListPage from "src/pages/app/withdraws/List";
import DepositsListPage from "src/pages/app/deposits/List";

export default function Router() {
	return useRoutes([
		{
			path: "auth",
			children: [
				{
					index: true,
					element: <Navigate to="/auth/login" />,
				},
				{
					path: "login",
					element: (
						<GuestGuard>
							<LoginPage />
						</GuestGuard>
					),
				},
			],
		},
		{
			path: "app",
			element: (
				<AuthGuard>
					<DashboardLayout />
				</AuthGuard>
			),
			children: [
				{
					element: <Navigate to="/app/accounts" />,
					index: true,
				},
				{
					path: "main",
					element: (
						<RouteGuard roles={["super-admin", "admin", "user", "dashboard"]} subDomains={["backoffice", "localhost"]}>
							<DashboardPage />
						</RouteGuard>
					),
				},
				{
					path: "statistics",
					element: (
						<RouteGuard roles={["super-admin", "admin", "user", "statistics"]} subDomains={["backoffice", "localhost"]}>
							<StatisticsPage />
						</RouteGuard>
					),
				},
				{
					path: "admin",
					element: (
						<RouteGuard roles={["super-admin"]} subDomains={["backoffice", "localhost"]}>
							<Outlet />
						</RouteGuard>
					),
					children: [
						{
							index: true,
							element: <Navigate to="/app/admin/permissions" />,
						},
						{
							path: "permissions",
							element: <AdminPermissionsPage />,
						},
					],
				},
				{
					path: "games",
					element: (
						<RouteGuard roles={["super-admin", "admin", "user"]} subDomains={["backoffice", "localhost"]}>
							<Outlet />
						</RouteGuard>
					),
					children: [
						{
							index: true,
							element: <Navigate to="/app/games/double" />,
						},
						{
							path: "double",
							element: <GamePage />,
						},
						{
							path: "doublepro",
							element: <GamePage />,
						},
						{
							path: "rocket",
							element: <GamePage />,
						},
						{
							path: "mines",
							element: <GamePage />,
						},
						{
							path: "dice",
							element: <GamePage />,
						},
						{
							path: "plinko",
							element: <GamePage />,
						},
					],
				},
				{
					path: "logs",
					element: (
						<RouteGuard roles={["super-admin", "admin", "user"]} subDomains={["backoffice", "localhost"]}>
							<LogsPage />
						</RouteGuard>
					),
				},
				{
					path: "accounts",
					element: (
						<RouteGuard roles={["super-admin", "admin", "user", "accounts.see"]} subDomains={["backoffice", "localhost"]}>
							<Outlet />
						</RouteGuard>
					),
					children: [
						{
							index: true,
							element: <Navigate to="/app/accounts/list" />,
						},
						{
							path: "list",
							element: <AccountListPage />,
						},
						{
							path: ":id",
							children: [
								{
									path: "details",
									element: <AccountDetailsPage />,
								},
							],
						},
					],
				},
				{
					path: "affiliated",
					element: (
						<RouteGuard roles={["super-admin", "admin", "user", "affiliates.see", "affiliates.manage"]} subDomains={["backoffice", "localhost"]}>
							<Outlet />
						</RouteGuard>
					),
					children: [
						{
							index: true,
							element: <Navigate to="/app/affiliated/list" />,
						},
						{
							path: "list",
							element: <AffiliatedListPage />,
						},
						{
							path: "sub",
							children: [
								{
									path: ":id",
									element: <AffiliatedDetailsPage />,
								},
							],
						},
						{
							path: ":id",
							children: [
								{
									path: "details",
									element: <AffiliatedDetailsPage />,
								},
							],
						},
					],
				},
				{
					path: "withdraws",
					element: (
						<RouteGuard roles={["super-admin", "admin", "withdraws.see"]} subDomains={["backoffice", "localhost"]}>
							<Outlet />
						</RouteGuard>
					),
					children: [
						{
							index: true,
							element: <Navigate to="/app/withdraws/list" />,
						},
						{
							path: "list",
							element: <WithdrawListPage />,
						},
						{
							path: ":id",
							element: (
								<RouteGuard roles={["super-admin", "admin", "withdraws.see", "withdraws.manage"]} subDomains={["backoffice", "localhost"]}>
									<Outlet />
								</RouteGuard>
							),
							children: [
								{
									path: "details",
									element: <WithdrawsDetailsPage />,
								},
							],
						},
					],
				},
				{
					path: "deposits",
					element: (
						<RouteGuard roles={["super-admin", "admin", "deposits.verify"]} subDomains={["backoffice", "localhost", "deposits"]}>
							<Outlet />
						</RouteGuard>
					),
					children: [
						{
							index: true,
							element: <Navigate to="/app/deposits/list" />,
						},
						{
							path: "list",
							element: <DepositsListPage />,
						},
					],
				},
			],
		},
		{
			path: "/",
			element: <Navigate to="/auth" />,
		},
		{ path: "*", element: <Navigate to="/" replace /> },
	]);
}
