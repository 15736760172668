import { JSXElementConstructor, ReactElement, ReactNode, useState } from "react";
import { IconButton, Modal as MuiModal } from "@mui/material";
import { ModalProps as MuiModalProps, ButtonProps } from "@mui/material";
import Button from "@mui/material/Button";

interface ModalProps extends MuiModalProps {
	defaultOpen?: boolean;
	onClose?: () => {};
	onOpen?: () => {};
	buttonProps?: ButtonProps;
	iconButton?: boolean;
	buttonText: string | ReactNode;
	Children: (close: Function) => ReactElement<any, string | JSXElementConstructor<any>>;
}

export default function Modal({ defaultOpen, onClose, onOpen, buttonProps, buttonText, iconButton, Children, ...props }: ModalProps) {
	const [open, setOpen] = useState(defaultOpen || false);
	const handleOpen = () => {
		if (onClose) onClose();

		setOpen(true);
	};
	const handleClose = () => {
		if (onOpen) onOpen();

		setOpen(false);
	};

	return (
		<>
			{iconButton ? (
				<IconButton onClick={handleOpen} {...buttonProps}>
					{buttonText}
				</IconButton>
			) : (
				<Button onClick={handleOpen} {...buttonProps}>
					{buttonText}
				</Button>
			)}
			<MuiModal aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" {...props} open={open} onClose={handleClose}>
				{Children(handleClose)}
			</MuiModal>
		</>
	);
}
