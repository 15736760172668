import { DefaultTableProps, TableOrder } from "../../_types";
import { emptyRows } from "../../../../utils/tableUtils";
import { TableEmptyRows, TableHeadCustom, TableNoData } from "src/components/table";
import { useState, MouseEvent, ChangeEvent, useEffect } from "react";
import { withdrawsHeadCells } from "../../_constants";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Iconify from "src/components/iconify";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import WithdrawsTableRow from "./WithdrawsTableRow";
import { websiteConfig } from "src/config";

export interface WithdrawsTableRowType {
	id: string;
	email: string;
	document: string;
	kyc: boolean;
	amount: number;
	first: boolean;
	status: string;
	createdAt: number;
	depositTotal: number;
	withdrawTotal: number;
	currency: string;
	walletId: string | null;
	//
	firstName: string;
	lastName: string;
	address: string;
	postalCode: string;
	city: string;
	country: string;
	hometown: string;
	phoneDialCode: string;
	phoneNumber: string;
}

export default function WithdrawsTable({ rows, tableConfig, itemCount, dense: dense_ = false, onChangeTableConfig }: DefaultTableProps<WithdrawsTableRowType>) {
	const [dense, setDense] = useState(dense_);
	const [order, setOrder] = useState<TableOrder>(tableConfig.order);
	const [orderBy, setOrderBy] = useState<keyof WithdrawsTableRowType>(tableConfig.orderBy);
	const [page, setPage] = useState(tableConfig.page);
	const [rowCount, setRowCount] = useState(itemCount);
	const [rowsPerPage, setRowsPerPage] = useState(tableConfig.limit);
	const [searchFilter, setSearchFilter] = useState("");
	const [selected, setSelected] = useState<"all" | readonly string[]>([]);
	const [tableData, setTableData] = useState<WithdrawsTableRowType[]>(rows);

	useEffect(() => {
		setOrder(tableConfig.order);
		setOrderBy(tableConfig.orderBy);
		setPage(tableConfig.page);
		setRowCount(itemCount);
		setRowsPerPage(tableConfig.limit);
		setSearchFilter(tableConfig.search || "");
		setTableData(rows);
	}, [rows, itemCount, tableConfig.limit, tableConfig.order, tableConfig.orderBy, tableConfig.page, tableConfig.search]);

	const handleRequestSort = (id: keyof WithdrawsTableRowType) => {
		const order_ = orderBy === id && order === "asc";
		onChangeTableConfig([
			{ type: "order", data: order_ ? "desc" : "asc" },
			{ type: "orderBy", data: id },
		]);
	};

	const handleSelectRow = (event: MouseEvent<unknown>, id: string) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: readonly string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		onChangeTableConfig([{ type: "page", data: newPage }]);
	};

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		onChangeTableConfig([
			{ type: "limit", data: Number(event.target.value) },
			{ type: "page", data: 0 },
		]);
	};

	const handleChangeDense = (event: ChangeEvent<HTMLInputElement>) => {
		setDense(event.target.checked);
	};

	const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchFilter(event.target.value);
	};

	const handleSearch = () => {
		onChangeTableConfig([{ type: "search", data: searchFilter }]);
	};

	const isSelected = (id: string) => (selected === "all" ? true : selected.indexOf(id) !== -1);

	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<Stack
					spacing={2}
					direction="row"
					sx={{
						alignItems: "center",
						alignContent: "center",
						justifyContent: "center",
						pl: { xs: 1 },
						pr: { xs: 1 },
						py: 2,
					}}>
					<FormControl>
						<InputLabel id="select-status-label">Status</InputLabel>
						<Select
							size="small"
							labelId="select-status-label"
							id="select-status"
							value={tableConfig.keyFilter?.status}
							label="Status"
							onChange={({ target: { value } }) => onChangeTableConfig([{ type: "keyFilter", data: { ...(tableConfig.keyFilter || {}), status: value } }])}>
							<MenuItem value={"pending"}>Pendente</MenuItem>
							<MenuItem value={"approved"}>Aprovado</MenuItem>
							<MenuItem value={"rejected"}>Rejeitado</MenuItem>
							<MenuItem value={"canceled"}>Cancelado</MenuItem>
						</Select>
					</FormControl>
					<TextField
						type="text"
						label="Procurar E-Mail"
						placeholder="abc@mail.com"
						size={dense ? "small" : "medium"}
						value={searchFilter}
						onChange={handleChangeSearch}
						sx={{
							width: 300,
						}}
					/>
					<Button variant="contained" color="success" size={dense ? "small" : "medium"} startIcon={<Iconify icon={"iconoir:search"} />} onClick={handleSearch}>
						Procurar
					</Button>
				</Stack>
				<TableContainer>
					<Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
						<TableHeadCustom<WithdrawsTableRowType>
							headLabel={withdrawsHeadCells}
							order={order}
							orderBy={orderBy}
							onSort={handleRequestSort}
							rowCount={tableData.length}
							extra={true}
						/>
						<TableBody>
							{tableData.map((row, index) => (
								<WithdrawsTableRow isItemSelected={isSelected(row.id)} row={row} key={row.id} labelId={`accounts-table-checkbox-${index}`} handleSelect={handleSelectRow} />
							))}

							<TableEmptyRows height={dense ? 56 : 76} emptyRows={emptyRows(page, rowsPerPage, itemCount)} />
							<TableNoData isNotFound={!tableData.length} />
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
					component="div"
					count={rowCount}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage={"Contas por página"}
					getItemAriaLabel={function defaultGetAriaLabel(type) {
						return `Ir para a ${type === "next" ? "próxima página" : type === "first" ? "primeira página" : type === "last" ? "última página" : "pagina anterior"} `;
					}}
					showFirstButton={page !== 0 && rowCount > rowsPerPage * page}
					showLastButton={page !== Math.ceil(rowCount / rowsPerPage) - 1 && rowCount > rowsPerPage * page}
				/>
			</Paper>
			<FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Modo minimalista" />
		</Box>
	);
}
