import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";

type GuestGuardProps = {
	children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
	const { isAuthenticated } = useAuth();

	if (isAuthenticated()) {
		return <Navigate to="/app/accounts" />;
	}

	return <>{children}</>;
}
