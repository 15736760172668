import { Box, Button, Card, Container, Divider, Grid, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { fCpf } from "src/utils/formatNumber";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import EmptyContent from "src/components/empty-content";
import Iconify from "src/components/iconify";
import Loading from "src/components/Loading";
import Page from "src/components/Page";
import useAuth from "src/hooks/useAuth";
import useWebSocket from "src/hooks/useWebSocket";

export function WithdrawsDetailsPage() {
	const navigate = useNavigate();
	const params = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const { user } = useAuth();
	const { websocket } = useWebSocket();
	const [currentAction, setCurrentAction] = useState<"deposits" | "withdraws">("withdraws");
	const [withdrawInfo, setWithdrawInfo] = useState<null | {
		depositHistory: { id: string; amount: number; bonus: number; status: string; createdAt: string }[];
		withdrawHistory: { id: string; amount: number; status: string; createdAt: string }[];
		games: {
			[key: string]: {
				count: number;
				bonusCount: number;
				betAmount: number;
				betBonusAmount: number;
				gainAmount: number;
				gainBonusAmount: number;
				lossAmount: number;
				lossBonusAmount: number;
			};
		};
		transactions: {
			id: string;
			amount: number;
			initialBalance: number;
			finalBalance: number;
			initialBalanceBonus: number;
			finalBalanceBonus: number;
			description: string;
			date: string;
			typeb: string;
		}[];
		withdrawInfo: {
			createdAt: string;
			amount: number;
			userId: string;
			id: string;
			document: string;
			status: string;
			reason: string;
			changedAt: string;
			initalBalance: number;
			finalBalance: number;
		};
		userInfo: {
			username: string;
			email: string;
			firstName: string;
			lastName: string;
			id: string;
			balance: number;
			bonusBalance: number;
			birthDate: string;
			createdAt: string;
			document: string;
		};
	}>(null);
	const [withdrawRejectReason, setWithdrawRejectReason] = useState("");
	const [requestingTable, setRequestingTable] = useState(false);

	useEffect(() => {
		if (!params.id || params.id.length === 0) return navigate("/app/accounts");

		if (!requestingTable && !withdrawInfo) {
			const blueoceanKey = localStorage.getItem("blueoceanKey");
			websocket.emit("Management.Withdraw.Get", {
				withdrawId: params.id,
				...(blueoceanKey
					? {
							blueOceanKey: blueoceanKey,
					  }
					: {}),
			});
		}
	}, [withdrawInfo, navigate, params.id, requestingTable, websocket, user]);

	useEffect(() => {
		websocket
			.on("Management.Withdraw.Get", (data) => {
				setWithdrawInfo(data);
			})
			.on("Management.Withdraw.Action", (data) => {
				if (data.success) {
					enqueueSnackbar(data.message);
					if (data.nextId) {
						navigate(`/app/withdraws/${data.nextId}/details`, {
							replace: true,
						});
					} else {
						navigate(`/app/withdraws/list`);
					}
				} else {
					enqueueSnackbar(data.message, {
						variant: "error",
					});
				}
			});
	}, [enqueueSnackbar, navigate, websocket]);

	if (!withdrawInfo || !user) return <Loading loadingText="Carregando informações" />;

	const handleApproveWithdraw = () => {
		websocket.emit("Management.Withdraw.Action", { id: withdrawInfo.withdrawInfo.id, platform: localStorage.getItem("withdraw-platform") });
	};

	const handleRejectWithdraw = () => {
		websocket.emit("Management.Withdraw.Action", {
			id: withdrawInfo.withdrawInfo.id,
			reason: withdrawRejectReason.length > 0 ? withdrawRejectReason : "Saque rejeitado.",
			platform: localStorage.getItem("withdraw-platform"),
		});
	};

	return (
		<Page title="Gerenciamento: Saques">
			<Container maxWidth="xl">
				<CustomBreadcrumbs heading={`Detalhes do saque`} links={[{ name: `Atualizado em ${new Date().toLocaleString("pt-BR")}` }]} />
				<Grid container spacing={3}>
					<Grid item xs={12} md={8}>
						<Grid container spacing={3}>
							<Grid item md={6} xs={12}>
								<Card sx={{ p: 3 }}>
									<Typography variant="h5" sx={{ textAlign: "center" }} component="h1">
										Informações do usuário
									</Typography>
									<Divider sx={{ my: 3 }} />
									<Box>
										<Typography variant="subtitle2" color="text.secondary">
											E-Mail:{" "}
											<Typography color="text.primary" component="span">
												{withdrawInfo.userInfo.email}
												<Button size="small" variant="soft" color="secondary" sx={{ ml: 1 }} onClick={() => navigator.clipboard.writeText(withdrawInfo.userInfo.email)}>
													Copiar
												</Button>
											</Typography>
										</Typography>
										<Typography variant="subtitle2" color="text.secondary">
											CPF:{" "}
											<Typography color="text.primary" component="span">
												{fCpf(withdrawInfo.userInfo.document)}
												<Button size="small" variant="soft" color="secondary" sx={{ ml: 1 }} onClick={() => navigator.clipboard.writeText(fCpf(withdrawInfo.userInfo.document))}>
													Copiar
												</Button>
											</Typography>
										</Typography>
										<br />
										<Typography variant="subtitle2" color="text.secondary">
											Saldo:{" "}
											<Typography color="warning.main" component="span">
												{withdrawInfo.userInfo.balance.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
											</Typography>
										</Typography>
										<Typography variant="subtitle2" color="text.secondary">
											Saldo bônus:{" "}
											<Typography color="warning.light" component="span">
												{withdrawInfo.userInfo.bonusBalance.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
											</Typography>
										</Typography>
										<br />
										<Typography variant="subtitle2" color="text.secondary">
											Depósito total:{" "}
											<Typography color="success.main" component="span">
												{withdrawInfo.depositHistory
													.reduce((prev, curr) => prev + (curr.status === "confirmed" ? curr.amount : 0), 0)
													.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
											</Typography>
										</Typography>
										<Typography variant="subtitle2" color="text.secondary">
											Depósito bônus total:{" "}
											<Typography color="success.main" component="span">
												{withdrawInfo.depositHistory
													.reduce((prev, curr) => prev + (curr.status === "confirmed" ? curr.amount * ((curr.bonus + 100) / 100) - curr.amount : 0), 0)
													.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
											</Typography>
										</Typography>
										<Typography variant="subtitle2" color="text.secondary">
											Saque total:{" "}
											<Typography color="error.main" component="span">
												{withdrawInfo.withdrawHistory
													.reduce((prev, curr) => prev + (curr.status === "approved" ? curr.amount : 0), 0)
													.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
											</Typography>
										</Typography>
										<br />
										<Typography variant="subtitle2" color="text.secondary">
											Movimento real:{" "}
											<Typography color="success.light" component="span">
												{withdrawInfo.transactions
													.reduce((prev, curr) => prev + (curr.finalBalance - curr.initialBalance), 0)
													.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
											</Typography>
										</Typography>
										<Typography variant="subtitle2" color="text.secondary">
											Movimento bônus:{" "}
											<Typography color="success.light" component="span">
												{withdrawInfo.transactions
													.reduce((prev, curr) => prev + (curr.finalBalanceBonus - curr.initialBalanceBonus), 0)
													.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
											</Typography>
										</Typography>
									</Box>
								</Card>
							</Grid>
							<Grid item md={6} xs={12}>
								<Card sx={{ p: 3, height: "100%" }}>
									<Typography variant="h5" sx={{ textAlign: "center" }} component="h1">
										Informações do saque
									</Typography>
									<Divider sx={{ my: 3 }} />
									<Box>
										<Typography variant="subtitle2" color="text.secondary">
											Quantia:{" "}
											<Typography color="text.primary" component="span">
												{withdrawInfo.withdrawInfo.amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
												<Button
													size="small"
													variant="soft"
													color="secondary"
													sx={{ ml: 1 }}
													onClick={() => navigator.clipboard.writeText(withdrawInfo.withdrawInfo.amount.toString())}>
													Copiar
												</Button>
											</Typography>
										</Typography>
										<br />
										<Typography variant="subtitle2" color="text.secondary">
											Data de solicitação:{" "}
											<Typography color="text.primary" component="span">
												{new Date(withdrawInfo.withdrawInfo.createdAt).toLocaleString("pt-BR")}
											</Typography>
										</Typography>
										<Typography variant="subtitle2" color="text.secondary">
											Última modificação:{" "}
											<Typography color="text.primary" component="span">
												{new Date(withdrawInfo.withdrawInfo.changedAt).toLocaleString("pt-BR")}
											</Typography>
										</Typography>
										<br />
										<Box sx={{ display: "flex", alignItems: "center", alignContent: "center", gap: 2 }}>
											<Typography variant="subtitle2" color="text.secondary">
												Status:
											</Typography>
											<Iconify
												icon={`iconoir:${
													withdrawInfo.withdrawInfo.status === "pending" ? "clock" : (withdrawInfo.withdrawInfo.status === "approved" ? "check" : "delete") + "-circle"
												}`}
												color={`${withdrawInfo.withdrawInfo.status === "pending" ? "warning" : withdrawInfo.withdrawInfo.status === "approved" ? "success" : "error"}.main`}
											/>
											<Typography
												variant="subtitle2"
												color={`${withdrawInfo.withdrawInfo.status === "pending" ? "warning" : withdrawInfo.withdrawInfo.status === "approved" ? "success" : "error"}.light`}>
												{withdrawInfo.withdrawInfo.status === "pending"
													? "Pendente"
													: withdrawInfo.withdrawInfo.status === "approved"
													? "Aprovado"
													: withdrawInfo.withdrawInfo.status === "canceled"
													? "Cancelado"
													: "Recusado"}
											</Typography>
										</Box>
										<Divider sx={{ my: 3 }} />
										{user && user.roles.some((role) => ["super-admin", "admin", "withdraws.manage"].includes(role)) && (
											<Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", alignContent: "center", gap: 3 }}>
												<Box sx={{ textAlign: "center" }}>
													<TextField
														size="small"
														placeholder="Motivo"
														sx={{ mb: 1 }}
														value={withdrawRejectReason}
														onChange={({ target: { value } }) => setWithdrawRejectReason(value)}
													/>
													<Button variant="contained" color="error" onClick={handleRejectWithdraw}>
														Recusar
													</Button>
												</Box>
												<Divider orientation="vertical" flexItem />
												<Box>
													<Button variant="soft" color="success" sx={{ mx: 6 }} onClick={handleApproveWithdraw}>
														Aprovar
													</Button>
												</Box>
											</Box>
										)}
									</Box>
								</Card>
							</Grid>
							<Grid item md={6} xs={12}>
								<Card sx={{ px: 2, py: 3 }}>
									<Typography variant="h5" sx={{ textAlign: "center" }} component="h1">
										Jogos jogados com saldo real
									</Typography>
									<Divider sx={{ my: 3 }} />
									<Box sx={{ display: "flex", flexWrap: "wrap", gap: 4, justifyContent: "space-around" }}>
										{Object.keys(withdrawInfo.games).filter((key) => withdrawInfo.games[key].count > 0).length > 0 ? (
											Object.keys(withdrawInfo.games)
												.filter((key) => withdrawInfo.games[key].count > 0)
												.map((key) => {
													const gameInfo = withdrawInfo.games[key];
													return (
														<Box key={`played-game-${key}`}>
															<Typography variant="subtitle1" color="text.primary" sx={{ textAlign: "center" }}>
																{key.charAt(0).toUpperCase() + key.slice(1)}
															</Typography>
															<Box sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}>
																<Typography variant="subtitle2" color="text.secondary">
																	Apostou:
																</Typography>
																<Typography color="warning.main" variant="subtitle2">
																	{gameInfo.betAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
																</Typography>
															</Box>
															<Box sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}>
																<Typography variant="subtitle2" color="text.secondary">
																	Ganhou:
																</Typography>
																<Typography color="success.main" variant="subtitle2">
																	{gameInfo.gainAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
																</Typography>
															</Box>
															<Box sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}>
																<Typography variant="subtitle2" color="text.secondary">
																	Perdeu:
																</Typography>
																<Typography color="error.main" variant="subtitle2">
																	{gameInfo.lossAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
																</Typography>
															</Box>
															<Divider sx={{ my: 1 }} />
															<Box sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}>
																<Typography variant="subtitle2" color="text.secondary">
																	Lucro:
																</Typography>
																<Typography color={(gameInfo.gainAmount - gameInfo.lossAmount > 0 ? "success" : "error") + ".main"} variant="subtitle2">
																	{(gameInfo.gainAmount - gameInfo.lossAmount).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
																</Typography>
															</Box>
														</Box>
													);
												})
										) : (
											<EmptyContent title={"Nenhum jogo"} sx={{ maxHeight: 360 }} />
										)}
									</Box>
								</Card>
							</Grid>
							<Grid item md={6} xs={12}>
								<Card sx={{ px: 2, py: 3 }}>
									<Typography variant="h5" sx={{ textAlign: "center" }} component="h1">
										Jogos jogados com saldo bônus
									</Typography>
									<Divider sx={{ my: 3 }} />
									<Box sx={{ display: "flex", flexWrap: "wrap", gap: 4, justifyContent: "space-around" }}>
										{Object.keys(withdrawInfo.games).filter((key) => withdrawInfo.games[key].bonusCount > 0).length > 0 ? (
											Object.keys(withdrawInfo.games)
												.filter((key) => withdrawInfo.games[key].bonusCount > 0)
												.map((key) => {
													const gameInfo = withdrawInfo.games[key];
													return (
														<Box key={`played-game-bonus-${key}`}>
															<Typography variant="subtitle1" color="text.primary" sx={{ textAlign: "center" }}>
																{key.charAt(0).toUpperCase() + key.slice(1)}
															</Typography>
															<Box sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}>
																<Typography variant="subtitle2" color="text.secondary">
																	Apostou:
																</Typography>
																<Typography color="warning.main" variant="subtitle2">
																	{gameInfo.betBonusAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
																</Typography>
															</Box>
															<Box sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}>
																<Typography variant="subtitle2" color="text.secondary">
																	Ganhou:
																</Typography>
																<Typography color="success.main" variant="subtitle2">
																	{gameInfo.gainBonusAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
																</Typography>
															</Box>
															<Box sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}>
																<Typography variant="subtitle2" color="text.secondary">
																	Perdeu:
																</Typography>
																<Typography color="error.main" variant="subtitle2">
																	{gameInfo.lossBonusAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
																</Typography>
															</Box>
															<Divider sx={{ my: 1 }} />
															<Box sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}>
																<Typography variant="subtitle2" color="text.secondary">
																	Lucro:
																</Typography>
																<Typography color={(gameInfo.gainBonusAmount - gameInfo.lossBonusAmount > 0 ? "success" : "error") + ".main"} variant="subtitle2">
																	{(gameInfo.gainBonusAmount - gameInfo.lossBonusAmount).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
																</Typography>
															</Box>
														</Box>
													);
												})
										) : (
											<EmptyContent title={"Nenhum jogo"} sx={{ maxHeight: 360 }} />
										)}
									</Box>
								</Card>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4}>
						<Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
							<ToggleButtonGroup
								color="primary"
								value={currentAction}
								exclusive
								onChange={(event, newValue) => setCurrentAction(newValue || currentAction)}
								aria-label="Saques ou depósitos">
								<ToggleButton value={"deposits"}>Depósitos</ToggleButton>
								<ToggleButton value={"withdraws"}>Saques</ToggleButton>
							</ToggleButtonGroup>
						</Box>
						<Grid container spacing={3} sx={{ justifyContent: "space-around" }}>
							{currentAction === "deposits"
								? withdrawInfo.depositHistory
										.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
										.map((deposit) => (
											<Grid item md={6} xs={6} key={`deposit-${deposit.id}`}>
												<Card sx={{ p: 3 }}>
													<Typography variant="subtitle2">Data: {new Date(deposit.createdAt).toLocaleString("pt-BR")}</Typography>
													<Typography variant="subtitle2">Valor: {deposit.amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</Typography>
													<Typography variant="subtitle2">
														Bônus: {(deposit.amount * ((deposit.bonus + 100) / 100) - deposit.amount).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
													</Typography>
													<Box sx={{ display: "flex", alignItems: "center", alignContent: "center", gap: 1 }}>
														<Iconify
															icon={`iconoir:${deposit.status === "pending" ? "clock" : (deposit.status === "confirmed" ? "check" : "delete") + "-circle"}`}
															color={`${deposit.status === "pending" ? "warning" : deposit.status === "confirmed" ? "success" : "error"}.main`}
														/>
														<Typography variant="subtitle2" color={`${deposit.status === "pending" ? "warning" : deposit.status === "confirmed" ? "success" : "error"}.light`}>
															{deposit.status === "pending" ? "Pendente" : deposit.status === "confirmed" ? "Aprovado" : deposit.status === "canceled" ? "Cancelado" : "Recusado"}
														</Typography>
													</Box>
												</Card>
											</Grid>
										))
								: withdrawInfo.withdrawHistory
										.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
										.map((withdraw) => (
											<Grid item md={6} xs={6} key={`withdraw-${withdraw.id}`}>
												<Card sx={{ p: 3 }}>
													<Typography variant="subtitle2">Data: {new Date(withdraw.createdAt).toLocaleString("pt-BR")}</Typography>
													<Typography variant="subtitle2">Valor: {withdraw.amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</Typography>
													<Box sx={{ display: "flex", alignItems: "center", alignContent: "center", gap: 1 }}>
														<Iconify
															icon={`iconoir:${withdraw.status === "pending" ? "clock" : (withdraw.status === "approved" ? "check" : "delete") + "-circle"}`}
															color={`${withdraw.status === "pending" ? "warning" : withdraw.status === "approved" ? "success" : "error"}.main`}
														/>
														<Typography variant="subtitle2" color={`${withdraw.status === "pending" ? "warning" : withdraw.status === "approved" ? "success" : "error"}.light`}>
															{withdraw.status === "pending" ? "Pendente" : withdraw.status === "approved" ? "Aprovado" : withdraw.status === "canceled" ? "Cancelado" : "Recusado"}
														</Typography>
													</Box>
												</Card>
											</Grid>
										))}
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</Page>
	);
}
