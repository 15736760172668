import { alpha, useTheme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import FirstInteractionsGraph from "src/sections/FirstInteractionsGraph";
import Grid from "@mui/material/Grid";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs";
import Iconify from "src/components/iconify";
import Loading from "src/components/Loading";
import Page from "src/components/Page";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useWebSocket from "src/hooks/useWebSocket";
import WidgetSummary from "src/sections/app/WidgetSummary";

const getChartData = (docs: string[], after: number, before: number) => {
	const dataArray = new Array(Number(Math.abs((before - (after - 1000 * 60 * 60 * 24)) / 24 / 60 / 60 / 1000).toFixed(0))).fill(0);
	const newData: number[] = [...dataArray];

	for (const date of docs) {
		const accountDate = new Date(date).getTime();
		const diffTime = Math.abs(before - accountDate);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		newData[diffDays - 1] += 1;
	}

	return newData;
};

export default function StatisticsPage() {
	const theme = useTheme();
	const { statistics, websocket, setWebsocketState } = useWebSocket();
	const [requested, setRequested] = useState(false);
	const [detailsConfig, setDetailsConfig] = useState({
		after: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0).getTime() - 1000 * 60 * 60 * 24 * 7,
		before: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 999).getTime(),
	});

	useEffect(() => {
		if (!statistics && !requested) {
			setRequested(true);
			websocket.emit("Statistics.Get", {
				after: detailsConfig.after,
				before: detailsConfig.before,
			});
		}
	}, [detailsConfig.after, detailsConfig.before, requested, statistics, websocket]);

	if (!statistics) return <Loading loadingText="Carregando estatísticas" />;

	/**
	 * Date Filter
	 */

	const chartLabels = new Array(Math.floor((detailsConfig.before - detailsConfig.after) / 24 / 60 / 60 / 1000)).fill(0).map((_, i) => {
		const date = new Date(detailsConfig.before - 1000 * 60 * 60 * 24 * i);
		return date.toLocaleDateString("en-US");
	});

	const handleFilterData = () => {
		setRequested(false);
		setWebsocketState((_state) => ({ ..._state, statistics: null }));
	};

	return (
		<Page title="Estatísticas">
			<Container maxWidth="xl">
				<CustomBreadcrumbs heading="Estatísticas" links={[{ name: `Atualizado em ${new Date().toLocaleString("pt-BR")}` }]} />
				<Box sx={{ my: 3, textAlign: "center" }}>
					<Grid
						container
						spacing={3}
						sx={{
							display: "flex",
							flexDirection: {
								lg: "row",
								md: "column-reverse",
							},
						}}>
						<Grid item xs={12} lg={3}>
							<WidgetSummary
								title="Lucro da plataforma"
								total={(statistics.deposits.amount.totalReal - statistics.accounts.balances.real - statistics.withdraws.amount.total).toLocaleString("pt-BR", {
									currency: "BRL",
									style: "currency",
								})}
								icon={
									<Iconify
										icon={"iconoir:card-wallet"}
										sx={{
											width: "100%",
											height: "100%",
											color: theme.palette.warning.main,
										}}
									/>
								}
								bgColor={alpha(theme.palette.warning.main, 0.08)}
							/>
						</Grid>
						<Grid item xs={12} lg={6}>
							<Box>
								<Stack spacing={3} direction="row" sx={{ justifyContent: "center", alignItems: "center", alignContent: "center" }}>
									<DatePicker
										renderInput={(props) => <TextField size={"small"} {...props} />}
										label="A partir da data"
										value={detailsConfig.after}
										onChange={(newValue: Date | null) => {
											setDetailsConfig((_oldConfig) => ({ ..._oldConfig, after: newValue ? newValue.getTime() : Date.now() }));
										}}
									/>
									<Typography variant="subtitle2" color="text.secondary">
										{((detailsConfig.before - detailsConfig.after) / 24 / 60 / 60 / 1000).toFixed(0)} dia
										{Math.floor((detailsConfig.before - detailsConfig.after) / 24 / 60 / 60 / 1000) - 1 > 1 ? "s" : ""}
									</Typography>
									<DatePicker
										renderInput={(props) => <TextField size={"small"} {...props} />}
										label="Até a data"
										value={detailsConfig.before}
										onChange={(newValue: Date | null) => {
											newValue?.setHours(23, 59, 59, 999);
											setDetailsConfig((_oldConfig) => ({ ..._oldConfig, before: newValue ? newValue.getTime() : Date.now() }));
										}}
									/>
								</Stack>
								<Stack spacing={3} direction="row" sx={{ mt: 1, justifyContent: "center" }}>
									{[1, 7, 14, 31].map((dayOffset) => (
										<Button
											key={`date-change-${dayOffset}`}
											color="secondary"
											variant="soft"
											size="small"
											onClick={() =>
												setDetailsConfig((_oldConfig) => ({
													..._oldConfig,
													after: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0).getTime() - 1000 * 60 * 60 * 24 * dayOffset,
													before: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 999).getTime(),
												}))
											}>
											{dayOffset} dia{dayOffset > 1 ? "s" : ""}
										</Button>
									))}
								</Stack>
							</Box>
							<Box sx={{ mt: 2 }}>
								<Button variant="contained" color="success" onClick={handleFilterData}>
									Filtrar
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Box>
				<Divider sx={{ mt: 5, mb: 3 }} />
				<Grid container spacing={3}>
					<Grid item xs={12} lg={4}>
						<Card
							sx={{
								display: "flex",
								justifyContent: "space-between",
								p: 2,
								pl: 3,
								color: "success.main",
							}}>
							<div>
								<Typography variant="h3">{statistics.deposits.count.twoDeposits}</Typography>
								<Typography variant="subtitle2" sx={{ color: "text.secondary", mt: -0.5 }}>
									Pessoas que depositaram duas vezes nesse período
								</Typography>
							</div>
						</Card>
					</Grid>
					<Grid item xs={12} lg={4}>
						<Card
							sx={{
								display: "flex",
								justifyContent: "space-between",
								p: 2,
								pl: 3,
								color: "success.main",
							}}>
							<div>
								<Typography variant="h3">{statistics.deposits.count.moreThanTwoDeposits}</Typography>
								<Typography variant="subtitle2" sx={{ color: "text.secondary", mt: -0.5 }}>
									Pessoas que depositaram três ou mais vezes nesse período
								</Typography>
							</div>
						</Card>
					</Grid>
				</Grid>
				<Divider sx={{ mt: 5, mb: 3 }} />
				<Typography variant="h4" sx={{ mb: 3, textAlign: "center" }}>
					Primeiras interações
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={12} lg={8}>
						<FirstInteractionsGraph
							title={`Dados de primeiras interações`}
							chart={{
								labels: chartLabels,
								series: [
									{
										name: "Cadastros",
										type: "bar",
										fill: "gradient",
										data: getChartData(statistics.accounts.creationDates, detailsConfig.after, detailsConfig.before),
									},
									{
										name: "Primeiros depósitos",
										type: "bar",
										fill: "gradient",
										data: getChartData(statistics.deposits.firstDepositsDates, detailsConfig.after, detailsConfig.before),
									},
									{
										name: "Primeiros saques",
										type: "bar",
										fill: "gradient",
										data: getChartData(statistics.withdraws.firstWithdrawsDates, detailsConfig.after, detailsConfig.before),
									},
								],
							}}
						/>
					</Grid>
					<Grid item xs={12} lg={4}>
						<Stack spacing={2.3}>
							<WidgetSummary title="Cadastros" total={statistics.accounts.count.toLocaleString()} icon={null} bgColor={""} sx={{ color: "primary.main" }} />
							<Card
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									p: 2,
									pl: 3,
									color: "success.main",
								}}>
								<div>
									<Typography variant="h3">{(statistics.deposits.count.firstWithoutBonus + statistics.deposits.count.firstWithBonus).toLocaleString()}</Typography>
									<Typography variant="subtitle2" sx={{ color: "text.secondary", mt: -0.5 }}>
										Quantidade de primeiros depósitos
									</Typography>

									<Divider sx={{ mt: 1, mb: 1.5 }} />
									<Typography variant="subtitle2" sx={{ color: "text.primary" }}>
										Quantidade de primeiros depósitos dos cadastrados:{" "}
										<Typography variant="subtitle2" sx={{ color: "success.light" }} component="span">
											{statistics.accounts.firstDeposited}
										</Typography>
									</Typography>
									<Typography variant="subtitle2" sx={{ color: "text.primary" }}>
										Taxa de conversão:{" "}
										<Typography variant="subtitle2" sx={{ color: "success.light" }} component="span">
											{((statistics.accounts.firstDeposited * 100) / statistics.accounts.count).toFixed(2)}%
										</Typography>
									</Typography>
									<Typography variant="subtitle2" sx={{ color: "text.primary" }}>
										Sem bônus:{" "}
										<Typography variant="subtitle2" sx={{ color: "success.light" }} component="span">
											{statistics.deposits.count.firstWithoutBonus.toLocaleString()}
										</Typography>
									</Typography>
									<Typography variant="subtitle2" sx={{ color: "text.primary" }}>
										Com bônus:{" "}
										<Typography variant="subtitle2" sx={{ color: "success.light" }} component="span">
											{statistics.deposits.count.firstWithBonus.toLocaleString()}
										</Typography>
									</Typography>
									<Typography variant="subtitle2" sx={{ color: "text.primary" }}>
										Quantia real:{" "}
										<Typography variant="subtitle2" sx={{ color: "success.light" }} component="span">
											{statistics.deposits.amount.firstTotalReal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
										</Typography>
									</Typography>
									<Typography variant="subtitle2" sx={{ color: "text.primary" }}>
										Quantia bônus:{" "}
										<Typography variant="subtitle2" sx={{ color: "success.light" }} component="span">
											{statistics.deposits.amount.firstTotalBonus.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
										</Typography>
									</Typography>
									<Typography variant="subtitle2" sx={{ color: "text.primary" }}>
										Quantia média:{" "}
										<Typography variant="subtitle2" sx={{ color: "success.light" }} component="span">
											{statistics.deposits.amount.averageFirstReal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
										</Typography>
									</Typography>
								</div>
							</Card>
							<WidgetSummary title="Primeiros saques" total={statistics.withdraws.count.first.toLocaleString()} icon={null} bgColor={""} sx={{ color: "error.main" }} />
						</Stack>
					</Grid>
				</Grid>
				<Divider sx={{ mt: 5, mb: 3 }} />
				<Typography variant="h4" sx={{ mb: 3, textAlign: "center" }}>
					Depósitos e Saques
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={12} lg={4}>
						<Card
							sx={{
								display: "flex",
								justifyContent: "space-between",
								p: 2,
								pl: 3,
								color: "success.main",
							}}>
							<div>
								<Typography variant="h3">{(statistics.deposits.count.totalWithoutBonus + statistics.deposits.count.totalWithBonus).toLocaleString()}</Typography>
								<Typography variant="subtitle2" sx={{ color: "text.secondary", mt: -0.5 }}>
									Quantidade total de depósitos
								</Typography>

								<Divider sx={{ mt: 1, mb: 1.5 }} />

								<Typography variant="subtitle2" sx={{ color: "text.primary" }}>
									Sem Bônus:{" "}
									<Typography variant="subtitle2" sx={{ color: "success.light" }} component="span">
										{statistics.deposits.count.totalWithoutBonus.toLocaleString()}
									</Typography>
								</Typography>
								<Typography variant="subtitle2" sx={{ color: "text.primary" }}>
									Com Bônus:{" "}
									<Typography variant="subtitle2" sx={{ color: "success.light" }} component="span">
										{statistics.deposits.count.totalWithBonus.toLocaleString()}
									</Typography>
								</Typography>
							</div>
						</Card>
					</Grid>
					<Grid item xs={12} lg={4}>
						<Card
							sx={{
								display: "flex",
								justifyContent: "space-between",
								height: "100%",
								p: 2,
								pl: 3,
								color: "success.main",
							}}>
							<div>
								<Typography variant="h3">{statistics.deposits.amount.totalReal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</Typography>
								<Typography variant="subtitle2" sx={{ color: "text.secondary", mt: -0.5 }}>
									Quantia real depositada
								</Typography>

								<Divider sx={{ mt: 1, mb: 1.5 }} />

								<Typography variant="subtitle2" sx={{ color: "text.primary" }}>
									Média:{" "}
									<Typography variant="subtitle2" sx={{ color: "success.light" }} component="span">
										{statistics.deposits.amount.averageReal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
									</Typography>
								</Typography>
							</div>
						</Card>
					</Grid>
					<Grid item xs={12} lg={4}>
						<Card
							sx={{
								display: "flex",
								justifyContent: "space-between",
								height: "100%",
								p: 2,
								pl: 3,
								color: "success.main",
							}}>
							<div>
								<Typography variant="h3">{statistics.deposits.amount.totalBonus.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</Typography>
								<Typography variant="subtitle2" sx={{ color: "text.secondary", mt: -0.5 }}>
									Quantia bônus adicionada
								</Typography>

								<Divider sx={{ mt: 1, mb: 1.5 }} />

								<Typography variant="subtitle2" sx={{ color: "text.primary" }}>
									Média:{" "}
									<Typography variant="subtitle2" sx={{ color: "success.light" }} component="span">
										{statistics.deposits.amount.averageBonus.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
									</Typography>
								</Typography>
							</div>
						</Card>
					</Grid>
					<Grid item xs={12} lg={4}>
						<Card
							sx={{
								display: "flex",
								justifyContent: "space-between",
								height: "100%",
								p: 2,
								pl: 3,
								color: "error.main",
							}}>
							<div>
								<Typography variant="h3">{statistics.withdraws.count.total.toLocaleString()}</Typography>

								<Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
									Quantidade total de saques
								</Typography>
							</div>
						</Card>
					</Grid>
					<Grid item xs={12} lg={4}>
						<Card
							sx={{
								display: "flex",
								justifyContent: "space-between",
								height: "100%",
								p: 2,
								pl: 3,
								color: "error.main",
							}}>
							<div>
								<Typography variant="h3">{statistics.withdraws.amount.total.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</Typography>
								<Typography variant="subtitle2" sx={{ color: "text.secondary", mt: -0.5 }}>
									Quantia total de saques
								</Typography>

								<Divider sx={{ mt: 1, mb: 1.5 }} />

								<Typography variant="subtitle2" sx={{ color: "text.primary" }}>
									Média:{" "}
									<Typography variant="subtitle2" sx={{ color: "error.light" }} component="span">
										{statistics.withdraws.amount.average.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
									</Typography>
								</Typography>
							</div>
						</Card>
					</Grid>
					<Grid item xs={12} lg={4}>
						<Card
							sx={{
								display: "flex",
								justifyContent: "space-between",
								height: "100%",
								p: 2,
								pl: 3,
								color: "primary.main",
							}}>
							<div>
								<Typography variant="h3">{statistics.accounts.balances.real.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</Typography>
								<Typography variant="subtitle2" sx={{ color: "text.secondary", mt: -0.5 }}>
									Total de saldo
								</Typography>

								<Divider sx={{ mt: 1, mb: 1.5 }} />

								<Typography variant="subtitle2" sx={{ color: "text.primary" }}>
									Saldo Bônus:{" "}
									<Typography variant="subtitle2" sx={{ color: "primary.light" }} component="span">
										{statistics.accounts.balances.bonus.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
									</Typography>
								</Typography>
								<Typography variant="subtitle2" sx={{ color: "text.primary" }}>
									Lucro médio:{" "}
									<Typography variant="subtitle2" sx={{ color: "primary.light" }} component="span">
										{statistics.accounts.averageProfit.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
									</Typography>
								</Typography>
							</div>
						</Card>
					</Grid>
				</Grid>
			</Container>
		</Page>
	);
}
