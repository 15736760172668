import { AccountsTableRowType } from "./accounts/list/AccountsTable";
import { AffiliatedTableRowType } from "./affiliated/AffiliatedTable";
import { HeadCell } from "./_types";
import { AffiliatedPaymentsTableRowType } from "./affiliated/details/AffiliatedPaymentsTable";
import { LogsTableRowType } from "./logs/LogsTable";
import { AccountTransactionsTableRowType } from "./accounts/details/AccountTransactionsTable";
import { AffiliatedCpaTableRowType } from "./affiliated/details/AffiliatedCpaTable";
import { WithdrawsTableRowType } from "./withdraws/list/WithdrawsTable";
import { AccountDepositsTableRowType } from "./accounts/details/AccountDepositsTable";
import { AccountWithdrawsTableRowType } from "./accounts/details/AccountWithdrawsTable";

export const dateLocale = "pt-BR";

export const dateOptions: Intl.DateTimeFormatOptions = {
	year: "numeric",
	month: "2-digit",
	day: "2-digit",
	hour: "2-digit",
	minute: "2-digit",
	second: "2-digit",
};

export const affiliatedPaymentsHeadCells: HeadCell<AffiliatedPaymentsTableRowType>[] = [
	{
		id: "type",
		disablePadding: true,
		label: "Tipo",
	},
	{
		id: "amount",
		disablePadding: true,
		label: "Quantia",
	},
	{
		id: "type",
		disablePadding: true,
		label: "Cfg. no pagamento",
	},
	{
		id: "email",
		disablePadding: true,
		label: "Realizada por",
	},
	{
		id: "date",
		disablePadding: true,
		label: "Data",
	},
];

export const affiliatedHeadCells: HeadCell<AffiliatedTableRowType>[] = [
	{
		id: "email",
		disablePadding: true,
		label: "E-Mail",
	},
	{
		id: "username",
		disablePadding: true,
		label: "Apelido",
	},
	{
		id: "firstName",
		disablePadding: true,
		label: "Nome",
	},
	{
		id: "peopleUsingCode",
		disablePadding: true,
		label: "Usuários",
	},
	{
		id: "cpa",
		disablePadding: true,
		label: "CPA",
	},
	{
		id: "revenueShare",
		disablePadding: true,
		label: "Revenue Share",
	},
	{
		id: "document",
		disablePadding: true,
		label: "CPF",
	},
];

export const accountsHeadCells: HeadCell<AccountsTableRowType>[] = [
	{
		id: "username",
		disablePadding: true,
		label: "Apelido",
	},
	{
		id: "email",
		disablePadding: false,
		label: "E-Mail",
	},
	{
		id: "firstName",
		disablePadding: true,
		label: "Nome",
	},
	{
		id: "balance",
		disablePadding: false,
		label: "Saldo",
	},
	{
		id: "bonusBalance",
		disablePadding: false,
		label: "Saldo bônus",
	},
	{
		id: "createdAt",
		disablePadding: false,
		label: "Criada em",
	},
	{
		id: "birthDate",
		disablePadding: false,
		label: "Data de nascimento",
	},
];

export const logsHeadCells: HeadCell<LogsTableRowType>[] = [
	{
		id: "action",
		disablePadding: false,
		label: "Ação",
	},
	{
		id: "message",
		disablePadding: false,
		label: "Mensagem",
	},
	{
		id: "ip",
		disablePadding: false,
		label: "Informações",
	},
	{
		id: "date",
		disablePadding: false,
		label: "Data",
	},
	{
		id: "success",
		disablePadding: false,
		label: "Sucesso",
	},
];

export const accountTransactionsHeadCells: HeadCell<AccountTransactionsTableRowType>[] = [
	{
		id: "date",
		disablePadding: false,
		label: "Data",
	},
	{
		id: "id",
		disablePadding: false,
		label: "ID",
	},
	{
		id: "type",
		disablePadding: false,
		label: "Tipo",
	},
	{
		id: "amount",
		disablePadding: false,
		label: "Aposta",
	},
	{
		id: "initialBalance",
		disablePadding: false,
		label: "Saldo inicial",
	},
	{
		id: "finalBalance",
		disablePadding: false,
		label: "Saldo final",
	},
	{
		id: "id",
		disablePadding: false,
		label: "Resultado",
	},
	{
		id: "finalResult",
		disablePadding: false,
		label: "Contabilizado",
	},
];

export const affiliatedCpaHeadCells: HeadCell<AffiliatedCpaTableRowType>[] = [
	{
		id: "id",
		disablePadding: false,
		label: "ID",
	},
	{
		id: "amount",
		disablePadding: false,
		label: "Quantia",
	},
	{
		id: "cpaCheck",
		disablePadding: false,
		label: "Passou no CPA",
	},
	{
		id: "cpaAmount",
		disablePadding: false,
		label: "Quantia acumulada no CPA",
	},
];

export const withdrawsHeadCells: HeadCell<WithdrawsTableRowType>[] = [
	{
		id: "status",
		disablePadding: false,
		label: "Status",
	},
	{
		id: "walletId",
		disablePadding: false,
		label: "Carteira",
	},
	{
		id: "email",
		disablePadding: false,
		label: "E-Mail",
	},
	{
		id: "document",
		disablePadding: false,
		label: "CPF",
	},
	{
		id: "amount",
		disablePadding: false,
		label: "Quantia",
	},
	{
		id: "kyc",
		disablePadding: false,
		label: "KYC",
	},
	{
		id: "createdAt",
		disablePadding: false,
		label: "Data",
	},
	{
		id: "depositTotal",
		disablePadding: false,
		label: "Depósito Total",
	},
	{
		id: "withdrawTotal",
		disablePadding: false,
		label: "Saque Total",
	},
	{
		id: "first",
		disablePadding: false,
		label: "Primeiro",
	},
];

export const accountDepositsHeadCells: HeadCell<AccountDepositsTableRowType>[] = [
	{
		id: "status",
		disablePadding: false,
		label: "Status",
	},
	{
		id: "id",
		disablePadding: false,
		label: "ID",
	},
	{
		id: "createdAt",
		disablePadding: false,
		label: "Data",
	},
	{
		id: "amount",
		disablePadding: false,
		label: "Quantia",
	},
	{
		id: "bonus",
		disablePadding: false,
		label: "Bônus",
	},
];

export const accountWithdrawsHeadCells: HeadCell<AccountWithdrawsTableRowType>[] = [
	{
		id: "status",
		disablePadding: false,
		label: "Status",
	},
	{
		id: "id",
		disablePadding: false,
		label: "ID",
	},
	{
		id: "createdAt",
		disablePadding: false,
		label: "Data",
	},
	{
		id: "amount",
		disablePadding: false,
		label: "Quantia",
	},
];

export const accountsBaseRoute = "/app/accounts";
export const withdrawsBaseRoute = "/app/withdraws";
export const affiliatedBaseRoute = "/app/affiliated";
export const subAffiliatedBaseRoute = "/app/affiliated/sub";
export const transactionsBaseRoute = "/app/transactions";
