import { createContext, ReactNode, useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";
import { serverUrl } from "src/config";
import { WebSocketContextType, WebSocketState } from "src/@types/websocket";
import useAuth from "src/hooks/useAuth";

const WebSocketContext = createContext({} as WebSocketContextType);

type WebSocketProviderProps = {
	children: ReactNode;
};

export const socket = io(serverUrl, {
	reconnectionDelay: 1000,
	autoConnect: false,
});

function WebSocketProvider({ children }: WebSocketProviderProps) {
	const { logout } = useAuth();
	const [websocket] = useState<Socket>(socket);
	const [websocketState, setWebsocketState] = useState<WebSocketState>({
		statistics: null,
		dashData: null,
		double: null,
		doublepro: null,
		rocket: null,
		mines: null,
		plinko: null,
		dice: null,
	});

	useEffect(() => {
		websocket
			.on("disconnect", () => {
				websocket.connect();
			})
			.on("Login.Error", (data) => {
				console.error("Websocket Error");
			})
			.on("Dashboard.Get", (data) => {
				setWebsocketState((_state) => ({
					..._state,
					dashData: data,
				}));
			})
			.on("Statistics.Get", (data) => {
				setWebsocketState((_state) => ({
					..._state,
					statistics: data,
				}));
			})
			.on("Game.Double.Get", (data) => {
				setWebsocketState((_state) => ({
					..._state,
					double: data,
				}));
			})
			.on("Game.Doublepro.Get", (data) => {
				setWebsocketState((_state) => ({
					..._state,
					doublepro: data,
				}));
			})
			.on("Game.Rocket.Get", (data) => {
				setWebsocketState((_state) => ({
					..._state,
					rocket: data,
				}));
			})
			.on("Game.Plinko.Get", (data) => {
				setWebsocketState((_state) => ({
					..._state,
					plinko: data,
				}));
			})
			.on("Game.Mines.Get", (data) => {
				setWebsocketState((_state) => ({
					..._state,
					mines: data,
				}));
			})
			.on("Game.Dice.Get", (data) => {
				setWebsocketState((_state) => ({
					..._state,
					dice: data,
				}));
			});

		return () => {
			socket
				.off("Login.Error")
				.off("Dashboard.Get")
				.off("Statistics.Get")
				.off("Game.Double.Get")
				.off("Game.Doublepro.Get")
				.off("Game.Rocket.Get")
				.off("Game.Plinko.Get")
				.off("Game.Mines.Get")
				.off("Game.Dice.Get");
		};
	}, [logout, websocket]);

	const start = (token: string) => {
		if (websocket) {
			websocket.connect();

			socket.emit("User.Login", {
				token,
			});
		}
	};

	const finish = () => {
		if (websocket) {
			websocket.disconnect();
		}
	};

	return (
		<WebSocketContext.Provider
			value={{
				...websocketState,
				websocket,
				setWebsocketState,

				//
				finish,
				start,
			}}>
			{children}
		</WebSocketContext.Provider>
	);
}

export { WebSocketContext, WebSocketProvider };
