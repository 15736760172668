import { serverUrl } from 'src/config';
import axios from 'axios';

const axiosInstance = axios.create({
	baseURL: serverUrl,
});

axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => (error.response && error.response.data) || 'Something went wrong'
);

export default axiosInstance;
