import { AccountTransactionsTableRowType } from "./AccountTransactionsTable";
import { dateLocale, dateOptions } from "../../_constants";
import { DefaultTableRowProps } from "../../_types";
import Iconify from "src/components/iconify";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

export default function AccountTransactionsTableRow({ isItemSelected, row, labelId, handleSelect }: DefaultTableRowProps<AccountTransactionsTableRowType>) {
	return (
		<TableRow hover tabIndex={-1}>
			<TableCell align="left" id={labelId}>
				{new Date(row.date).toLocaleString(dateLocale, dateOptions)}
			</TableCell>
			<TableCell align="left">{row.id}</TableCell>
			<TableCell align="left">{row.type}</TableCell>
			<TableCell align="left">
				{row.amount.toLocaleString("pt-BR", {
					currency: "BRL",
					style: "currency",
					maximumFractionDigits: 2,
					minimumFractionDigits: 2,
				})}
			</TableCell>
			<TableCell align="left">
				{row.initialBalance.toLocaleString("pt-BR", {
					currency: "BRL",
					style: "currency",
					maximumFractionDigits: 2,
					minimumFractionDigits: 2,
				})}
			</TableCell>
			<TableCell align="left">
				{row.finalBalance.toLocaleString("pt-BR", {
					currency: "BRL",
					style: "currency",
					maximumFractionDigits: 2,
					minimumFractionDigits: 2,
				})}
			</TableCell>
			<TableCell align="left">
				<Typography color={`${row.finalBalance - row.initialBalance > 0 ? "success" : "error"}.main`}>
					{(row.finalBalance - row.initialBalance).toLocaleString("pt-BR", {
						currency: "BRL",
						style: "currency",
						maximumFractionDigits: 2,
						minimumFractionDigits: 2,
					})}
				</Typography>
			</TableCell>
			<TableCell align="left">
				<Iconify icon={`iconoir:${(row.finalResult ? "check" : "delete") + "-circle"}`} color={`${row.finalResult ? "success" : "error"}.main`} />
			</TableCell>
		</TableRow>
	);
}
