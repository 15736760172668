import { AccountData } from "src/@types/websocket";
import { DefaultTableConfig } from "src/sections/app/_types";
import { Divider, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { fCpf } from "src/utils/formatNumber";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import AccountDepositsTable from "src/sections/app/accounts/details/AccountDepositsTable";
import AccountTransactionsTable, { AccountTransactionsTableRowType } from "src/sections/app/accounts/details/AccountTransactionsTable";
import AccountWithdrawsTable from "src/sections/app/accounts/details/AccountWithdrawsTable";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs";
import EmptyContent from "src/components/empty-content";
import Grid from "@mui/material/Grid";
import Loading from "src/components/Loading";
import Page from "src/components/Page";
import TextEditable from "src/components/TextEditable";
import useAuth from "src/hooks/useAuth";
import useWebSocket from "src/hooks/useWebSocket";

export default function AccountDetailsPage() {
	const navigate = useNavigate();
	const params = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const { user } = useAuth();
	const { websocket } = useWebSocket();
	const [accountInfo, setAccountInfo] = useState<null | AccountData>(null);
	const [tableConfig, setTableConfig] = useState<DefaultTableConfig<AccountTransactionsTableRowType>>({
		limit: 10,
		order: "desc",
		orderBy: "date",
		page: 0,
	});
	const [otherTrnasactionsTableConfig, setOtherTrnasactionsTableConfig] = useState<DefaultTableConfig<AccountTransactionsTableRowType>>({
		limit: 10,
		order: "desc",
		orderBy: "date",
		page: 0,
	});
	const [requestingTable, setRequestingTable] = useState(false);
	const [currentAction, setCurrentAction] = useState<"transactions" | "otherTransactions" | "deposits" | "withdraws">("transactions");

	const getAccountTransactions = useCallback(
		(tableConfig: DefaultTableConfig<AccountTransactionsTableRowType>) => {
			setTableConfig(tableConfig);

			setAccountInfo((_oldInfo) => (_oldInfo ? { ..._oldInfo, transactions: [] } : null));

			websocket.emit("Account.Get.Transactions", {
				id: params.id,
				config: tableConfig,
			});
		},
		[params.id, websocket]
	);

	const getAccountOtherTransactions = useCallback(
		(tableConfig: DefaultTableConfig<AccountTransactionsTableRowType>) => {
			setOtherTrnasactionsTableConfig(tableConfig);

			setAccountInfo((_oldInfo) => (_oldInfo ? { ..._oldInfo, transactions: [] } : null));

			websocket.emit("Account.Get.Transactions.Other", {
				id: params.id,
				config: tableConfig,
			});
		},
		[params.id, websocket]
	);

	useEffect(() => {
		if (!params.id || params.id.length === 0) return navigate("/app/accounts");

		if (!requestingTable && !accountInfo) {
			websocket.emit("Account.Get", {
				id: params.id,
				config: tableConfig,
			});
		}
	}, [accountInfo, navigate, params.id, requestingTable, tableConfig, websocket]);

	useEffect(() => {
		websocket
			.on("Account.Get", (data) => {
				setAccountInfo(data.data);
			})
			.on("Account.Get.Transactions", (data) => {
				setAccountInfo((_oldInfo) =>
					_oldInfo
						? {
								..._oldInfo,
								transactions: data.data.transactions,
								transactionsCount: data.data.count,
								realTransactionsCount: data.data.realTransactionsCount,
								bonusTransactionsCount: data.data.bonusTransactionsCount,
						  }
						: null
				);
				setRequestingTable(false);
			})
			.on("Account.Get.Transactions.Other", (data) => {
				setAccountInfo((_oldInfo) =>
					_oldInfo
						? {
								..._oldInfo,
								otherTransactions: data.data.transactions,
								otherTransactionsCount: data.data.count,
						  }
						: null
				);
				setRequestingTable(false);
			})
			.on("Management.Deposit.Action", (data) => {
				if (data.success) {
					const { depositId, approved } = data;
					enqueueSnackbar(`Depósito ${approved ? "aprovado" : "rejeitado"} com sucesso!`);
					setAccountInfo((_oldInfo) =>
						_oldInfo
							? {
									..._oldInfo,
									deposits: [
										..._oldInfo.deposits.filter((deposit) => deposit.id !== depositId),
										{
											..._oldInfo.deposits.filter((deposit) => deposit.id === depositId)[0],
											status: approved ? "confirmed" : "pending",
										},
									],
							  }
							: null
					);
				}
			});
	}, [enqueueSnackbar, websocket]);

	const onChangeTableConfig = (data: { type: keyof DefaultTableConfig<AccountTransactionsTableRowType>; data: any }[]) => {
		const newData = data.reduce((prev, curr) => ({ ...prev, [curr.type]: curr.data }), {});

		setRequestingTable(true);
		getAccountTransactions({
			...tableConfig,
			...newData,
		});
	};

	const onChangeOtherTransactionsTableConfig = (data: { type: keyof DefaultTableConfig<AccountTransactionsTableRowType>; data: any }[]) => {
		const newData = data.reduce((prev, curr) => ({ ...prev, [curr.type]: curr.data }), {});

		setRequestingTable(true);
		getAccountOtherTransactions({
			...tableConfig,
			...newData,
		});
	};

	if (!accountInfo || !user) return <Loading loadingText="Carregando usuário" />;

	const editAccount = (newData: Partial<AccountData>) => {
		setAccountInfo((_oldData) => (_oldData ? { ..._oldData, ...newData } : null));
		if (user.roles.includes("admin")) {
			if (accountInfo) {
				const newAccountData = {
					id: accountInfo.id,
					username: accountInfo.username,
					email: accountInfo.email,
					balance: accountInfo.balance,
					bonusBalance: accountInfo.bonusBalance,
					document: accountInfo.document,
					password: accountInfo.password,
					phoneNumber: accountInfo.phoneNumber,
					...{ ...newData },
				};

				websocket.emit("Account.Save", newAccountData);
			}
		}
	};

	return (
		<Page title="Gerenciamento: Usuários">
			<Container maxWidth="xl">
				<CustomBreadcrumbs heading={`Usuário "${accountInfo.email}"`} links={[{ name: `Atualizado em ${new Date().toLocaleString("pt-BR")}` }]} />
				<Grid container spacing={3}>
					<Grid item xs={0} md={2} />
					<Grid item xs={12} md={8}>
						<Card sx={{ p: 3 }}>
							<Grid container spacing={3}>
								<Grid item xs={12} md={4} order={{ xs: 2, md: 1 }}>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: {
												xs: "center",
												md: "start",
											},
											textAlign: {
												xs: "center",
												md: "start",
											},
											height: "100%",
											width: "100%",
										}}>
										<div>
											<Typography variant="h3" sx={{ color: "primary.main" }}>
												{accountInfo.balance.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
											</Typography>
											<Typography variant="subtitle2" sx={{ color: "text.secondary", mt: -0.5 }}>
												Total de saldo
											</Typography>

											<Divider sx={{ mt: 1, mb: 1.5 }} />

											<Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
												Saldo Bônus:{" "}
												<Typography variant="subtitle2" sx={{ color: "primary.light" }} component="span">
													{accountInfo.bonusBalance.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
												</Typography>
											</Typography>
										</div>
									</Box>
								</Grid>
								<Grid item xs={12} md={4} order={{ xs: 1, md: 2 }}>
									<Avatar sx={{ width: 148, height: 148, mx: "auto" }} />
									<Box sx={{ mt: 3 }}>
										<TextEditable
											text={accountInfo.username}
											value={accountInfo.username}
											isCenter={true}
											textTypographyProps={{ variant: "h4" }}
											textFieldProps={{
												placeholder: "Novo apelido",
												inputProps: {
													sx: {
														textAlign: "center",
														fontSize: {
															xs: "1.25rem",
															lg: "1.5rem",
														},
														fontWeight: "700",
													},
												},
												InputProps: {
													inputProps: {
														maxLength: 32,
													},
												},
											}}
											onEdit={(value: string) => {
												editAccount({ username: value });
											}}
											disabled={!user.roles.includes("admin")}
										/>
										<TextEditable
											text={fCpf(accountInfo.document)}
											value={accountInfo.document}
											isCenter={true}
											textTypographyProps={{ variant: "subtitle1", color: "text.secondary" }}
											textFieldProps={{
												placeholder: "Novo CPF",
												inputProps: {
													sx: {
														textAlign: "center",
														fontSize: "1rem",
														fontWeight: "600",
														color: "#919EAB",
													},
												},
											}}
											onEdit={(value: string) => {
												editAccount({ document: value });
											}}
											sx={{ mt: -1 }}
											disabled={!user.roles.includes("admin")}
										/>
										<TextEditable
											text={accountInfo.email}
											value={accountInfo.email}
											isCenter={true}
											textTypographyProps={{ variant: "subtitle2", color: "text.secondary" }}
											textFieldProps={{
												placeholder: "Novo Email",
												type: "email",
												inputProps: {
													sx: {
														textAlign: "center",
														fontSize: "0.875rem",
														fontWeight: "600",
														color: "#919EAB",
													},
												},
											}}
											onEdit={(value: string) => {
												editAccount({ email: value });
											}}
											sx={{ mt: -1 }}
											disabled={!user.roles.includes("admin")}
										/>
										<TextEditable
											text={new Date(accountInfo.birthDate).toLocaleDateString("pt-BR")}
											value={accountInfo.birthDate}
											isCenter={true}
											textTypographyProps={{ variant: "subtitle2", color: "text.secondary" }}
											textFieldProps={{
												placeholder: "01/01/2000",
												inputProps: {
													style: {
														textAlign: "center",
														fontSize: "0.875rem",
														fontWeight: "600",
														color: "#919EAB",
														marginLeft: 36,
													},
												},
											}}
											onEdit={(value: string) => {
												editAccount({ birthDate: value });
											}}
											sx={{ mt: -1 }}
											disabled={!user.roles.includes("admin")}
											isDate={true}
										/>
										<TextEditable
											text={`ID B.O.: ${accountInfo.blueOceanId}`}
											value={accountInfo.blueOceanId}
											isCenter={true}
											textTypographyProps={{ variant: "subtitle2", color: "text.secondary" }}
											textFieldProps={{
												placeholder: "ID Blue Ocean",
												type: "text",
												inputProps: {
													sx: {
														textAlign: "center",
														fontSize: "0.875rem",
														fontWeight: "600",
														color: "#919EAB",
													},
												},
											}}
											onEdit={(value: string) => {
												editAccount({ email: value });
											}}
											sx={{ mt: -1 }}
											disabled={true}
										/>
									</Box>
								</Grid>
								<Grid
									item
									xs={12}
									md={4}
									order={{
										xs: 3,
									}}>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: {
												xs: "center",
												md: "end",
											},
											textAlign: {
												xs: "center",
												md: "end",
											},
											height: "100%",
											width: "100%",
										}}>
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												justifyContent: "space-between",
												alignItems: "center",
												height: "100%",
											}}>
											<div>
												<Box sx={{ color: "success.main" }}>
													<Typography variant="h3">
														{accountInfo.deposits
															.reduce((prev, curr) => prev + (curr.status === "confirmed" ? curr.amount : 0), 0)
															.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
													</Typography>
													<Typography variant="subtitle2" sx={{ color: "text.secondary", mt: -0.5 }}>
														Total depositado
													</Typography>

													<Divider sx={{ mt: 1, mb: 1.5 }} />

													<Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
														Quantia Bônus:{" "}
														<Typography variant="subtitle2" sx={{ color: "success.light" }} component="span">
															{accountInfo.deposits
																.reduce((prev, curr) => prev + (curr.status === "confirmed" ? curr.amount * ((curr.bonus + 100) / 100) - curr.amount : 0), 0)
																.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
														</Typography>
													</Typography>

													<Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
														Depósitos confirmados:{" "}
														<Typography variant="subtitle2" sx={{ color: "success.light" }} component="span">
															{accountInfo.deposits.filter((deposit) => deposit.status === "confirmed").length.toLocaleString("pt-BR")}
														</Typography>
													</Typography>

													<Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
														Depósitos pendentes:{" "}
														<Typography variant="subtitle2" sx={{ color: "warning.light" }} component="span">
															{accountInfo.deposits.filter((deposit) => deposit.status === "pending").length.toLocaleString("pt-BR")}
														</Typography>
													</Typography>
												</Box>

												<Box sx={{ my: 3 }} />

												<Box sx={{ color: "error.main" }}>
													<Typography variant="h3">
														{accountInfo.withdraws
															.reduce((prev, curr) => prev + (curr.status === "approved" ? curr.amount : 0), 0)
															.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
													</Typography>
													<Typography variant="subtitle2" sx={{ color: "text.secondary", mt: -0.5 }}>
														Total sacado
													</Typography>

													<Divider sx={{ mt: 1, mb: 1.5 }} />

													<Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
														Saques confirmados:{" "}
														<Typography variant="subtitle2" sx={{ color: "error.light" }} component="span">
															{accountInfo.withdraws.filter((withdraw) => withdraw.status === "approved").length.toLocaleString("pt-BR")}
														</Typography>
													</Typography>

													<Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
														Saques pendentes:{" "}
														<Typography variant="subtitle2" sx={{ color: "warning.light" }} component="span">
															{accountInfo.withdraws.filter((withdraw) => withdraw.status === "pending").length.toLocaleString("pt-BR")}
														</Typography>
													</Typography>
												</Box>
											</div>
										</Box>
									</Box>
								</Grid>
							</Grid>
						</Card>
					</Grid>

					<Grid item md={6} xs={12}>
						<Card sx={{ px: 2, py: 3 }}>
							<Typography variant="h5" sx={{ textAlign: "center" }} component="h1">
								Jogos jogados com saldo real
							</Typography>
							<Divider sx={{ my: 3 }} />
							<Box sx={{ display: "flex", flexWrap: "wrap", gap: 4, justifyContent: "space-around" }}>
								{Object.keys(accountInfo.games).filter((key) => accountInfo.games[key].count > 0).length > 0 ? (
									Object.keys(accountInfo.games)
										.filter((key) => accountInfo.games[key].count > 0)
										.map((key) => {
											const gameInfo = accountInfo.games[key];
											const firstDepositDate = accountInfo.deposits
												.filter((deposit) => deposit.status === "confirmed")
												.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())[0].createdAt;
											let daysPlaying = gameInfo.lastGameDate
												? `${Math.floor((Date.now() - new Date(gameInfo.lastGameDate).getTime()) / 1000 / 60 / 60 / 24)} dias sem jogar`
												: "Nunca jogou";
											const playingAfterDeposit =
												gameInfo.lastGameDate && firstDepositDate
													? `${Math.floor((new Date(gameInfo.lastGameDate).getTime() - new Date(firstDepositDate).getTime()) / 1000 / 60 / 60 / 24)} dias jogando após depósito`
													: "Nunca jogou";

											return (
												<Box key={`played-game-${key}`}>
													<Typography variant="subtitle1" color="text.primary" sx={{ textAlign: "center" }}>
														{key.charAt(0).toUpperCase() + key.slice(1)}
													</Typography>
													<Typography variant="caption" color="text.secondary">
														{daysPlaying}
													</Typography>
													<br />
													<Typography variant="caption" color="text.secondary">
														{playingAfterDeposit}
													</Typography>
													<br />
													<Typography variant="caption" color="text.secondary">
														{gameInfo.count} apostas
													</Typography>
													<Divider sx={{ my: 1 }} />
													<Box sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}>
														<Typography variant="subtitle2" color="text.secondary">
															Apostou:
														</Typography>
														<Typography color="warning.main" variant="subtitle2">
															{gameInfo.betAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
														</Typography>
													</Box>
													<Box sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}>
														<Typography variant="subtitle2" color="text.secondary">
															Ganhou:
														</Typography>
														<Typography color="success.main" variant="subtitle2">
															{gameInfo.gainAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
														</Typography>
													</Box>
													<Box sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}>
														<Typography variant="subtitle2" color="text.secondary">
															Perdeu:
														</Typography>
														<Typography color="error.main" variant="subtitle2">
															{gameInfo.lossAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
														</Typography>
													</Box>
													<Divider sx={{ my: 1 }} />
													<Box sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}>
														<Typography variant="subtitle2" color="text.secondary">
															Lucro:
														</Typography>
														<Typography color={(gameInfo.gainAmount - gameInfo.lossAmount > 0 ? "success" : "error") + ".main"} variant="subtitle2">
															{(gameInfo.gainAmount - gameInfo.lossAmount).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
														</Typography>
													</Box>
												</Box>
											);
										})
								) : (
									<EmptyContent title={"Nenhum jogo"} sx={{ maxHeight: 360 }} />
								)}
							</Box>
						</Card>
					</Grid>
					<Grid item md={6} xs={12}>
						<Card sx={{ px: 2, py: 3 }}>
							<Typography variant="h5" sx={{ textAlign: "center" }} component="h1">
								Jogos jogados com saldo bônus
							</Typography>
							<Divider sx={{ my: 3 }} />
							<Box sx={{ display: "flex", flexWrap: "wrap", gap: 4, justifyContent: "space-around" }}>
								{Object.keys(accountInfo.games).filter((key) => accountInfo.games[key].bonusCount > 0).length > 0 ? (
									Object.keys(accountInfo.games)
										.filter((key) => accountInfo.games[key].bonusCount > 0)
										.map((key) => {
											const gameInfo = accountInfo.games[key];
											const firstDepositDate = accountInfo.deposits
												.filter((deposit) => deposit.status === "confirmed")
												.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())[0].createdAt;
											let daysPlaying = gameInfo.lastGameDate
												? `${Math.floor((Date.now() - new Date(gameInfo.lastGameDate).getTime()) / 1000 / 60 / 60 / 24)} dias sem jogar`
												: "Nunca jogou";
											const playingAfterDeposit =
												gameInfo.lastGameDate && firstDepositDate
													? `${Math.floor((new Date(gameInfo.lastGameDate).getTime() - new Date(firstDepositDate).getTime()) / 1000 / 60 / 60 / 24)} dias jogando após depósito`
													: "Nunca jogou";

											return (
												<Box key={`played-game-bonus-${key}`}>
													<Typography variant="subtitle1" color="text.primary" sx={{ textAlign: "center" }}>
														{key.charAt(0).toUpperCase() + key.slice(1)}
													</Typography>
													<Typography variant="caption" color="text.secondary">
														{daysPlaying}
													</Typography>
													<br />
													<Typography variant="caption" color="text.secondary">
														{playingAfterDeposit}
													</Typography>
													<br />
													<Typography variant="caption" color="text.secondary">
														{gameInfo.bonusCount} apostas
													</Typography>
													<Divider sx={{ my: 1 }} />
													<Box sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}>
														<Typography variant="subtitle2" color="text.secondary">
															Apostou:
														</Typography>
														<Typography color="warning.main" variant="subtitle2">
															{gameInfo.betBonusAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
														</Typography>
													</Box>
													<Box sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}>
														<Typography variant="subtitle2" color="text.secondary">
															Ganhou:
														</Typography>
														<Typography color="success.main" variant="subtitle2">
															{gameInfo.gainBonusAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
														</Typography>
													</Box>
													<Box sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}>
														<Typography variant="subtitle2" color="text.secondary">
															Perdeu:
														</Typography>
														<Typography color="error.main" variant="subtitle2">
															{gameInfo.lossBonusAmount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
														</Typography>
													</Box>
													<Divider sx={{ my: 1 }} />
													<Box sx={{ display: "flex", justifyContent: "space-between", gap: 3 }}>
														<Typography variant="subtitle2" color="text.secondary">
															Lucro:
														</Typography>
														<Typography color={(gameInfo.gainBonusAmount - gameInfo.lossBonusAmount > 0 ? "success" : "error") + ".main"} variant="subtitle2">
															{(gameInfo.gainBonusAmount - gameInfo.lossBonusAmount).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
														</Typography>
													</Box>
												</Box>
											);
										})
								) : (
									<EmptyContent title={"Nenhum jogo"} sx={{ maxHeight: 360 }} />
								)}
							</Box>
						</Card>
					</Grid>
					{/* <Grid item xs={12} md={5}>
						<AccountBalance
							list={[
								{
									id: "sembonus",
									type: "real",
									balance: accountInfo.balance,
									transactionsCount: accountInfo.realTransactionsCount,
									lastTransactionDate: "00/00",
									editDisable: !user.roles.includes("admin"),
								},
								{
									id: "bonus",
									type: "bônus",
									balance: accountInfo.bonusBalance,
									transactionsCount: accountInfo.bonusTransactionsCount,
									lastTransactionDate: "00/00",
									editDisable: !user.roles.includes("admin"),
								},
							]}
						/>
					</Grid> */}
					<Grid item xs={12} md={12}>
						<Divider />
					</Grid>
					<Grid item xs={12} md={12}>
						<Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
							<ToggleButtonGroup
								color="primary"
								value={currentAction}
								exclusive
								onChange={(event, newValue) => setCurrentAction(newValue || currentAction)}
								aria-label="Saques ou depósitos">
								<ToggleButton value={"transactions"}>Transações</ToggleButton>
								<ToggleButton value={"otherTransactions"}>Outras transações</ToggleButton>
								<ToggleButton value={"deposits"}>Depósitos</ToggleButton>
								<ToggleButton value={"withdraws"}>Saques</ToggleButton>
							</ToggleButtonGroup>
						</Box>
						{currentAction === "transactions" ? (
							requestingTable ? (
								<Loading loadingText="Carregando transações" />
							) : (
								<AccountTransactionsTable
									rows={accountInfo.transactions}
									tableConfig={tableConfig}
									itemCount={accountInfo.transactionsCount}
									onChangeTableConfig={onChangeTableConfig}
									dense={true}
								/>
							)
						) : currentAction === "otherTransactions" ? (
							requestingTable ? (
								<Loading loadingText="Carregando transações" />
							) : (
								<AccountTransactionsTable
									rows={accountInfo.otherTransactions}
									tableConfig={otherTrnasactionsTableConfig}
									itemCount={accountInfo.otherTransactionsCount}
									onChangeTableConfig={onChangeOtherTransactionsTableConfig}
									dense={true}
								/>
							)
						) : currentAction === "deposits" ? (
							<AccountDepositsTable
								rows={accountInfo.deposits}
								tableConfig={{
									order: "asc",
									orderBy: "createdAt",
									page: 0,
									limit: 25,
								}}
								dense={false}
							/>
						) : (
							<AccountWithdrawsTable
								rows={accountInfo.withdraws}
								tableConfig={{
									order: "asc",
									orderBy: "createdAt",
									page: 0,
									limit: 25,
								}}
								dense={false}
							/>
						)}
					</Grid>
				</Grid>
			</Container>
		</Page>
	);
}
