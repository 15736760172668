import { useContext } from 'react';
import { WebSocketContext } from 'src/contexts/WebSocketContext';

const useWebSocket = () => {
	const context = useContext(WebSocketContext);

	if (!context)
		throw new Error(
			'WebSocket context must be use inside WebSocketProvider'
		);

	return context;
};

export default useWebSocket;
