import { emptyRows, getComparator, stableSort } from "../../../../utils/tableUtils";
import { HeadCell, StaticDefaultTableProps, TableOrder } from "../../_types";
import { TableEmptyRows, TableHeadCustom, TableNoData } from "src/components/table";
import { useState, ChangeEvent } from "react";
import AffiliatedSubAffiliatesTableRow from "./AffiliatedSubAffiliatesTableRow";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

export interface AffiliatedSubAffiliatesTableRowType {
	id: string;
	cpa: number;
	revenueShare: number;
	createdAt: string;
	email: string;
}

const affiliatedSubAffiliatesHeadCells: HeadCell<AffiliatedSubAffiliatesTableRowType>[] = [
	{
		id: "id",
		disablePadding: false,
		label: "ID",
	},
	{
		id: "email",
		disablePadding: false,
		label: "E-Mail",
	},
	{
		id: "cpa",
		disablePadding: false,
		label: "CPA",
	},
	{
		id: "revenueShare",
		disablePadding: false,
		label: "Revenue Share",
	},
	{
		id: "createdAt",
		disablePadding: false,
		label: "Adicionado",
	},
];

export default function AffiliatedSubAffiliatesTable({ rows, tableConfig, dense: dense_ = false }: StaticDefaultTableProps<AffiliatedSubAffiliatesTableRowType>) {
	const [dense, setDense] = useState(dense_);
	const [order, setOrder] = useState<TableOrder>(tableConfig.order);
	const [orderBy, setOrderBy] = useState<keyof AffiliatedSubAffiliatesTableRowType>(tableConfig.orderBy);
	const [page, setPage] = useState(tableConfig.page);
	const [rowCount] = useState(rows.length);
	const [rowsPerPage, setRowsPerPage] = useState(tableConfig.limit);
	const [tableData] = useState<AffiliatedSubAffiliatesTableRowType[]>(rows);

	const handleRequestSort = (id: keyof AffiliatedSubAffiliatesTableRowType) => {
		const order_ = orderBy === id && order === "asc";
		setOrder(order_ ? "desc" : "asc");
		setOrderBy(id);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(Number(event.target.value));
		setPage(0);
	};

	const handleChangeDense = (event: ChangeEvent<HTMLInputElement>) => {
		setDense(event.target.checked);
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<Toolbar
					sx={{
						pl: { sm: 2 },
						pr: { xs: 1 },
					}}>
					<Typography sx={{ flex: "1 1 100%" }} variant="h6" id="tableTitle" component="div">
						Sub-Afiliados
					</Typography>
				</Toolbar>
				<TableContainer>
					<Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
						<TableHeadCustom<AffiliatedSubAffiliatesTableRowType>
							headLabel={affiliatedSubAffiliatesHeadCells}
							order={order}
							orderBy={orderBy}
							onSort={handleRequestSort}
							rowCount={tableData.length}
							extra={true}
						/>
						<TableBody>
							{stableSort(tableData, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => (
									<AffiliatedSubAffiliatesTableRow row={row} key={row.id} labelId={`affiliatedSubAffiliates-table-checkbox-${index}`} searchFilter={""} />
								))}

							<TableEmptyRows height={dense ? 56 : 76} emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />
							<TableNoData isNotFound={!tableData.length} />
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
					component="div"
					count={rowCount}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage={"Sub-Afiliados por página"}
					getItemAriaLabel={function defaultGetAriaLabel(type) {
						return `Ir para a ${type === "next" ? "próxima página" : type === "first" ? "primeira página" : type === "last" ? "última página" : "pagina anterior"} `;
					}}
					showFirstButton={page !== 0 && rowCount > rowsPerPage * page}
					showLastButton={page !== Math.ceil(rowCount / rowsPerPage) - 1 && rowCount > rowsPerPage * page}
				/>
			</Paper>
			<FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Modo minimalista" />
		</Box>
	);
}
