import { AccountWithdrawsTableRowType } from "./AccountWithdrawsTable";
import { dateLocale, dateOptions } from "../../_constants";
import { DefaultTableRowProps } from "../../_types";
import Box from "@mui/material/Box";
import Iconify from "src/components/iconify";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function AccountWithdrawsTableRow({ isItemSelected, row, labelId, handleSelect }: DefaultTableRowProps<AccountWithdrawsTableRowType>) {
	return (
		<TableRow hover tabIndex={-1}>
			<TableCell align="left" id={labelId}>
				<Box sx={{ display: "flex", alignItems: "center", alignContent: "center", gap: 1 }}>
					<Iconify
						icon={`iconoir:${row.status === "pending" ? "clock" : (row.status === "approved" ? "check" : "delete") + "-circle"}`}
						color={`${row.status === "pending" ? "warning" : row.status === "approved" ? "success" : "error"}.main`}
					/>
					{row.status === "pending" ? "Pendente" : row.status === "approved" ? "Aprovado" : row.status === "canceled" ? "Cancelado" : "Recusado"}
				</Box>
			</TableCell>
			<TableCell align="left">{row.id}</TableCell>
			<TableCell align="left">{new Date(row.createdAt).toLocaleString(dateLocale, dateOptions)}</TableCell>
			<TableCell align="left">{row.amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</TableCell>
		</TableRow>
	);
}
