import { Fragment } from "react";
import { Account, Roles } from "src/@types/auth";
import { alpha, useTheme } from "@mui/material/styles";
import { Checkbox, IconButton } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DefaultTableConfig } from "src/sections/app/_types";
import { fCpf, fMinMax } from "src/utils/formatNumber";
import { FormControlLabel, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { websiteConfig } from "src/config";
import AffiliatedCpaTable from "src/sections/app/affiliated/details/AffiliatedCpaTable";
import AffiliatedDepositStatusChart from "src/sections/app/affiliated/AffiliatedDepositStatusChart";
import AffiliatedPaymentsTable, { AffiliatedPaymentsTableRowType } from "src/sections/app/affiliated/details/AffiliatedPaymentsTable";
import AffiliatedSubAffiliatesTable from "src/sections/app/affiliated/details/AffiliatedSubAffiliatesTable";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs";
import Divider from "@mui/material/Divider";
import EmptyContent from "src/components/empty-content";
import FirstInteractionsGraph from "src/sections/FirstInteractionsGraph";
import Grid from "@mui/material/Grid";
import Iconify from "src/components/iconify";
import Link from "@mui/material/Link";
import Loading from "src/components/Loading";
import Modal from "src/components/Modal";
import Page from "src/components/Page";
import Stack from "@mui/material/Stack";
import TextEditable from "src/components/TextEditable";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import useAuth from "src/hooks/useAuth";
import useResponsive from "src/hooks/useResponsive";
import useWebSocket from "src/hooks/useWebSocket";
import WidgetSummary from "src/sections/app/WidgetSummary";

type AffiliateInfo = {
	info: Account;
	affiliatedPeople: AffiliatedPeople[];
	subAffiliates?: { id: string; cpa: number; revenueShare: number; createdAt: string; email: string }[];
};

type AffiliatedPeople = {
	_id: string;
	id: string;
	createdAt: string;
	balance: number;
	bonusBalance: number;
	deposits: DepositsEntity[];
	withdraws: WithdrawsEntity[];
	games: Games;
};

export interface DepositsEntity {
	createdAt: string;
	amount: number;
	status: string;
	bonus: number;
}

export interface WithdrawsEntity {
	createdAt: string;
	amount: number;
	status: string;
}

export interface Games {
	dices?: number;
	mines?: number;
	plinkos?: number;
	rockets?: number;
	doubles?: number;
	doublepro?: number;
}

type NewSubAffiliateData = { cpaActive: boolean; email: string; cpaValue: number; revenueShare: number };

const getChartData = (
	deposits: { amount: number; status: string }[]
): {
	total: number;
	chartData: { label: string; value: number }[];
} => {
	const data: { [key: string]: number } = {};

	for (const deposit of deposits) {
		if (!data[deposit.status]) data[deposit.status] = 0;

		data[deposit.status] += 1;
	}

	let newData = [];

	for (const key of Object.keys(data)) {
		newData.push({
			label: key,
			value: Number(((data[key] * 100) / deposits.length).toFixed(0)),
		});
	}

	return {
		total: deposits.length,
		chartData: newData,
	};
};

const getAreaChartData = (
	affiliatedPeople: AffiliatedPeople[],
	after: number,
	before: number
): {
	name: string;
	type: string;
	fill: string;
	data: number[];
}[] => {
	const dataArray = new Array(Number(Math.abs((before - after) / 24 / 60 / 60 / 1000).toFixed(0))).fill(0);
	const newData: { name: string; type: string; fill: string; data: number[] }[] = [
		{
			name: "Cadastros",
			type: "bar",
			fill: "gradient",
			data: [...dataArray],
		},
		{
			name: "Primeiros depósitos",
			type: "bar",
			fill: "gradient",
			data: [...dataArray],
		},
	];

	for (const people of affiliatedPeople) {
		const accountDate = new Date(people.createdAt).getTime();
		if (accountDate >= after - 1000 * 60 * 60 * 24 && accountDate <= before) {
			const diffTime = Math.abs(before - accountDate);
			const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
			newData[0].data[diffDays - 1] += 1;
		}

		const deposit = people.deposits.filter((deposit) => deposit.status === "confirmed")[0];

		if (deposit) {
			const depositDate = new Date(deposit.createdAt).getTime();

			if (depositDate >= after - 1000 * 60 * 60 * 24 && depositDate <= before) {
				const diffTime = Math.abs(before - depositDate);
				const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
				newData[1].data[diffDays - 1] += 1;
			}
		}
	}

	return newData;
};

export default function AffiliatedDetailsPage() {
	const theme = useTheme();
	const navigate = useNavigate();
	const params = useParams();
	const location = useLocation();
	const isDesktop = useResponsive("up", "lg");
	const { enqueueSnackbar } = useSnackbar();
	const { user } = useAuth();
	const { websocket } = useWebSocket();
	const [affiliate, setAffiliate] = useState<AffiliateInfo | null>(null);
	const [creatingSubAffiliate, setCreatingSubAffiliate] = useState(false);
	const [newSubAffiliateData, setNewSubAffiliateData] = useState<NewSubAffiliateData>({
		cpaActive: false,
		email: "",
		cpaValue: 0,
		revenueShare: 30,
	});
	const [tableConfig, setTableConfig] = useState<DefaultTableConfig<AffiliatedPaymentsTableRowType>>({
		limit: 5,
		order: "desc",
		orderBy: "date",
		page: 0,
	});
	const [currentAction, setCurrentAction] = useState<"details" | "deposits" | "withdraws" | "payments" | "cpa" | "subAffiliates">("details");
	const [detailsConfig, setDetailsConfig] = useState({
		after: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0).getTime() - 1000 * 60 * 60 * 24 * 6,
		before: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 999).getTime(),
	});
	const canEditAffiliate = (["super-admin", "admin", "affiliates.manage"] as Roles[]).some((role) => user?.roles.includes(role));
	const isSubAffiliate = location.pathname.includes("sub");

	useEffect(() => {
		if (!params.id || params.id.length === 0) return navigate("/app/affiliates");

		if (!affiliate) {
			websocket.emit(`Affiliate${location.pathname.includes("sub") ? ".Sub" : ""}.Get`, {
				id: params.id,
				after: detailsConfig.after,
				before: detailsConfig.before,
			});
		}

		websocket
			.on("Affiliate.Get", (data) => {
				setAffiliate(data);
			})
			.on("Affiliate.Sub.Create", (data) => {
				if (data.error) {
					enqueueSnackbar("Não foi possível criar o sub-afiliado.", {
						variant: "error",
					});
				} else {
					setCreatingSubAffiliate(false);
					setAffiliate((_oldData) => (_oldData ? { ..._oldData, subAffiliates: [...(_oldData.subAffiliates || []), data.data] } : null));
					enqueueSnackbar("Sub-afiliado criado com sucesso.");
				}
			})
			.on("Affiliate.Sub.Get", (data) => {
				setCurrentAction("deposits");
				setAffiliate(data);
			})
			.on("Affiliate.Pay", (data) => {
				if (data.success) {
					enqueueSnackbar("Afiliado pago com sucesso!");
					setAffiliate((_oldData) => {
						if (!_oldData) return null;

						_oldData.info[`${data.type as "revenueShare" | "cpa"}Logs`] = data.logs;

						return _oldData;
					});

					return;
				}
				enqueueSnackbar("Ocorreu algum erro ao pagar o afiliado.");
			});

		window.addEventListener("click", (event) => {
			const target = event.target as any;
			if (target.name === "sub-affiliate-open") {
				setAffiliate(null);
			}
		});
	}, [affiliate, navigate, params.id, websocket, location, detailsConfig.after, detailsConfig.before, enqueueSnackbar]);

	const onChangeTableConfig = (
		data: {
			type: keyof DefaultTableConfig<AffiliatedPaymentsTableRowType>;
			data: any;
		}[]
	) => {
		const newData = data.reduce((prev, curr) => ({ ...prev, [curr.type]: curr.data }), {});

		setTableConfig({ ...tableConfig, ...newData });
	};

	if (!affiliate || !user) return <Loading loadingText={`Carregando ${isSubAffiliate ? "sub-" : ""}afiliado`} />;

	const getAffiliateProfit = () => {
		let total = 0;

		total = affiliate.affiliatedPeople.reduce(
			(prev, curr) =>
				prev +
				curr.deposits.reduce((_prev, _curr) => _prev + (_curr.status === "confirmed" ? _curr.amount : 0), 0) -
				curr.balance -
				curr.withdraws.reduce((_prev, _curr) => _prev + (_curr.status === "approved" ? _curr.amount : 0), 0),
			0
		);

		return total;
	};

	const getAffiliateIntervalProfit = () => {
		let total = 0;

		total = affiliate.affiliatedPeople.reduce(
			(prev, curr) =>
				prev +
				curr.deposits.reduce(
					(_prev, _curr) =>
						_prev +
						(_curr.status === "confirmed" && new Date(curr.createdAt).getTime() >= detailsConfig.after && new Date(curr.createdAt).getTime() <= detailsConfig.before
							? _curr.amount
							: 0),
					0
				) -
				(new Date(curr.createdAt).getTime() >= detailsConfig.after && new Date(curr.createdAt).getTime() <= detailsConfig.before ? curr.balance : 0) -
				curr.withdraws.reduce(
					(_prev, _curr) =>
						_prev +
						(_curr.status === "approved" && new Date(curr.createdAt).getTime() >= detailsConfig.after && new Date(curr.createdAt).getTime() <= detailsConfig.before
							? _curr.amount
							: 0),
					0
				),
			0
		);

		return total;
	};

	const getRevenueShare = () => {
		let lastPassed = 0;
		let total = 0;

		let after = 0;

		const updateLogs = affiliate.info.updateLogs
			.filter((log) => typeof log.newRevenueShare === "number" && typeof log.oldRevenueShare === "number")
			.sort((a, b) => b.dateTimestamp - a.dateTimestamp);

		if (updateLogs[0]) {
			after = updateLogs[0].dateTimestamp;
		}

		total = affiliate.affiliatedPeople.reduce(
			(prev, curr) =>
				prev +
				(new Date(curr.createdAt).getTime() >= after
					? curr.deposits.reduce((_prev, _curr) => _prev + (_curr.status === "confirmed" ? _curr.amount : 0), 0) -
					  curr.balance -
					  curr.withdraws.reduce((_prev, _curr) => _prev + (_curr.status === "approved" ? _curr.amount : 0), 0)
					: 0),
			0
		);

		total -= total * 0.2;
		total *= affiliate.info.revenueShare / 100;

		for (const log of affiliate.info.revenueShareLogs) {
			if (log.at > after) {
				lastPassed += Math.abs(log.amount);
			}
		}

		total -= lastPassed;

		return total;
	};

	const getRealCpa = (cpaNumber: number) => {
		let cpa = 0;

		if (cpaNumber !== 0) {
			for (const account of affiliate.affiliatedPeople) {
				const totalBetAmount = Object.values(account.games).reduce((prev, curr) => prev + curr, 0);
				if (account.deposits.reduce((_prev, _curr) => _prev + (_curr.status === "confirmed" ? _curr.amount : 0), 0) >= cpaNumber && totalBetAmount >= cpaNumber) {
					cpa += cpaNumber;
				}
			}
		}

		return cpa;
	};

	const getCurrentCpa = () => {
		let cpa = 0;
		let after = 0;

		const cpaLogs = affiliate.info.cpaLogs.sort((a, b) => b.at - a.at);
		const updateLogs = affiliate.info.updateLogs
			.filter((log) => typeof log.newCpa === "number" && typeof log.oldCpa === "number")
			.sort((a, b) => b.dateTimestamp - a.dateTimestamp);

		if (updateLogs[0]) {
			after = updateLogs[0].dateTimestamp;
		}

		after = cpaLogs.length > 0 ? (after > cpaLogs[0].at ? after : cpaLogs[0].at) : after;

		if (affiliate.info.cpa !== 0) {
			for (const account of affiliate.affiliatedPeople.filter((user) => new Date(user.createdAt).getTime() >= after)) {
				const totalBetAmount = Object.values(account.games).reduce((prev, curr) => prev + curr, 0);
				const totalDepositedAmount = account.deposits.reduce((_prev, _curr) => _prev + (_curr.status === "confirmed" ? _curr.amount : 0), 0);

				if (totalDepositedAmount >= affiliate.info.cpa && totalBetAmount + 10 >= affiliate.info.cpa) {
					cpa += affiliate.info.cpa;
				} else if (totalDepositedAmount - account.balance >= 10 && totalDepositedAmount >= affiliate.info.cpa) {
					cpa += affiliate.info.cpa;
				}
			}
		}

		return cpa;
	};

	const getCpaTable = () => {
		let tableData: {
			id: string;
			userId: string;
			amount: number;
			cpaCheck: string;
			cpaAmount: number;
			date: number;
		}[] = [];

		const allDeposits = affiliate.affiliatedPeople
			.reduce(
				(prev, curr) => (prev = [...prev, ...curr.deposits.map((deposit) => ({ ...deposit, userId: curr.id }))]),
				[] as {
					amount: number;
					userId: string;
					bonus: number;
					status: string;
					createdAt: string;
				}[]
			)
			.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
			.filter((deposit) => deposit.status === "confirmed");

		for (const deposit of allDeposits) {
			const prevData = tableData.filter((data) => data.userId === deposit.userId);
			const account = affiliate.affiliatedPeople.filter((account) => account.id === deposit.userId)[0];
			const totalBetAmount = Object.values(account.games).reduce((prev, curr) => prev + curr, 0);

			tableData.push({
				id: deposit.userId + Math.random(),
				userId: deposit.userId,
				amount: deposit.amount,
				cpaCheck:
					(deposit.amount + prevData.reduce((prev, curr) => prev + curr.amount, 0) >= affiliate.info.cpa && totalBetAmount + 10 >= affiliate.info.cpa) ||
					(prevData.reduce((prev, curr) => prev + curr.amount, 0) - account.balance >= 10 && prevData.reduce((prev, curr) => prev + curr.amount, 0) >= affiliate.info.cpa)
						? "Sim"
						: "Não",
				cpaAmount: deposit.amount + prevData.reduce((prev, curr) => prev + curr.amount, 0),
				date: new Date(deposit.createdAt).getTime(),
			});
		}

		for (const data of tableData) {
			if (data.cpaCheck === "Sim") {
				tableData = [...tableData.filter((_data) => _data.userId !== data.userId), data];
			}
		}

		return tableData;
	};

	const getFirstDepositsCount = () => {
		let count = 0;

		for (const people of affiliate.affiliatedPeople) {
			const deposit = people.deposits.filter((deposit) => deposit.status === "confirmed")[0];

			if (deposit && new Date(deposit.createdAt).getTime() >= detailsConfig.after && new Date(deposit.createdAt).getTime() <= detailsConfig.before) {
				count++;
			}
		}

		return count;
	};

	const updateAffiliate = (newData: Partial<Account>) => {
		if (canEditAffiliate) {
			setAffiliate((_oldAffiliate) => (_oldAffiliate ? { ..._oldAffiliate, info: { ..._oldAffiliate.info, ...newData } } : null));

			websocket.emit("Affiliate.Edit", {
				affiliateId: affiliate.info.id,
				cpa: Number(affiliate.info.cpa),
				demoBalance: Number(affiliate.info.demoBalance),
				revenueShare: Number(affiliate.info.revenueShare),
				...newData,
			});
		}
	};

	const payAffiliate = (type: "cpa" | "revenueShare") => {
		websocket.emit("Affiliate.Pay", {
			affiliateId: affiliate.info.id,
			amount: -(type === "cpa" ? getCurrentCpa() : getRevenueShare()),
			type,
		});
	};

	const deleteAffiliate = () => {
		websocket.emit("Affiliate.Delete", { id: affiliate.info.id });
		navigate(-1);
	};

	/**
	 * Sub-Affiliate
	 */

	const createSubAffiliate = () => {
		setCreatingSubAffiliate(true);

		websocket.emit("Affiliate.Sub.Create", {
			email: newSubAffiliateData.email,
			cpa: newSubAffiliateData.cpaValue,
			revenueShare: newSubAffiliateData.revenueShare,
			affiliateId: affiliate.info.id,
		});
	};

	const changeNewSubAffiliateData = (newData: Partial<NewSubAffiliateData>) => {
		setNewSubAffiliateData((_oldData) => ({ ..._oldData, ...newData }));
	};

	/**
	 * Date Filter
	 */

	const chartLabels = new Array(Math.floor((detailsConfig.before - detailsConfig.after) / 24 / 60 / 60 / 1000)).fill(0).map((_, i) => {
		const date = new Date(detailsConfig.before - 1000 * 60 * 60 * 24 * i);
		return date.toLocaleDateString("en-US");
	});

	const handleFilterData = () => {
		setAffiliate(null);
	};

	const affiliatedPeopleFiltered = affiliate.affiliatedPeople.filter(
		(people) => new Date(people.createdAt).getTime() >= detailsConfig.after && new Date(people.createdAt).getTime() <= detailsConfig.before
	);

	return (
		<Page title="Gerenciamento: Afiliados">
			<Container maxWidth="lg">
				<CustomBreadcrumbs
					heading={`${isSubAffiliate ? "Sub-" : ""}Afiliado "${affiliate.info.email}"`}
					links={[{ name: `Atualizado em ${new Date().toLocaleString("pt-BR")}` }]}
				/>
				<Grid container spacing={3} sx={{ justifyContent: "space-around", alignItems: "center", alignContent: "center" }}>
					<Grid item xs={12} md={6}>
						<Card sx={{ p: 3 }}>
							<Link href={`${websiteConfig.cassinoDomain}/home?ref=${affiliate.info.id}`} target="_blank" sx={{ color: "text.secondary" }}>
								Link de convite
							</Link>
							<Button
								variant="contained"
								color="secondary"
								sx={{ ml: 2 }}
								onClick={() => navigator.clipboard.writeText(`${websiteConfig.cassinoDomain}/home?ref=${affiliate.info.id}`)}>
								Copiar
							</Button>
							<Divider sx={{ my: 2 }} />
							<Grid container>
								<Grid item xs={4}>
									<Typography variant="subtitle2" color="text.primary">
										CPF:
										<br />
										E-Mail:
										<br />
										Nome de usuário:
										<br />
										ID:
									</Typography>
								</Grid>
								<Grid item xs={8}>
									<Typography variant="subtitle2" color="text.secondary" sx={{ textAlign: "end", justifyContent: "end" }}>
										{fCpf(affiliate.info.document)}
										<br />
										{affiliate.info.email}
										<br />
										{affiliate.info.username}
										<br />
										{affiliate.info.id}
									</Typography>
								</Grid>
							</Grid>
							<Stack spacing={3} direction="row" sx={{ justifyContent: "space-around", mt: 3, textAlign: "center" }}>
								<Stack direction={canEditAffiliate ? "column" : "row"} width={canEditAffiliate ? "160px" : "auto"} spacing={3}>
									<Box>
										<Typography variant={"body2"} sx={{ mb: 1 }}>
											Revenue Share
										</Typography>
										<TextEditable
											text={`${affiliate.info.revenueShare}%`}
											value={affiliate.info.revenueShare}
											isCenter={true}
											textTypographyProps={{
												variant: "subtitle2",
												color: "text.secondary",
											}}
											textFieldProps={{
												placeholder: "Nova Revenue Share",
												size: "small",
												inputProps: {
													sx: {
														textAlign: "center",
														fontSize: "0.875rem",
														fontWeight: "600",
														color: "#919EAB",
													},
												},
											}}
											onEdit={(value: string) => {
												updateAffiliate({ revenueShare: Number(value) });
											}}
											sx={{ mt: -1 }}
											disabled={!canEditAffiliate}
										/>
									</Box>
									<Box>
										<Divider orientation={canEditAffiliate ? "horizontal" : "vertical"} />
									</Box>
									<Box>
										<Typography variant={"body2"} sx={{ mb: 1 }}>
											CPA
										</Typography>
										<TextEditable
											text={affiliate.info.cpa.toLocaleString("pt-BR", {
												style: "currency",
												currency: "BRL",
											})}
											value={affiliate.info.cpa}
											isCenter={true}
											textTypographyProps={{
												variant: "subtitle2",
												color: "text.secondary",
											}}
											textFieldProps={{
												placeholder: "Novo CPA",
												size: "small",
												inputProps: {
													sx: {
														textAlign: "center",
														fontSize: "0.875rem",
														fontWeight: "600",
														color: "#919EAB",
													},
												},
											}}
											onEdit={(value: string) => {
												updateAffiliate({ cpa: Number(value) });
											}}
											sx={{ mt: -1 }}
											disabled={!canEditAffiliate}
										/>
									</Box>
									<Box>
										<Divider orientation={canEditAffiliate ? "horizontal" : "vertical"} />
									</Box>
									<Box>
										<Typography variant={"body2"} sx={{ mb: 1 }}>
											Saldo de demonstração
										</Typography>
										<TextEditable
											text={affiliate.info.demoBalance.toLocaleString("pt-BR", {
												style: "currency",
												currency: "BRL",
											})}
											value={affiliate.info.demoBalance}
											isCenter={true}
											textTypographyProps={{
												variant: "subtitle2",
												color: "text.secondary",
											}}
											textFieldProps={{
												placeholder: "Novo saldo de demonstração",
												size: "small",
												inputProps: {
													sx: {
														textAlign: "center",
														fontSize: "0.875rem",
														fontWeight: "600",
														color: "#919EAB",
													},
												},
											}}
											onEdit={(value: string) => {
												updateAffiliate({ demoBalance: Number(value) });
											}}
											sx={{ mt: -1 }}
											disabled={!canEditAffiliate}
										/>
									</Box>
								</Stack>
								<Box>
									<Stack spacing={3} sx={{ height: "100%", display: "flex", alignItems: "center", alignContent: "center", justifyContent: "center" }}>
										{!isSubAffiliate && (
											<Modal
												buttonText={"Adicionar Sub-Afiliado"}
												buttonProps={{
													color: "info",
													variant: "contained",
													disabled: !canEditAffiliate,
													fullWidth: true,
												}}
												Children={(closeModal: Function) => (
													<Box
														sx={{
															position: "absolute",
															top: "50%",
															left: "50%",
															transform: "translate(-50%, -50%)",
															width: 400,
															bgcolor: "background.paper",
															boxShadow: 24,
															p: 4,
														}}>
														<Box sx={{ display: "flex", justifyContent: "space-between", alignContent: "center", alignItems: "center" }}>
															<Typography variant="h6" component="span">
																Adicionar Sub-Afiliado
															</Typography>
															<IconButton onClick={() => closeModal()} color="error">
																<Iconify icon="iconoir:cancel" />
															</IconButton>
														</Box>
														<Stack spacing={2} sx={{ my: 3 }}>
															<TextField
																fullWidth
																variant="outlined"
																id="email"
																name="email"
																label="Insira o e-mail do usuário"
																placeholder="abc@mail.xyz"
																size="medium"
																type="email"
																value={newSubAffiliateData.email}
																onChange={({ target: { name, value } }) => changeNewSubAffiliateData({ [name]: value })}
															/>
															<TextField
																id="revenueShare"
																inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
																InputProps={{ inputProps: { min: 0, max: 100 } }}
																label="% de revenue share"
																name="revenueShare"
																placeholder="30"
																size="medium"
																type="number"
																variant="outlined"
																value={Number(newSubAffiliateData.revenueShare)}
																onChange={({ target: { name, value } }) => changeNewSubAffiliateData({ [name]: fMinMax(value, 0, 100) })}
															/>
															<Box>
																{newSubAffiliateData.cpaActive && (
																	<TextField
																		fullWidth
																		id="cpaValue"
																		inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
																		label="Valor do CPA	(R$)"
																		name="cpaValue"
																		placeholder="100"
																		size="medium"
																		type="number"
																		variant="outlined"
																		value={Number(newSubAffiliateData.cpaValue)}
																		onChange={({ target: { name, value } }) => changeNewSubAffiliateData({ [name]: Number(value) })}
																		disabled={!newSubAffiliateData.cpaActive}
																	/>
																)}
																<FormControlLabel
																	control={
																		<Checkbox
																			checked={newSubAffiliateData.cpaActive}
																			id="cpaActive"
																			name="cpaActive"
																			onChange={({ target: { name, checked } }) => changeNewSubAffiliateData({ [name]: checked })}
																		/>
																	}
																	label="Ativar CPA"
																	sx={{
																		float: "right",
																	}}
																/>
															</Box>
														</Stack>
														<Box sx={{ textAlign: "end" }}>
															<LoadingButton
																variant="contained"
																color="success"
																loading={creatingSubAffiliate}
																disabled={newSubAffiliateData.email.length === 0}
																onClick={() => {
																	createSubAffiliate();
																	closeModal();
																}}>
																Adicionar
															</LoadingButton>
														</Box>
													</Box>
												)}
												open={false}
												children={<></>}
											/>
										)}
										{canEditAffiliate && (
											<>
												<Button fullWidth variant="contained" color="success" disabled={!canEditAffiliate || getRevenueShare() <= 0} onClick={() => payAffiliate("revenueShare")}>
													Pagar Revenue Share
												</Button>
												{affiliate.info.cpa !== 0 && (
													<Button fullWidth variant="contained" color="success" disabled={!canEditAffiliate || getCurrentCpa() <= 0} onClick={() => payAffiliate("cpa")}>
														Pagar CPA
													</Button>
												)}
												<Button fullWidth variant="contained" color="error" disabled={!canEditAffiliate} onClick={deleteAffiliate}>
													Remover {isSubAffiliate ? "Sub-" : ""}Afiliado
												</Button>
											</>
										)}
									</Stack>
								</Box>
							</Stack>
						</Card>
					</Grid>
					<Grid item xs={12} md={5}>
						<Stack spacing={2}>
							{user.roles.some((role) => ["super-admin", "admin", "user", "affiliates.see", "affiliates.manage"].includes(role)) && (
								<WidgetSummary
									title="Total em saldo real nas contas dos clientes"
									total={affiliate.affiliatedPeople
										.reduce((prev, curr) => prev + curr.balance, 0)
										.toLocaleString("pt-BR", {
											currency: "BRL",
											style: "currency",
										})}
									icon={
										<Iconify
											icon={"iconoir:wallet"}
											sx={{
												width: "100%",
												height: "100%",
												color: theme.palette.info.main,
											}}
										/>
									}
									bgColor={alpha(theme.palette.info.main, 0.08)}
								/>
							)}
							{user.roles.some((role) => ["super-admin", "admin", "user", "affiliates.see", "affiliates.manage"].includes(role)) && (
								<WidgetSummary
									title="Lucro total"
									total={getAffiliateProfit().toLocaleString("pt-BR", {
										currency: "BRL",
										style: "currency",
									})}
									icon={
										<Iconify
											icon={"iconoir:card-wallet"}
											sx={{
												width: "100%",
												height: "100%",
												color: theme.palette.warning.main,
											}}
										/>
									}
									bgColor={alpha(theme.palette.warning.main, 0.08)}
								/>
							)}
							<WidgetSummary
								title={`Total em Revenue Share ${getRevenueShare().toFixed(2)}`}
								total={getRevenueShare().toLocaleString("pt-BR", {
									currency: "BRL",
									style: "currency",
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								})}
								icon={
									<Iconify
										icon={"iconoir:three-stars"}
										sx={{
											width: "100%",
											height: "100%",
											color: theme.palette.success.main,
										}}
									/>
								}
								bgColor={alpha(theme.palette.success.main, 0.08)}
							/>
							<WidgetSummary
								title={`Total em CPA`}
								total={getCurrentCpa().toLocaleString("pt-BR", {
									currency: "BRL",
									style: "currency",
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
								})}
								icon={
									<Iconify
										icon={"iconoir:three-stars"}
										sx={{
											width: "100%",
											height: "100%",
											color: theme.palette.success.main,
										}}
									/>
								}
								bgColor={alpha(theme.palette.success.main, 0.08)}
							/>
						</Stack>
					</Grid>
				</Grid>
				<Divider sx={{ mt: 5, mb: 5 }} />
				<Box sx={{ my: 3, textAlign: "center" }}>
					<Box>
						<Stack spacing={3} direction="row" sx={{ justifyContent: "center", alignItems: "center", alignContent: "center" }}>
							<DatePicker
								renderInput={(props) => <TextField size={"small"} {...props} />}
								label="A partir da data"
								value={detailsConfig.after}
								onChange={(newValue: Date | null) => {
									newValue?.setHours(0, 0, 0, 0);
									setDetailsConfig((_oldConfig) => ({ ..._oldConfig, after: newValue ? newValue.getTime() : Date.now() }));
								}}
							/>
							<Typography variant="subtitle2" color="text.secondary">
								{((detailsConfig.before - detailsConfig.after) / 24 / 60 / 60 / 1000).toFixed(0)} dia
								{Math.floor((detailsConfig.before - detailsConfig.after) / 24 / 60 / 60 / 1000) - 1 > 1 ? "s" : ""}
							</Typography>
							<DatePicker
								renderInput={(props) => <TextField size={"small"} {...props} />}
								label="Até a data"
								value={detailsConfig.before}
								onChange={(newValue: Date | null) => {
									newValue?.setHours(23, 59, 59, 999);
									setDetailsConfig((_oldConfig) => ({ ..._oldConfig, before: newValue ? newValue.getTime() : Date.now() }));
								}}
							/>
						</Stack>
						<Stack spacing={3} direction="row" sx={{ mt: 1, justifyContent: "center" }}>
							{[1, 7, 14, 31].map((dayOffset) => (
								<Button
									key={`date-change-${dayOffset}`}
									color="secondary"
									variant="soft"
									size="small"
									onClick={() =>
										setDetailsConfig((_oldConfig) => ({
											..._oldConfig,
											after: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0, 0).getTime() - 1000 * 60 * 60 * 24 * (dayOffset - 1),
											before: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 999).getTime(),
										}))
									}>
									{dayOffset} dia{dayOffset > 1 ? "s" : ""}
								</Button>
							))}
						</Stack>
					</Box>
					<Box sx={{ mt: 2 }}>
						<Button variant="contained" color="success" onClick={handleFilterData}>
							Filtrar
						</Button>
					</Box>
				</Box>
				<Box sx={{ width: "100%" }}>
					{user.roles.some((role) => ["super-admin", "admin", "affiliates.see", "affiliates.manage", "user"].includes(role)) ? (
						<ToggleButtonGroup
							color="primary"
							value={currentAction}
							exclusive
							onChange={(event, newValue) => setCurrentAction(newValue || currentAction)}
							aria-label="Abas"
							size={isDesktop ? "medium" : "small"}
							sx={{ mx: "auto" }}>
							<ToggleButton size={isDesktop ? "medium" : "small"} value={"details"}>
								Detalhes
							</ToggleButton>
							<ToggleButton size={isDesktop ? "medium" : "small"} value={"deposits"}>
								Depósitos
							</ToggleButton>
							<ToggleButton size={isDesktop ? "medium" : "small"} value={"withdraws"}>
								Saques
							</ToggleButton>
							<ToggleButton size={isDesktop ? "medium" : "small"} value={"payments"}>
								{isDesktop ? "Pagamentos" : "Pagts."}
							</ToggleButton>
							{affiliate.info.cpa !== 0 && (
								<ToggleButton size={isDesktop ? "medium" : "small"} value={"cpa"}>
									CPA
								</ToggleButton>
							)}
							{(affiliate.subAffiliates || []).length > 0 && (
								<ToggleButton size={isDesktop ? "medium" : "small"} value={"subAffiliates"}>
									Sub-{isDesktop ? "Afiliados" : "Afs"}
								</ToggleButton>
							)}
						</ToggleButtonGroup>
					) : (
						(affiliate.subAffiliates || []).length > 0 &&
						affiliate.info.id === user.id && (
							<ToggleButtonGroup
								color="primary"
								value={currentAction}
								exclusive
								onChange={(event, newValue) => setCurrentAction(newValue || currentAction)}
								aria-label="Abas"
								size={isDesktop ? "medium" : "small"}
								sx={{ mx: "auto" }}>
								<ToggleButton size={isDesktop ? "medium" : "small"} value={"subAffiliates"}>
									Sub-{isDesktop ? "Afiliados" : "Afs"}
								</ToggleButton>
							</ToggleButtonGroup>
						)
					)}
				</Box>
				<Grid container spacing={3} sx={{ justifyContent: "center", alignItems: "center", alignContent: "center", mt: 1 }}>
					{currentAction === "deposits" ? (
						affiliate.affiliatedPeople.reduce((prev, curr) => prev + curr.deposits.length, 0) > 0 ? (
							<>
								<Grid item xs={12} md={user.roles.some((role) => ["super-admin", "admin", "affiliates.see", "affiliates.manage", "user"].includes(role)) ? 3.5 : 4}>
									<AffiliatedDepositStatusChart
										title="Status dos depósitos"
										{...getChartData(
											affiliatedPeopleFiltered
												.map((people) =>
													people.deposits
														.filter((deposit) => new Date(deposit.createdAt).getTime() >= detailsConfig.after && new Date(deposit.createdAt).getTime() <= detailsConfig.before)
														.map((deposit) => ({
															amount: deposit.amount,
															status: deposit.status,
														}))
												)
												.flat(1) || []
										)}
										chartColors={[
											[theme.palette.warning.light, theme.palette.warning.main],
											[theme.palette.success.light, theme.palette.success.main],
										]}
									/>
								</Grid>
								{user.roles.some((role) => ["super-admin", "admin", "affiliates.see", "affiliates.manage", "user"].includes(role)) && (
									<Grid item xs={12} md={4.5}>
										<WidgetSummary
											title="Quantia aprovada"
											total={affiliate.affiliatedPeople
												.reduce(
													(prev, curr) =>
														prev +
														curr.deposits
															.filter((deposit) => new Date(deposit.createdAt).getTime() >= detailsConfig.after && new Date(deposit.createdAt).getTime() <= detailsConfig.before)
															.filter((deposit) => deposit.status === "confirmed")
															.reduce((prev, curr) => prev + curr.amount, 0),
													0
												)
												.toLocaleString("pt-BR", {
													currency: "BRL",
													style: "currency",
												})}
											icon={
												<Iconify
													icon={"iconoir:check"}
													sx={{
														width: "100%",
														height: "100%",
														color: theme.palette.success.main,
													}}
												/>
											}
											bgColor={alpha(theme.palette.success.main, 0.08)}
											sx={{ mb: 3 }}
										/>
										<WidgetSummary
											title="Quantia bônus"
											total={affiliate.affiliatedPeople
												.reduce(
													(prev, curr) =>
														prev +
														curr.deposits
															.filter((deposit) => deposit.status === "confirmed")
															.filter((deposit) => new Date(deposit.createdAt).getTime() >= detailsConfig.after && new Date(deposit.createdAt).getTime() <= detailsConfig.before)
															.reduce((prev, curr) => prev + (curr.amount * ((curr.bonus + 100) / 100) - curr.amount), 0),
													0
												)
												.toLocaleString("pt-BR", {
													currency: "BRL",
													style: "currency",
												})}
											icon={
												<Iconify
													icon={"iconoir:card-wallet"}
													sx={{
														width: "100%",
														height: "100%",
														color: theme.palette.info.main,
													}}
												/>
											}
											bgColor={alpha(theme.palette.info.main, 0.08)}
											sx={{ mb: 3 }}
										/>
										<WidgetSummary
											title="Quantia pendente"
											total={affiliate.affiliatedPeople
												.reduce(
													(prev, curr) =>
														prev +
														curr.deposits
															.filter((deposit) => deposit.status === "pending")
															.filter((deposit) => new Date(deposit.createdAt).getTime() >= detailsConfig.after && new Date(deposit.createdAt).getTime() <= detailsConfig.before)
															.reduce((prev, curr) => prev + curr.amount, 0),
													0
												)
												.toLocaleString("pt-BR", {
													currency: "BRL",
													style: "currency",
												})}
											icon={
												<Iconify
													icon={"iconoir:clock-outline"}
													sx={{
														width: "100%",
														height: "100%",
														color: theme.palette.warning.main,
													}}
												/>
											}
											bgColor={alpha(theme.palette.warning.main, 0.08)}
											sx={{ mb: 3 }}
										/>
									</Grid>
								)}
								<Grid item xs={12} md={4}>
									<WidgetSummary
										title="Qntd. de usuários com dep. total ≤ R$50"
										total={
											affiliate.affiliatedPeople.filter((people) => {
												const totalAmount = people.deposits
													.filter((deposit) => deposit.status === "confirmed")
													.filter((deposit) => new Date(deposit.createdAt).getTime() >= detailsConfig.after && new Date(deposit.createdAt).getTime() <= detailsConfig.before)
													.reduce((prev_, curr_) => prev_ + curr_.amount, 0);
												return totalAmount > 0 && totalAmount <= 50;
											}).length
										}
										icon={null}
										bgColor={alpha(theme.palette.success.main, 0.08)}
										sx={{ mb: 3 }}
									/>
									<WidgetSummary
										title="Qntd. de usuários com dep. total > R$50 < R$100"
										total={
											affiliate.affiliatedPeople.filter((people) => {
												const totalAmount = people.deposits
													.filter((deposit) => deposit.status === "confirmed")
													.filter((deposit) => new Date(deposit.createdAt).getTime() >= detailsConfig.after && new Date(deposit.createdAt).getTime() <= detailsConfig.before)
													.reduce((prev_, curr_) => prev_ + curr_.amount, 0);
												return totalAmount > 50 && totalAmount < 100;
											}).length
										}
										icon={null}
										bgColor={alpha(theme.palette.info.main, 0.08)}
										sx={{ mb: 3 }}
									/>
									<WidgetSummary
										title="Qntd. de usuários com dep. total ≥ R$100"
										total={
											affiliate.affiliatedPeople.filter(
												(people) =>
													people.deposits
														.filter((deposit) => deposit.status === "confirmed")
														.filter((deposit) => new Date(deposit.createdAt).getTime() >= detailsConfig.after && new Date(deposit.createdAt).getTime() <= detailsConfig.before)
														.reduce((prev_, curr_) => prev_ + curr_.amount, 0) >= 100
											).length
										}
										icon={null}
										bgColor={alpha(theme.palette.warning.main, 0.08)}
										sx={{ mb: 3 }}
									/>
								</Grid>
							</>
						) : (
							<EmptyContent
								title="Nenhum dado disponível"
								sx={{
									"& span.MuiBox-root": { height: 160 },
								}}
							/>
						)
					) : currentAction === "withdraws" ? (
						affiliate.affiliatedPeople.reduce((prev, curr) => prev + curr.withdraws.length, 0) > 0 ? (
							<>
								<Grid item xs={12} lg={4}>
									<AffiliatedDepositStatusChart
										title="Status dos saques"
										{...getChartData(
											affiliate.affiliatedPeople
												.map((people) =>
													people.withdraws
														.filter((withdraw) => new Date(withdraw.createdAt).getTime() >= detailsConfig.after && new Date(withdraw.createdAt).getTime() <= detailsConfig.before)
														.map((widthdraw) => ({
															amount: widthdraw.amount,
															status: widthdraw.status,
														}))
												)
												.flat(1) || []
										)}
										chartColors={[
											[theme.palette.success.light, theme.palette.success.main],
											[theme.palette.error.light, theme.palette.error.main],
											[theme.palette.warning.light, theme.palette.warning.main],
										]}
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<WidgetSummary
										title="Quantia aprovada"
										total={affiliate.affiliatedPeople
											.reduce(
												(prev, curr) =>
													prev +
													curr.withdraws
														.filter((withdraw) => withdraw.status === "approved")
														.filter((withdraw) => {
															const withdrawAfter = new Date(detailsConfig.after);
															const withdrawBefore = new Date(detailsConfig.before);

															// withdrawAfter.setHours(0, 0, 0, 0);
															// withdrawBefore.setHours(0, 0, 0, 0);

															// console.log(withdrawAfter.toUTCString(), withdrawBefore.toUTCString())

															return new Date(withdraw.createdAt).getTime() >= withdrawAfter.getTime() && new Date(withdraw.createdAt).getTime() <= withdrawBefore.getTime();
														})
														.reduce((prev, curr) => prev + curr.amount, 0),
												0
											)
											.toLocaleString("pt-BR", {
												currency: "BRL",
												style: "currency",
											})}
										icon={
											<Iconify
												icon={"iconoir:check"}
												sx={{
													width: "100%",
													height: "100%",
													color: theme.palette.success.main,
												}}
											/>
										}
										bgColor={alpha(theme.palette.success.main, 0.08)}
										sx={{ mb: 3 }}
									/>
									<WidgetSummary
										title="Quantia pendente"
										total={affiliate.affiliatedPeople
											.reduce(
												(prev, curr) =>
													prev +
													curr.withdraws
														.filter((deposit) => deposit.status === "pending")
														.filter((withdraw) => new Date(withdraw.createdAt).getTime() >= detailsConfig.after && new Date(withdraw.createdAt).getTime() <= detailsConfig.before)
														.reduce((prev, curr) => prev + curr.amount, 0),
												0
											)
											.toLocaleString("pt-BR", {
												currency: "BRL",
												style: "currency",
											})}
										icon={
											<Iconify
												icon={"iconoir:clock-outline"}
												sx={{
													width: "100%",
													height: "100%",
													color: theme.palette.warning.main,
												}}
											/>
										}
										bgColor={alpha(theme.palette.warning.main, 0.08)}
										sx={{ mb: 3 }}
									/>
									<WidgetSummary
										title="Quantia rejeitada"
										total={affiliate.affiliatedPeople
											.reduce(
												(prev, curr) =>
													prev +
													curr.withdraws
														.filter((deposit) => deposit.status === "rejected")
														.filter((withdraw) => new Date(withdraw.createdAt).getTime() >= detailsConfig.after && new Date(withdraw.createdAt).getTime() <= detailsConfig.before)
														.reduce((prev, curr) => prev + curr.amount, 0),
												0
											)
											.toLocaleString("pt-BR", {
												currency: "BRL",
												style: "currency",
											})}
										icon={
											<Iconify
												icon={"iconoir:cancel"}
												sx={{
													width: "100%",
													height: "100%",
													color: theme.palette.error.main,
												}}
											/>
										}
										bgColor={alpha(theme.palette.error.main, 0.08)}
										sx={{ mb: 3 }}
									/>
								</Grid>
							</>
						) : (
							<EmptyContent
								title="Nenhum dado disponível"
								sx={{
									"& span.MuiBox-root": { height: 160 },
								}}
							/>
						)
					) : currentAction === "details" ? (
						affiliate.affiliatedPeople.length > 0 ? (
							<>
								<Grid item xs={12} lg={8}>
									<FirstInteractionsGraph
										title="Dados de primeiras interações"
										chart={{
											labels: chartLabels,
											series: getAreaChartData(affiliate.affiliatedPeople, detailsConfig.after, detailsConfig.before),
										}}
									/>
								</Grid>
								<Grid item xs={12} lg={4}>
									<WidgetSummary
										title="Qntd. total de cadastros"
										total={
											affiliatedPeopleFiltered.filter(
												(user) => new Date(user.createdAt).getTime() >= detailsConfig.after && new Date(user.createdAt).getTime() <= detailsConfig.before
											).length
										}
										icon={null}
										bgColor={alpha(theme.palette.success.main, 0.08)}
										sx={{ mb: 3 }}
									/>
									<WidgetSummary
										title="Qntd. total de primeiros depósitos"
										total={getFirstDepositsCount()}
										icon={null}
										bgColor={alpha(theme.palette.success.main, 0.08)}
										sx={{ mb: 3 }}
									/>
								</Grid>
							</>
						) : (
							<EmptyContent
								title="Nenhum dado disponível"
								sx={{
									"& span.MuiBox-root": { height: 160 },
								}}
							/>
						)
					) : currentAction === "payments" ? (
						affiliate.affiliatedPeople.length > 0 ? (
							<>
								<Grid item xs={3}>
									{user.roles.some((role) => ["super-admin", "admin", "user", "affiliates.see", "affiliates.manage"].includes(role)) && (
										<WidgetSummary
											title="Lucro no período"
											total={getAffiliateIntervalProfit().toLocaleString("pt-BR", {
												currency: "BRL",
												style: "currency",
											})}
											icon={
												<Iconify
													icon={"iconoir:card-wallet"}
													sx={{
														width: "100%",
														height: "100%",
														color: theme.palette.warning.main,
													}}
												/>
											}
											bgColor={alpha(theme.palette.warning.main, 0.08)}
										/>
									)}
								</Grid>
								<Grid item md={12} xs={12}>
									<AffiliatedPaymentsTable
										rows={affiliate.info.paymentLogs.map((log) => ({
											id: log.id,
											date: log.at,
											type: log.type,
											email: log.email,
											amount: log.amount,
											thatDayValue: log.thatDayValue,
											byId: log.byId,
										}))}
										tableConfig={tableConfig}
										itemCount={affiliate.info.revenueShareLogs.length || 0}
										onChangeTableConfig={onChangeTableConfig}
										sx={{ p: 2 }}
									/>
								</Grid>
							</>
						) : (
							<EmptyContent
								title="Nenhum dado disponível"
								sx={{
									"& span.MuiBox-root": { height: 160 },
								}}
							/>
						)
					) : currentAction === "cpa" ? (
						affiliate.affiliatedPeople.reduce((prev, curr) => prev + curr.deposits.length, 0) > 0 ? (
							<>
								<Grid item xs={12} lg={3}>
									<WidgetSummary
										title="CPA total"
										total={getCurrentCpa().toLocaleString("pt-BR", { currency: "BRL", style: "currency" })}
										icon={null}
										bgColor={alpha(theme.palette.success.main, 0.08)}
									/>
								</Grid>
								<Grid item xs={12} lg={3}>
									<WidgetSummary
										title="Qntd. de usuários com cpa"
										total={affiliate.info.cpa === 0 ? 0 : getRealCpa(affiliate.info.cpa) / affiliate.info.cpa}
										icon={null}
										bgColor={alpha(theme.palette.success.main, 0.08)}
									/>
								</Grid>
								<Grid item xs={12} lg={12}>
									<AffiliatedCpaTable
										rows={getCpaTable()}
										tableConfig={{
											limit: 5,
											order: "desc",
											orderBy: "date",
											page: 0,
										}}
									/>
								</Grid>
							</>
						) : (
							<EmptyContent
								title="Nenhum dado disponível"
								sx={{
									"& span.MuiBox-root": { height: 160 },
								}}
							/>
						)
					) : currentAction === "subAffiliates" ? (
						affiliate.subAffiliates && affiliate.subAffiliates.length > 0 ? (
							<>
								<Grid item xs={12} lg={12}>
									<AffiliatedSubAffiliatesTable
										rows={affiliate.subAffiliates}
										tableConfig={{
											limit: 5,
											order: "desc",
											orderBy: "createdAt",
											page: 0,
										}}
									/>
								</Grid>
							</>
						) : (
							<EmptyContent
								title="Nenhum dado disponível"
								sx={{
									"& span.MuiBox-root": { height: 160 },
								}}
							/>
						)
					) : (
						<></>
					)}
				</Grid>
			</Container>
		</Page>
	);
}
