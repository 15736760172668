import { Link as RouterLink } from "react-router-dom";
import { Link, Stack, Alert, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormProvider, { RHFTextField, RHFCheckbox } from "src/components/hook-form";
import Iconify from "src/components/iconify";
import useAuth from "src/hooks/useAuth";

type FormValuesProps = {
	email: string;
	password: string;
	remember: boolean;
	afterSubmit?: string;
};

export default function LoginForm() {
	const { login } = useAuth();

	const [showPassword, setShowPassword] = useState(false);

	const LoginSchema = Yup.object().shape({
		email: Yup.string().email("O endereço e-mail necessita ser válido.").required("O endereço e-mail é obrigatório."),
		password: Yup.string().required("A senha é obrigatória."),
	});

	const defaultValues = {
		email: "",
		password: "",
		remember: true,
	};

	const methods = useForm<FormValuesProps>({
		resolver: yupResolver(LoginSchema),
		defaultValues,
	});

	const {
		setError,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = methods;

	const onSubmit = async (data: FormValuesProps) => {
		try {
			await login(data.email.toLowerCase(), data.password);
		} catch (error) {
			return setError("afterSubmit", {
				...error,
				message: "Ocorreu algum erro, verifique suas credenciais ou tente novamente mais tarde.",
			});
		}
	};

	return (
		<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
			<Stack spacing={3}>
				{!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

				<RHFTextField id="email" label="Endereço de e-mail" name="email" variant="filled" type="email" />

				<RHFTextField
					id="password"
					label="Senha"
					name="password"
					variant="filled"
					type={showPassword ? "text" : "password"}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
									<Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
			</Stack>

			<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
				<RHFCheckbox name="remember" label="Lembrar-me" />
				<Link component={RouterLink} variant="subtitle2" to="/auth/reset-password">
					Esqueceu sua senha?
				</Link>
			</Stack>

			<LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
				Entrar
			</LoadingButton>
		</FormProvider>
	);
}
