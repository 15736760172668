import { NavListProps } from "src/components/nav-section";
import Iconify from "../../../components/iconify";

const navConfig: {
	subheader: string;
	items: NavListProps[];
}[] = [
	{
		subheader: "Dashboard",
		items: [
			{
				title: "Página inicial",
				path: "/app/main",
				subDomainFilter: ["backoffice", "localhost"],
				icon: <Iconify icon="iconoir:home" />,
				permFilter: ["super-admin", "admin", "user", "dashboard"],
			},
			{
				title: "Estatísticas",
				path: "/app/statistics",
				subDomainFilter: ["backoffice", "localhost"],
				icon: <Iconify icon="iconoir:graph-up" />,
				permFilter: ["super-admin", "admin", "user", "statistics"],
			},
			{
				title: "Jogos",
				path: "",
				subDomainFilter: ["backoffice", "localhost"],
				icon: <Iconify icon="iconoir:gamepad" />,
				permFilter: ["super-admin", "admin", "user"],
				children: [
					{
						title: "Double",
						path: "/app/games/double",
					},
					{
						title: "Double Pro",
						path: "/app/games/doublepro",
					},
					{
						title: "Rocket",
						path: "/app/games/rocket",
					},
					{
						title: "Mines",
						path: "/app/games/mines",
					},
					{
						title: "Dice",
						path: "/app/games/dice",
					},
					{
						title: "Plinko",
						path: "/app/games/plinko",
					},
				],
			},
		],
	},
	{
		subheader: "Gerenciamento",
		items: [
			{
				title: "Usuários",
				path: "/app/accounts/list",
				subDomainFilter: ["backoffice", "localhost"],
				icon: <Iconify icon="iconoir:user" />,
				permFilter: ["super-admin", "admin", "user", "accounts.see"],
			},
			{
				title: "Afiliados",
				path: "/app/affiliated/list",
				subDomainFilter: ["backoffice", "localhost"],
				icon: <Iconify icon="iconoir:verified-user" />,
				permFilter: ["super-admin", "admin", "user", "affiliates.see", "affiliates.manage"],
			},
			{
				title: "Registros",
				path: "/app/logs",
				subDomainFilter: ["backoffice", "localhost"],
				icon: <Iconify icon="iconoir:ecology-book" />,
				permFilter: ["super-admin", "admin", "user"],
			},
		],
	},
	{
		subheader: "Suporte",
		items: [
			{
				title: "Saques",
				path: "/app/withdraws/list",
				subDomainFilter: ["backoffice", "localhost"],
				icon: <Iconify icon="iconoir:piggy-bank" />,
				permFilter: ["super-admin", "admin", "withdraws.see"],
			},
			{
				title: "Verificar Depósitos",
				path: "/app/deposits/list",
				icon: <Iconify icon="iconoir:data-transfer-check" />,
				permFilter: ["super-admin", "admin", "deposits.verify"],
			},
		],
	},
	{
		subheader: "Admin",
		items: [
			{
				title: "Permissões",
				path: "/app/admin/permissions",
				subDomainFilter: ["backoffice", "localhost"],
				icon: <Iconify icon="iconoir:user" />,
				permFilter: ["super-admin"],
			},
		],
	},
];

export default navConfig;
