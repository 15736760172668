import { AccountDepositsTableRowType } from "./AccountDepositsTable";
import { Box, Button, Typography } from "@mui/material";
import { dateLocale, dateOptions } from "../../_constants";
import { DefaultTableRowProps } from "../../_types";
import Iconify from "src/components/iconify";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import useWebSocket from "src/hooks/useWebSocket";

export default function AccountDepositsTableRow({ isItemSelected, row, labelId, handleSelect }: DefaultTableRowProps<AccountDepositsTableRowType>) {
	const { websocket } = useWebSocket();

	const handleApprove = () => {
		websocket.emit("Management.Deposit.Action", {
			approved: true,
			id: row.id,
		});
	};

	return (
		<TableRow hover tabIndex={-1}>
			<TableCell align="left" id={labelId}>
				<Box sx={{ display: "flex", alignItems: "center", alignContent: "center", gap: 1 }}>
					<Iconify
						icon={`iconoir:${row.status === "pending" ? "clock" : (row.status === "confirmed" ? "check" : "delete") + "-circle"}`}
						color={`${row.status === "pending" ? "warning" : row.status === "confirmed" ? "success" : "error"}.main`}
					/>
					<Typography variant="subtitle2" color={`${row.status === "pending" ? "warning" : row.status === "confirmed" ? "success" : "error"}.light`}>
						{row.status === "pending" ? "Pendente" : row.status === "confirmed" ? "Aprovado" : row.status === "canceled" ? "Cancelado" : "Recusado"}
					</Typography>
				</Box>
			</TableCell>
			<TableCell align="left">{row.id}</TableCell>
			<TableCell align="left">{new Date(row.createdAt).toLocaleString(dateLocale, dateOptions)}</TableCell>
			<TableCell align="left">{row.amount.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</TableCell>
			<TableCell align="left">
				{row.bonus > 0 ? (
					(row.amount * ((row.bonus + 100) / 100) - row.amount).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
				) : (
					<Iconify icon="iconoir:delete-circle" color="error.main" />
				)}
			</TableCell>
			{row.status !== "confirmed" && (
				<TableCell align="right">
					<Button variant="text" color="success" onClick={handleApprove} endIcon={<Iconify icon={"iconoir:check"} />}>
						Aprovar
					</Button>
				</TableCell>
			)}
		</TableRow>
	);
}
