import { Account, AuthContextType, AuthState } from "src/@types/auth";
import { createContext, ReactNode, useCallback, useEffect, useState } from "react";
import { socket } from "./WebSocketContext";
import { isValidToken, setSession } from "src/utils/jwt";
import { useSnackbar } from "notistack";
import axiosInstance from "src/utils/axios";
import { websiteConfig } from "src/config";

const AuthContext = createContext({} as AuthContextType);

type AuthProviderProps = {
	children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
	const { enqueueSnackbar } = useSnackbar();
	const [state, setState] = useState<AuthState>({
		user: null,
		token: "",
	});

	const logout = useCallback(() => {
		sessionStorage.removeItem("accessToken");
		setSession(null, logout);
		setState({ user: null, token: "" });
	}, []);

	const getProfile = useCallback(() => {
		return axiosInstance
			.get("/api/v1/user/me")
			.then((response) => {
				if (response.status === 200) {
					if (response.data.roles.length > 0 || response.data.enableAffiliation) {
						setState((_state) => ({ ..._state, user: response.data }));
						return true;
					} else {
						logout();
						return false;
					}
				} else {
					logout();
					return false;
				}
			})
			.catch((error) => {
				console.error(error);
				return false;
			});
	}, [logout]);

	const login = useCallback(
		async (email: string, password: string) => {
			await axiosInstance
				.post("/api/v1/auth/login", {
					email,
					password,
				})
				.then((response: any) => {
					if (response.status === 202) {
						const token = response.data.token;
						if (isValidToken(token)) {
							setSession(token, logout);

							getProfile().then((success) => {
								if (success) {
									socket.connect();

									socket.emit("User.Login", {
										token,
									});
								}
							});
						} else {
							enqueueSnackbar("Não foi possível fazer o login agora, tente novamente mais tarde.", {
								variant: "error",
							});
						}

						enqueueSnackbar(`Boas vindas ao Back Office ${websiteConfig.formatedName}!`);
					} else if (response.code) {
						let message = response.message;
						switch (response.code) {
							case 1221:
								message = "Não foi possível fazer o login agora, tente novamente mais tarde.";
								break;
							case 1221_1:
								message = "A verificação do Captcha não é válida, tente novamente.";
								break;
							case 1221_2:
								message = "A resposta captcha não foi encontrada na solicitação.";
								break;
							case 1221_3:
								message = "Verifique se suas credenciais estão corretas.";
								break;
							case 1221_4:
								message = "Verifique se suas credenciais estão corretas.";
								break;
						}
						enqueueSnackbar(`${response.code} - ${message}`, {
							variant: "error",
						});
					} else {
						enqueueSnackbar("Backoffice em manutenção, tente novamente mais tarde.", {
							variant: "error",
						});
					}
				})
				.catch((error) => {
					console.error(error);
					sessionStorage.removeItem("accessToken");
					enqueueSnackbar("Backoffice em manutenção, tente novamente mais tarde.", {
						variant: "error",
					});
					return false;
				});
		},
		[enqueueSnackbar, getProfile, logout]
	);

	useEffect(() => {
		const accessToken = sessionStorage.getItem("accessToken");
		if (accessToken && !state.user) {
			if (isValidToken(accessToken)) {
				setSession(accessToken, logout);
				getProfile().then((success) => {
					if (success) {
						socket.connect();

						socket.emit("User.Login", {
							token: accessToken,
						});
					}
				});
			}
		}
	}, [getProfile, login, logout, state.user]);

	const saveProfile = (data: Partial<Account>, callback: (success: boolean) => void) => {
		axiosInstance
			.post("/api/v1/user/me", data)
			.then((response) => {
				if (response.status === 200) {
					setState((_state) => ({ ..._state, user: response.data }));
					enqueueSnackbar("Conta salva com sucesso!!");
					callback(true);
				} else {
					enqueueSnackbar("Ocorreu um erro ao salvar sua conta.", {
						variant: "error",
					});
					callback(false);
				}
			})
			.catch((error) => {
				console.error(error);
				enqueueSnackbar("Ocorreu um erro ao salvar sua conta.", {
					variant: "error",
				});
				callback(false);
			});
	};

	const resetPassword = async (email: string) => {
		const result = await axiosInstance
			.post(`/api/v1/authflow/password-recovery`, {
				email,
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				}
				return response;
			})
			.catch((error) => {
				return error;
			});
		return result;
	};

	const sendResetCode = async (email: string, code: string, password: string, passwordConfirmation: string) => {
		const result = await axiosInstance
			.put(`/api/v1/authflow/password-recovery`, {
				email,
				code,
				password,
				passwordConfirmation,
			})
			.then((response) => {
				if (response.status === 200) {
					return response.data;
				}
				return response;
			})
			.catch((error) => {
				return error;
			});
		return result;
	};

	const isAuthenticated = () => {
		const userItem = state.user;
		return !!userItem;
	};

	const changeUserCredits = (newQuantity: number) => {
		setState((_state) => {
			if (_state.user) {
				return {
					..._state,
					user: {
						..._state.user,
						credits: newQuantity,
					},
				};
			}
			return _state;
		});
	};

	return (
		<AuthContext.Provider
			value={{
				...state,
				changeUserCredits,
				isAuthenticated,
				login,
				logout,
				resetPassword,
				saveProfile,
				sendResetCode,
				setAuthContextState: (state_) => setState(state_),
			}}>
			{children}
		</AuthContext.Provider>
	);
}

export { AuthContext, AuthProvider };
