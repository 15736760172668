import { Box, Button, Card, Stack, TextField, Typography } from "@mui/material";
import { fCpf } from "src/utils/formatNumber";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import Container from "@mui/material/Container";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs/CustomBreadcrumbs";
import Grid from "@mui/material/Grid";
import Loading from "src/components/Loading";
import Page from "src/components/Page";
import useWebSocket from "src/hooks/useWebSocket";

type Account = {
	id: string;
	email: string;
	username: string;
	document: string;
	totalDeposited: number;
	affiliatedAt: string;
	lastApproval?: {
		date: number;
		author: string;
	};
};

export default function DepositsListPage() {
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const { websocket } = useWebSocket();

	const [account, setAccount] = useState<Account | null>(null);
	const [email, setEmail] = useState<string>("");
	const [requestingAccounts, setRequestingAccount] = useState(false);

	useEffect(() => {
		websocket
			.on("Deposits.Verify", (data) => {
				setRequestingAccount(false);
				if (data.success) {
					setAccount(data.account);
				} else {
					enqueueSnackbar("Insira um E-Mail ou CPF válido e verifique se você tem as permissões necessárias.", {
						variant: "error",
					});
				}
			})
			.on("Deposits.Approve", (data) => {
				if (data.success) {
					enqueueSnackbar("Aprovação feita com sucesso.");
				} else {
					enqueueSnackbar("Ocorreu algum erro ao efetuar a aprovação, verifique se você tem as permissões necessárias.", {
						variant: "error",
					});
				}
			});
	}, [enqueueSnackbar, navigate, websocket]);

	const handleGetAccountDeposit = () => {
		setRequestingAccount(true);
		websocket.emit("Deposits.Verify", { search: email });
	};

	const handleApprove = () => {
		if (!account) return;

		websocket.emit("Deposits.Approve", {
			id: account.id,
			amount: account.totalDeposited,
		});
	};

	if (requestingAccounts) return <Loading loadingText="Procurando conta" />;

	return (
		<Page title="Gerenciamento: Depósitos">
			<Container maxWidth="xl">
				<CustomBreadcrumbs heading="Verificar depósitos" links={[{ name: `Atualizado em ${new Date().toLocaleString("pt-BR")}` }]} />
				<Grid container spacing={2} sx={{ alignItems: "center", alignContent: "center", justifyContent: "center" }}>
					<Grid item xs={12}>
						<Stack direction="row" spacing={3} sx={{ alignItems: "center", alignContent: "center", justifyContent: "center" }}>
							<TextField id="email" name="email" type="email" value={email} placeholder="Digite o E-Mail..." onChange={({ target: { value } }) => setEmail(value)} />
							<Button variant="contained" color="primary" onClick={handleGetAccountDeposit}>
								Procurar
							</Button>
						</Stack>
					</Grid>
					{account && (
						<Grid item lg={6} xs={12}>
							<Card sx={{ padding: 3 }}>
								<Box>
									<Typography component={"span"} color={"text.secondary"}>
										E-Mail:{" "}
									</Typography>
									<Typography component={"span"}>{account.email}</Typography>
								</Box>
								<Box>
									<Typography component={"span"} color={"text.secondary"}>
										Total depositado nas últimas 48hrs:{" "}
									</Typography>
									<Typography component={"span"}>
										{account.totalDeposited.toLocaleString("pt-BR", {
											style: "currency",
											currency: "BRL",
										})}
									</Typography>
								</Box>
								<Box>
									<Typography component={"span"} color={"text.secondary"}>
										CPF:{" "}
									</Typography>
									<Typography component={"span"}>{fCpf(account.document)}</Typography>
								</Box>
								<Box>
									<Typography component={"span"} color={"text.secondary"}>
										Afiliado:{" "}
									</Typography>
									<Typography component={"span"}>{account.affiliatedAt}</Typography>
								</Box>
								{account.lastApproval && (
									<Box>
										<Typography component={"span"} color={"text.secondary"}>
											Última aprovação feita por:{" "}
										</Typography>
										<Typography component={"span"}>
											{account.lastApproval.author} ({new Date(account.lastApproval.date).toLocaleString("pt-BR")})
										</Typography>
									</Box>
								)}
								<Button variant="contained" color="success" sx={{ mt: 3 }} onClick={handleApprove}>
									Aprovar
								</Button>
							</Card>
						</Grid>
					)}
				</Grid>
			</Container>
		</Page>
	);
}
