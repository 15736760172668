// locales
import { Box, Tooltip, ListItemText, Link } from "@mui/material";
import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { NavItemProps } from "../types";
import { StyledItem, StyledIcon } from "./styles";
import Iconify from "../../iconify";

const NavItem = forwardRef<HTMLDivElement, NavItemProps>(({ item, depth, open, active, isExternalLink, ...other }, ref) => {
	const { title, path, icon, info, children, disabled, caption } = item;

	const subItem = depth !== 1;

	const renderContent = (
		<StyledItem ref={ref} open={open} depth={depth} active={active} disabled={disabled} {...other}>
			{icon && <StyledIcon>{icon}</StyledIcon>}

			<ListItemText
				primary={`${title}`}
				primaryTypographyProps={{
					noWrap: true,
					component: "span",
					variant: active ? "subtitle2" : "body2",
				}}
			/>

			{info && (
				<Box component="span" sx={{ ml: 1, lineHeight: 0 }}>
					{info}
				</Box>
			)}

			{caption && (
				<Tooltip title={`${caption}`} arrow>
					<Box component="span" sx={{ ml: 0.5, lineHeight: 0 }}>
						<Iconify icon="eva:info-outline" width={16} />
					</Box>
				</Tooltip>
			)}

			{!!children && <Iconify icon={subItem ? "eva:chevron-right-fill" : "eva:chevron-down-fill"} width={16} sx={{ ml: 0.5, flexShrink: 0 }} />}
		</StyledItem>
	);

	if (isExternalLink)
		return (
			<Link href={path} target="_blank" rel="noopener" underline="none">
				{renderContent}
			</Link>
		);

	return (
		<Link component={RouterLink} to={path} underline="none">
			{renderContent}
		</Link>
	);
});

export default NavItem;
