import { Box, ButtonGroup, Stack, Tooltip, Typography } from "@mui/material";
import { dateLocale, dateOptions, withdrawsBaseRoute } from "../../_constants";
import { DefaultTableRowProps } from "../../_types";
import { fCpf } from "src/utils/formatNumber";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { WithdrawsTableRowType } from "./WithdrawsTable";
import axios from "axios";
import Button from "@mui/material/Button";
import Iconify from "src/components/iconify";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import useAuth from "src/hooks/useAuth";
import useWebSocket from "src/hooks/useWebSocket";

type BankAccountInfoType = {
	id: string;
	userId: string;
	bankName: string;
	accountNumber: string;
	accountName: string;
	bankBranchCode: string;
	createdAt: string;
	updatedAt: string;
};

export default function WithdrawsTableRow({ isItemSelected, row, labelId, handleSelect }: DefaultTableRowProps<WithdrawsTableRowType>) {
	const { websocket } = useWebSocket();
	const { user } = useAuth();
	const [bankAccountInfo, setBankAccountInfo] = useState<BankAccountInfoType | null>(null);
	const [requested, setRequested] = useState(false);

	const onApproveRow = () => {
		websocket.emit("Management.Withdraw.Action", { id: row.id, platform: localStorage.getItem("withdraw-platform") });
	};

	const onRejectRow = () => {
		websocket.emit("Management.Withdraw.Action", { id: row.id, reason: "Saque rejeitado.", platform: localStorage.getItem("withdraw-platform") });
	};

	useEffect(() => {
		if (!bankAccountInfo && !requested && row.walletId) {
			setRequested(true);
			axios.get(`https://content.arbety.com/api/users/bank/admin/${row.walletId}`).then((res) => {
				if (!res.data.error) {
					setBankAccountInfo(res.data.data.account);
				}
			});
		}
	}, [row.walletId]);

	return (
		<TableRow hover tabIndex={-1}>
			<TableCell align="left" id={labelId}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						alignContent: "center",
						gap: 1,
						color: `${row.status === "pending" ? "warning" : row.status === "approved" ? "success" : "error"}.main`,
					}}>
					<Iconify icon={`iconoir:${row.status === "pending" ? "clock" : (row.status === "approved" ? "check" : "delete") + "-circle"}`} />
					{row.status === "pending" ? "Pendente" : row.status === "approved" ? "Aprovado" : row.status === "canceled" ? "Cancelado" : "Recusado"}
				</Box>
			</TableCell>
			<TableCell scope="row">
				{row.walletId ? (
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							alignContent: "center",
							gap: 1,
							color: `${bankAccountInfo ? "success" : "error"}.main`,
						}}>
						<Tooltip
							title={
								<>
									Nome: {row.firstName}
									<br />
									Sobrenome: {row.lastName}
									<br />
									Enderço: {row.address}
									<br />
									Código postal: {row.postalCode}
									<br />
									Cidade: {row.city}
									<br />
									País: {row.country}
									<br />
									Cidade natal: {row.hometown}
									<br />
									Número de telefone: +{row.phoneDialCode} {row.phoneNumber}
									<br />
									Bank: {bankAccountInfo?.bankName}
									<br />
									Conta: {bankAccountInfo?.accountNumber}
									<br />
									Agência: {bankAccountInfo?.bankBranchCode}
									<br />
									Conta: {bankAccountInfo?.accountName}
								</>
							}>
							<Iconify icon="material-symbols:info" />
						</Tooltip>
					</Box>
				) : (
					"-/-"
				)}
			</TableCell>
			<TableCell scope="row">{row.email}</TableCell>
			<TableCell scope="row">
				<Button
					variant="text"
					color="inherit"
					onClick={() => navigator.clipboard.writeText(row.document)}
					startIcon={<Iconify icon="iconoir:copy" />}
					sx={{ whiteSpace: "nowrap" }}>
					{fCpf(row.document)}
				</Button>
			</TableCell>
			<TableCell align="left">
				<Button
					variant="text"
					color="inherit"
					onClick={() => navigator.clipboard.writeText(row.amount.toString())}
					startIcon={<Iconify icon="iconoir:copy" />}
					sx={{ whiteSpace: "nowrap" }}>
					{row.amount.toLocaleString("pt-BR", {
						currency: row.currency,
						style: "currency",
						maximumFractionDigits: 2,
						minimumFractionDigits: 2,
					})}
				</Button>
			</TableCell>
			<TableCell align="left">{row.kyc ? "Sim" : "Não"}</TableCell>
			<TableCell align="left">{new Date(row.createdAt).toLocaleString(dateLocale, dateOptions)}</TableCell>
			<TableCell align="left">
				<Typography variant="body2" color="success.main">
					{row.depositTotal.toLocaleString("pt-BR", {
						currency: row.currency,
						style: "currency",
						maximumFractionDigits: 2,
						minimumFractionDigits: 2,
					})}
				</Typography>
			</TableCell>
			<TableCell align="left">
				<Typography variant="body2" color="error.main">
					{row.withdrawTotal.toLocaleString("pt-BR", {
						currency: row.currency,
						style: "currency",
						maximumFractionDigits: 2,
						minimumFractionDigits: 2,
					})}
				</Typography>
			</TableCell>
			<TableCell align="left">
				<Iconify icon={`iconoir:${(row.first ? "check" : "delete") + "-circle"}`} color={`${row.first ? "success" : "error"}.main`} />
			</TableCell>
			<TableCell align="right">
				<Stack direction="row" sx={{ gap: 1 }}>
					{user?.roles.some((role) => ["super-admin", "admin", "withdraws.manage"].includes(role)) && (
						<ButtonGroup>
							<Button size="small" variant="contained" color="error" onClick={onRejectRow}>
								<Iconify icon={"iconoir:cancel"} />
							</Button>
							<Button size="small" variant="soft" color="success" onClick={onApproveRow}>
								<Iconify icon={"iconoir:check"} />
							</Button>
						</ButtonGroup>
					)}
					<Button
						size="small"
						variant="text"
						color="secondary"
						component={Link}
						to={`${withdrawsBaseRoute}/${row.id}/details`}
						endIcon={<Iconify icon={"iconoir:nav-arrow-right"} />}>
						Abrir
					</Button>
				</Stack>
			</TableCell>
		</TableRow>
	);
}
