import { accountsBaseRoute, dateLocale, dateOptions } from "../../_constants";
import { AccountsTableRowType } from "./AccountsTable";
import { DefaultTableRowProps } from "../../_types";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Iconify from "src/components/iconify";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function AccountsTableRow({ isItemSelected, row, labelId, handleSelect }: DefaultTableRowProps<AccountsTableRowType>) {
	const navigate = useNavigate();

	const onDetailsRow = () => {
		navigate(`${accountsBaseRoute}/${row.id}/details`);
	};

	return (
		<TableRow hover tabIndex={-1}>
			<TableCell id={labelId} scope="row">
				{row.username}
			</TableCell>
			<TableCell align="left">{row.email}</TableCell>
			<TableCell align="left">
				{row.firstName} {row.lastName}
			</TableCell>
			<TableCell align="left">
				{row.balance.toLocaleString("pt-BR", {
					currency: "BRL",
					style: "currency",
					maximumFractionDigits: 2,
					minimumFractionDigits: 2,
				})}
			</TableCell>
			<TableCell align="left">
				{row.bonusBalance.toLocaleString("pt-BR", {
					currency: "BRL",
					style: "currency",
					maximumFractionDigits: 2,
					minimumFractionDigits: 2,
				})}
			</TableCell>
			<TableCell align="left">{new Date(row.createdAt).toLocaleString(dateLocale, dateOptions)}</TableCell>
			<TableCell align="left">{new Date(row.birthDate).toLocaleString(dateLocale, dateOptions)}</TableCell>
			<TableCell align="right">
				<Button variant="text" color="secondary" onClick={onDetailsRow} endIcon={<Iconify icon={"iconoir:nav-arrow-right"} />}>
					Abrir
				</Button>
			</TableCell>
		</TableRow>
	);
}
