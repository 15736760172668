import { DatePicker } from "@mui/x-date-pickers";
import { useRef, useState } from "react";
import Box, { BoxProps } from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Iconify from "./iconify";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Typography, { TypographyProps } from "@mui/material/Typography";
import useResponsive from "src/hooks/useResponsive";

interface Props extends BoxProps {
	text: string;
	value: string | number;
	textTypographyProps: TypographyProps;
	textFieldProps: TextFieldProps;
	onEdit: (vlue: string) => void;
	isCenter: boolean;
	disabled: boolean;
	isDate?: boolean;
}

export default function TextEditable({ text, value, textTypographyProps, textFieldProps, onEdit, isCenter, disabled, isDate, ...props }: Props) {
	const oldValueRef = useRef(value);

	const [editing, setEditing] = useState(false);
	const [showEdit, setShowEdit] = useState(false);
	const [newValue, setNewValue] = useState(value);

	const isDesktop = useResponsive("up", "lg");

	const submit = (cancel: boolean) => {
		const curr = cancel ? oldValueRef.current.toString() : newValue.toString();
		if (!cancel) {
			oldValueRef.current = newValue.toString();
		}
		setNewValue(curr);
		onEdit(curr);
	};

	return (
		<Box
			onMouseEnter={() => (disabled ? {} : setShowEdit(true))}
			onMouseLeave={() => setShowEdit(false)}
			style={{ display: "flex", justifyContent: "center", alignItems: "center", alignContent: "center", textAlign: "center", height: 40 }}
			{...props}>
			{!editing && (
				<>
					{(showEdit || !isDesktop) && isCenter && !disabled && (
						<IconButton sx={{ mr: 1, visibility: "hidden" }}>
							<Iconify icon={`iconoir:edit-pencil`} />
						</IconButton>
					)}
					<Typography {...textTypographyProps} onClick={() => (disabled ? {} : setEditing(true))}>
						{text}
					</Typography>
					{(showEdit || !isDesktop) && !disabled && (
						<IconButton aria-label="edit" sx={{ ml: 1 }} onClick={() => setEditing(true)}>
							<Iconify icon={`iconoir:edit-pencil`} />
						</IconButton>
					)}
				</>
			)}
			{editing && (
				<>
					{isCenter && (
						<IconButton sx={{ mr: 1, visibility: "hidden" }}>
							<Iconify icon={`iconoir:check`} />
						</IconButton>
					)}
					{isDate ? (
						<DatePicker
							value={new Date(newValue).getTime()}
							onChange={(newValue) => setNewValue(new Date(newValue || Date.now()).toISOString())}
							renderInput={(params) => {
								params.inputProps = { ...textFieldProps.inputProps, ...params.inputProps };

								return (
									<TextField
										{...params}
										onBlur={() => {
											submit(true);
											setEditing(false);
										}}
										size="small"
									/>
								);
							}}
							autoFocus={true}
						/>
					) : (
						<TextField
							type="text"
							size="small"
							value={newValue}
							autoFocus={true}
							onBlur={() => {
								submit(true);
								setEditing(false);
							}}
							inputProps={{
								style: {
									textAlign: "center",
								},
							}}
							onChange={({ target: { value } }) => setNewValue(value)}
							{...textFieldProps}
						/>
					)}
					<IconButton
						aria-label="save"
						color="success"
						sx={{ ml: 1 }}
						onMouseDown={() => {
							submit(false);
							setEditing(false);
						}}>
						<Iconify icon={`iconoir:check`} />
					</IconButton>
				</>
			)}
		</Box>
	);
}
