import { dateLocale, dateOptions, logsHeadCells } from "../_constants";
import { LogsTableRow } from ".";
import { DefaultTableProps, TableOrder } from "../_types";
import { stableSort, getComparator, emptyRows } from "../../../utils/tableUtils";
import { TableEmptyRows, TableHeadCustom, TableNoData } from "src/components/table";
import { useState, MouseEvent, ChangeEvent, useEffect } from "react";
import LogsTableToolbar from "./LogsTableToolbar";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";

export interface LogsTableRowType {
	id: string;
	message: string;
	action: "auth.login" | "profile.get" | "affiliate.created" | "affiliate.deleted" | "affiliate.updated";
	success: string;
	userId: string;
	agent: string;
	ip: string;
	date: number;
}

export default function LogsTable({ rows, tableConfig, itemCount, dense: dense_ = false, onChangeTableConfig }: DefaultTableProps<LogsTableRowType>) {
	const [dense, setDense] = useState(dense_);
	const [order, setOrder] = useState<TableOrder>(tableConfig.order);
	const [orderBy, setOrderBy] = useState<keyof LogsTableRowType>(tableConfig.orderBy);
	const [page, setPage] = useState(tableConfig.page);
	const [rowCount, setRowCount] = useState(itemCount);
	const [rowsPerPage, setRowsPerPage] = useState(tableConfig.limit);
	const [searchFilter, setSearchFilter] = useState("");
	const [selected, setSelected] = useState<"all" | readonly string[]>([]);
	const [tableData, setTableData] = useState<LogsTableRowType[]>(rows);

	useEffect(() => {
		setOrder(tableConfig.order);
		setOrderBy(tableConfig.orderBy);
		setPage(tableConfig.page);
		setRowCount(itemCount);
		setRowsPerPage(tableConfig.limit);
		setTableData(rows);
	}, [rows, itemCount, tableConfig.limit, tableConfig.order, tableConfig.orderBy, tableConfig.page]);

	const handleRequestSort = (id: keyof LogsTableRowType) => {
		const order_ = orderBy === id && order === "asc";
		onChangeTableConfig([
			{ type: "order", data: order_ ? "desc" : "asc" },
			{ type: "orderBy", data: id },
		]);
	};

	const handleSelectAllClick = (checked: boolean) => {
		if (checked) {
			setSelected("all");
			return;
		}
		setSelected([]);
	};

	const handleSelectRow = (event: MouseEvent<unknown>, id: string) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: readonly string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		onChangeTableConfig([{ type: "page", data: newPage }]);
	};

	const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
		onChangeTableConfig([
			{ type: "limit", data: Number(event.target.value) },
			{ type: "page", data: 0 },
		]);
	};

	const handleChangeDense = (event: ChangeEvent<HTMLInputElement>) => {
		setDense(event.target.checked);
	};

	const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchFilter(event.target.value);
	};

	const isSelected = (id: string) => (selected === "all" ? true : selected.indexOf(id) !== -1);

	return (
		<Box sx={{ width: "100%" }}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<LogsTableToolbar numSelected={selected.length} />

				<Stack
					spacing={2}
					direction={"row"}
					sx={{
						alignItems: "center",
						alignContent: "center",
						pl: { xs: 1 },
						pr: { xs: 1 },
						py: 2,
					}}>
					<TextField
						type="text"
						label="Procurar"
						placeholder="abc@mail.com, 12/03/1999..."
						size={dense ? "small" : "medium"}
						value={searchFilter}
						onChange={handleChangeSearch}
						sx={{
							width: 300,
						}}
						disabled
					/>
				</Stack>
				<TableContainer>
					<Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
						<TableHeadCustom<LogsTableRowType>
							headLabel={logsHeadCells}
							order={order}
							orderBy={orderBy}
							numSelected={selected.length}
							onSelectAllRows={handleSelectAllClick}
							onSort={handleRequestSort}
							rowCount={tableData.length}
							extra={true}
						/>
						<TableBody>
							{stableSort(
								tableData.filter((row) =>
									Object.values(row).some((value) =>
										(typeof value === "string" ? value : new Date(value).getTime() ? new Date(value).toLocaleString(dateLocale, dateOptions) : `${value}`)
											.toLowerCase()
											.includes(searchFilter.toLowerCase())
									)
								),
								getComparator(order, orderBy)
							)
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => (
									<LogsTableRow isItemSelected={isSelected(row.id)} row={row} key={row.id} labelId={`logs-table-checkbox-${index}`} handleSelect={handleSelectRow} />
								))}

							<TableEmptyRows height={dense ? 56 : 76} emptyRows={emptyRows(page, rowsPerPage, itemCount)} />
							<TableNoData isNotFound={!tableData.length} />
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
					component="div"
					count={rowCount}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage={"Contas por página"}
					getItemAriaLabel={function defaultGetAriaLabel(type) {
						return `Ir para a ${type === "next" ? "próxima página" : type === "first" ? "primeira página" : type === "last" ? "última página" : "pagina anterior"} `;
					}}
					showFirstButton={page !== 0 && rowCount > rowsPerPage * page}
					showLastButton={page !== Math.ceil(rowCount / rowsPerPage) - 1 && rowCount > rowsPerPage * page}
				/>
			</Paper>
			<FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Modo minimalista" />
		</Box>
	);
}
