import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { useSettingsContext } from "../../components/settings";
import { useState } from "react";
import Header from "./header";
import Main from "./Main";
import NavHorizontal from "./nav/NavHorizontal";
import NavMini from "./nav/NavMini";
import NavVertical from "./nav/NavVertical";
import useResponsive from "../../hooks/useResponsive";
import useAuth from "../../hooks/useAuth";

export default function DashboardLayout() {
	const { themeLayout } = useSettingsContext();
	const { user } = useAuth();

	const isDesktop = useResponsive("up", "lg");

	const [open, setOpen] = useState(false);

	const isNavHorizontal = themeLayout === "horizontal";

	const isNavMini = themeLayout === "mini";

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;

	if (isNavHorizontal) {
		return (
			<>
				<Header onOpenNav={handleOpen} />

				{isDesktop ? <NavHorizontal /> : renderNavVertical}

				<Main>
					<Outlet />
				</Main>
			</>
		);
	}

	if (isNavMini) {
		return (
			<>
				<Header onOpenNav={handleOpen} />

				<Box
					sx={{
						display: { lg: "flex" },
						minHeight: { lg: 1 },
					}}>
					{isDesktop ? <NavMini /> : renderNavVertical}

					<Main>
						<Outlet />
					</Main>
				</Box>
			</>
		);
	}

	return (
		<>
			<Header onOpenNav={handleOpen} />

			<Box
				sx={{
					display: { lg: "flex" },
					minHeight: { lg: 1 },
				}}>
				{user?.roles.some((role) =>
					["super-admin", "admin", "user", "withdraws.see", "dashboard", "statistics", "deposits.verify", "affiliates.see", "affiliates.manage", "accounts.see"].includes(role)
				) && renderNavVertical}

				<Main>
					<Outlet />
				</Main>
			</Box>
		</>
	);
}
